import React, { Component, createRef } from 'react'; //different
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './App.css';
import Footer from './footer';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import instance from "./axios-instance";
import { NumericFormat } from 'react-number-format';
import Systems from "./SystemFunction";
import Casino from "./casino";
import Slot from "./slot";
import Fishing from "./fising";
import Sport from "./sport";
import TextSlider from "./textslide";
import Afficiate from "./affiliate";
import Signin from "./signin";
import Register from "./register";
import Loss from "./loss";
import Promotion from "./promotionx";
// import { isMobile } from 'react-device-detect';
import Profile from "./profile";
import Statement from "./statement";
import Banner from "./banner";
import MoblieMenu from "./mobilemenu";
import Mainindex from "./mainindex";
import Lowmenu from "./lowmenu";
import Popup from "./popup";
// import Lotto from "./lotto";
import Listgameall from "./linklistall";
// import GameHit from "./gamehit";
import moment from 'moment';
import md5 from 'md5';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { LoopTwoTone } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import NProgress from "nprogress";
import axios from 'axios';
var settings = {
    dots: false, // ซ่อนจุดนำทาง
    infinite: true, // วนลูป slides
    slidesToShow: 3, // แสดง 3 slide ในหน้าจอใหญ่
    slidesToScroll: 1, // เลื่อนทีละ 1 slide
    autoplay: true, // autoplay
    speed: 2000, // ความเร็วในการเลื่อน (ms)
    autoplaySpeed: 3000, // ช่วงเวลาระหว่าง autoplay
    cssEase: "linear", // ความเรียบเนียนของ transition
    centerMode: true, // แสดง slide ตรงกลาง
    centerPadding: "20px", // ระยะห่างซ้าย-ขวาของ slides
    responsive: [
        {
            breakpoint: 1024, // เมื่อหน้าจอเล็กกว่า 1024px
            settings: {
                slidesToShow: 2, // แสดง 2 slide
                centerPadding: "15px", // ระยะห่างลดลง
            },
        },
        {
            breakpoint: 760, // เมื่อหน้าจอเล็กกว่า 760px
            settings: {
                slidesToShow: 1, // แสดง 1 slide
                centerPadding: "10px", // ระยะห่างลดลงอีก
            },
        },
    ],
};

const settingsx = {
    dots: true, // แสดงจุดบอกสถานะของสไลด์
    infinite: true, // สไลด์วนลูป
    speed: 500, // ความเร็วในการเลื่อนสไลด์ (ms)
    slidesToShow: 1, // จำนวนสไลด์ที่แสดง
    slidesToScroll: 1, // จำนวนสไลด์ที่เลื่อนต่อครั้ง
    autoplay: true, // เลื่อนอัตโนมัติ
    autoplaySpeed: 3000, // เวลาในแต่ละสไลด์ (ms)
    arrows: true // แสดงปุ่มเลื่อนซ้าย-ขวา
};

let isMobile = false;
if (window.innerWidth <= 760) isMobile = true;
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false, // สถานะเปิด/ปิด Sidebar
            tabselect: 0,
            webname: "",
            logoimg: "",
            linelink: "",
            webnamex: "",
            describemain: "",
            System: "",
        }

    }

    toggleSidebar = () => {
        console.log("toggleSidebar")
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen, // สลับสถานะเปิด/ปิด
        }));
    };
    checkurl = async (e, url) => {
        NProgress.start();
        const pathname = url.split("/")
        //  console.log(210, url.split("/"));
        if (pathname.length > 1) {
            if (!this.state.loginstate && pathname[1] == "listgameall") {
                this.setState({
                    tabselect: 23,
                });
                return;
            }
            else {
                window.history.pushState(null, null, url);
                this.setState({
                    modalx: false,
                    modal: false,
                });
                if (pathname[1] == "") {
                    console.log(250, pathname[1]);
                    this.setState({
                        tabselect: 0,
                    });
                }
                else if (pathname[1] == "casino") {
                    this.setState({
                        tabselect: 10,
                    });
                }
                else if (pathname[1] == "gamehit") {
                    this.setState({
                        tabselect: 11,
                    });
                }
                else if (pathname[1] == "fishing") {
                    this.setState({
                        tabselect: 12,
                    });
                }
                else if (pathname[1] == "slot") {
                    console.log(101, pathname);
                    this.setState({
                        tabselect: 13,
                    });
                }
                else if (pathname[1] == "sport") {
                    this.setState({
                        tabselect: 14,
                    });
                }
                else if (pathname[1] == "loss") {
                    this.setState({
                        tabselect: 25,
                    });
                }

                else if (pathname[1] === "sportblog") {
                    this.setState({
                        tabselect: 20,
                    });
                } else if (pathname[1] === "casinoblog") {
                    this.setState({
                        tabselect: 21,
                    });
                } else if (pathname[1] === "slotblog") {
                    this.setState({
                        tabselect: 22,
                    });
                } else if (pathname[1] === "sportbet") {
                    this.setState({
                        tabselect: 23,
                    });
                } else if (pathname[1] === "bacaratbet") {
                    this.setState({
                        tabselect: 24,
                    });
                } else if (pathname[1] === "lottoonline") {
                    this.setState({
                        tabselect: 25,
                    });
                } else if (pathname[1] === "promotionblog") {
                    this.setState({
                        tabselect: 26,
                    });
                } else if (pathname[1] === "blog") {
                    this.setState({
                        tabselect: 27,
                    });
                }

                else if (pathname[1] == "event") {
                    this.setState({
                        tabselect: 16,
                    });
                }
                else if (pathname[1] == "lotto") {
                    this.setState({
                        tabselect: 17,
                    });
                }
                else if (pathname[1] == "withdraw") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 2,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "deposit") {
                    this.setState({
                        tabselect: 1,
                        tabselectdeposit: 1,
                    });
                }
                else if (pathname[1] == "statement") {
                    this.setState({
                        tabselect: 3,
                    });
                }
                else if (pathname[1] == "profile") {
                    this.setState({
                        tabselect: 8,
                    });
                }
                else if (pathname[1] == "register") {
                    this.setState({
                        tabselect: 22,
                    });
                }
                else if (pathname[1] == "signin") {
                    this.setState({
                        tabselect: 23,
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                    this.setState({
                        tabselect: 4,
                        refer: 1
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                    this.setState({
                        tabselect: 4,
                        refer: 2
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                    this.setState({
                        tabselect: 4,
                        refer: 3
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                    this.setState({
                        tabselect: 4,
                        refer: 4
                    });
                }
                else if (pathname[1] == "rebate") {
                    this.setState({
                        tabselect: 5,
                    });
                }
                else if (pathname[1] == "promotion") {
                    this.setState({
                        tabselect: 7,
                    });
                }
                else if (pathname[1] == "listgameall") {
                    let check = await this.getgame(pathname[2], pathname[3], true);
                    console.log(614, check)
                    if (check) {
                        this.setState({
                            tabselect: 15,
                        });
                    }
                }
            }
        }
        else {
            this.setState({
                tabselect: 0,
            });
        }
        NProgress.done();
    }

    async componentDidMount() {
        NProgress.start();
        try {
            const pathname = window.location.pathname.split("/")
            await instance.post("/api/v1/mainsetting", {
                System: Systems,
            }).then((res) => {
                if (res.data.status === 200) {
                    const datas = res.data.message;
                    var min = res.data.tblcatagoryx[0].limitdeposit;
                    var max = res.data.tblcatagoryx[0].maxdeposit;
                    var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                    var limit = { min, max }
                    var luckywheeldisplay = res.data.tblwheelsetting[0].status === 1;
                    console.log("luckywheeldisplay", res.data.tokeneditor);
                    document.title = res.data.tblsetting[0].title;
                    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                    link.type = 'image/svg+xml';
                    link.rel = 'icon';
                    link.href = res.data.tblsetting[0].imglogo;
                    document.getElementsByTagName('head')[0].appendChild(link);


                    this.setState({
                        webname: res.data.tblsetting[0].Description,
                        logoimg: res.data.tblsetting[0].imglogo,
                        whellsetting: res.data.tblwheelsetting,
                        maxwithdrawstatus: maxwithdrawstatus,
                        luckywheeldisplay: luckywheeldisplay,
                        linelink: datas[1].value,
                        webnamex: datas[7].value,
                        describemain: res.data.tblsetting[0].title,
                        System: res.data.tblcatagoryx[0].System,
                        cashoutSet: res.data.tblcashoutsetting[0],
                        inviteSet: res.data.tblinvitedsetting[0],
                        datas: datas,
                        limit: limit,
                    });
                }
                else {
                    // this.CheckThisGoalError();
                }
            });
            //   console.log(455, pathname[1] + "/" + pathname[2])

            if (pathname.length > 1) {
                console.log(pathname);
                if (pathname[1] == "casino") {
                    this.setState({
                        tabselect: 10,
                    });
                }
                else if (pathname[1] == "gamehit") {

                    this.setState({
                        tabselect: 11,
                    });
                }
                else if (pathname[1] == "fishing") {
                    this.setState({
                        tabselect: 12,
                    });
                }
                else if (pathname[1] == "loss") {
                    this.setState({
                        tabselect: 25,
                    });
                }
                else if (pathname[1] == "slot") {
                    this.setState({
                        tabselect: 13,
                    });
                }
                else if (pathname[1] == "register") {
                    this.setState({
                        tabselect: 22,
                    });
                }
                else if (pathname[1] == "signin") {
                    this.setState({
                        tabselect: 23,
                    });
                }
                else if (pathname[1] == "sport") {
                    this.setState({
                        tabselect: 14,
                    });
                }
                else if (pathname[1] == "event") {
                    this.setState({
                        tabselect: 16,
                    });
                }
                else if (pathname[1] == "lotto") {
                    this.setState({
                        tabselect: 17,
                    });
                }
                else if (pathname[1] == "withdraw") {
                    // console.log(1232323)
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 2,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "deposit") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 1,
                            tabselectdeposit: 1,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "statement") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 3,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] == "profile") {
                    if (this.state.loginstate) {
                        this.setState({
                            tabselect: 8,
                        });
                    }
                    else {
                        this.setState({
                            tabselect: 0,
                        });
                    }
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/overview") {
                    this.setState({
                        tabselect: 4,
                        refer: 1
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/member") {
                    console.log(63855555);
                    this.setState({
                        tabselect: 4,
                        refer: 2
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/income-summary") {
                    this.setState({
                        tabselect: 4,
                        refer: 3
                    });
                }
                else if (pathname[1] + "/" + pathname[2] == "refer/share-network") {
                    this.setState({
                        tabselect: 4,
                        refer: 4
                    });
                }
                else if (pathname[1] == "rebate") {
                    this.setState({
                        tabselect: 5,
                    });
                }
                else if (pathname[1] == "promotion") {
                    this.setState({
                        tabselect: 7,
                    });
                }
                else if (pathname[1] == "listgameall") {
                    let check = await this.getgame(pathname[2], pathname[3]);
                    if (check) {
                        this.setState({
                            tabselect: 15,
                        });
                    }
                }
            }

        }
        catch (error) {
            console.log(185222, error);
        }
        NProgress.done();
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }

    render() {
        const { isOpen } = this.state;
        const { tabselect } = this.state
        return (
            <>
                <>

                    <style
                        type="text/css"
                        id="custom-background-css"
                        dangerouslySetInnerHTML={{
                            __html:
                                "\n    body.custom-background {\n      background-color: #171b1e;\n    }\n  "
                        }}
                    />

                    <link
                        href="https://kit-pro.fontawesome.com/releases/v5.15.1/css/pro.min.css"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
                        integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
                        crossOrigin="anonymous"
                    />
                    <link
                        rel="stylesheet"
                        href="https://appufa.com/wp-content/themes/yumyum88/style.css?1733985343"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-widgets-style-css"
                        href="https://appufa.com/wp-content/plugins/header-footer-elementor/inc/widgets-css/frontend.css?ver=1.6.47"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="link-widget-title-css"
                        href="https://appufa.com/wp-content/plugins/link-widget-title/public/css/link-widget-title-public.css?ver=1.0.1"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-style-css"
                        href="https://appufa.com/wp-content/plugins/header-footer-elementor/assets/css/header-footer-elementor.css?ver=1.6.47"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="swiper-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css?ver=8.4.5"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="e-swiper-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/conditionals/e-swiper.min.css?ver=3.25.9"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-post-149-css"
                        href="https://appufa.com/wp-content/uploads/elementor/css/post-149.css?ver=1732315734"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-pro-css"
                        href="https://appufa.com/wp-content/plugins/elementor-pro/assets/css/frontend.min.css?ver=3.16.2"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="uael-frontend-css"
                        href="https://appufa.com/wp-content/plugins/ultimate-elementor/assets/min-css/uael-frontend.min.css?ver=1.36.21"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="widget-text-editor-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-text-editor.min.css?ver=3.25.9"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="widget-image-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-image.min.css?ver=3.25.9"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="widget-heading-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-heading.min.css?ver=3.25.9"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="widget-image-carousel-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-image-carousel.min.css?ver=3.25.9"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-post-154-css"
                        href="https://appufa.com/wp-content/uploads/elementor/css/post-154.css?ver=1732316235"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-post-232-css"
                        href="https://appufa.com/wp-content/uploads/elementor/css/post-232.css?ver=1732315735"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-post-274-css"
                        href="https://appufa.com/wp-content/uploads/elementor/css/post-274.css?ver=1732315735"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-icons-list-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-icon-list.min.css?ver=3.24.3"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-social-icons-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/css/widget-social-icons.min.css?ver=3.24.0"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-social-share-icons-brands-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.css?ver=5.15.3"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-social-share-icons-fontawesome-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.css?ver=5.15.3"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-nav-menu-icons-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.css?ver=5.15.3"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="hfe-widget-blockquote-css"
                        href="https://appufa.com/wp-content/plugins/elementor-pro/assets/css/widget-blockquote.min.css?ver=3.25.0"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="eael-general-css"
                        href="https://appufa.com/wp-content/plugins/essential-addons-for-elementor-lite/assets/front-end/css/view/general.min.css?ver=6.0.10"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="google-fonts-1-css"
                        href="https://fonts.googleapis.com/css?family=Kanit%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&display=auto&ver=6.7.1"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-icons-shared-0-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3"
                        type="text/css"
                        media="all"
                    />
                    <link
                        rel="stylesheet"
                        id="elementor-icons-fa-solid-css"
                        href="https://appufa.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3"
                        type="text/css"
                        media="all"
                    />
                    {/* Font Awesome JS */}
                    <link
                        href="https://kit-pro.fontawesome.com/releases/v5.15.1/css/pro.min.css"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
                        integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
                        crossOrigin="anonymous"
                    />
                    {/* Our Custom CSS */}
                    <link
                        rel="stylesheet"
                        href="https://appufa.com/wp-content/themes/yumyum88/style.css?1733985343"
                    />
                </>
                <div id="page" className="hfeed site">
                    <header
                        id="masthead"
                        itemScope="itemscope"
                        itemType="https://schema.org/WPHeader"
                    >
                        <p className="main-title bhf-hidden" itemProp="headline">
                            <a href="#" title="APPUFA" rel="home">
                                ENJOY289
                            </a>
                        </p>
                        <div
                            data-elementor-type="wp-post"
                            data-elementor-id={232}
                            className="elementor elementor-232"
                            data-elementor-post-type="elementor-hf"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-cf7e7d9 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                data-id="cf7e7d9"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-13d0d98"
                                        data-id="13d0d98"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <section
                                                data-particle_enable="false"
                                                data-particle-mobile-disabled="false"
                                                className="elementor-section elementor-inner-section elementor-element elementor-element-748e99c elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                                data-id="748e99c"
                                                data-element_type="section"
                                            >
                                                <div className="elementor-container elementor-column-gap-no">
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ae15989"
                                                        data-id="ae15989"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-43edab3 elementor-widget elementor-widget-html"
                                                                data-id="43edab3"
                                                                data-element_type="widget"
                                                                data-widget_type="html.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div
                                                                        className={`x-hamburger js-hamburger-toggle sidebarCollapse topbar ${isOpen ? "open" : ""
                                                                            }`}
                                                                        onClick={this.toggleSidebar} >
                                                                        <span />
                                                                        <span />
                                                                        <span />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-c061b50 elementor-widget elementor-widget-site-logo"
                                                                data-id="c061b50"
                                                                data-element_type="widget"
                                                                data-settings='{"width":{"unit":"%","size":100,"sizes":[]},"width_tablet":{"unit":"px","size":120,"sizes":[]},"width_mobile":{"unit":"px","size":104,"sizes":[]},"align_tablet":"left","align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                                data-widget_type="site-logo.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="hfe-site-logo">
                                                                        <a
                                                                            data-elementor-open-lightbox=""
                                                                            className="elementor-clickable"
                                                                            href="#"
                                                                        >
                                                                            <div className="hfe-site-logo-set">
                                                                                <div className="hfe-site-logo-container">
                                                                                    <img
                                                                                        className="hfe-site-logo-img elementor-animation-"
                                                                                        src={this.state.logoimg}
                                                                                        alt="logo"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-20c1968"
                                                        data-id="20c1968"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-d7a5a5f elementor-nav-menu__align-left elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
                                                                data-id="d7a5a5f"
                                                                data-element_type="widget"
                                                                data-settings='{"layout":"horizontal","submenu_icon":{"value":"<i class=\"fas fa-caret-down\"><\/i>","library":"fa-solid"}}'
                                                                data-widget_type="nav-menu.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <nav className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                                                                        <ul id="menu-2-d7a5a5f" className="elementor-nav-menu">
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-96">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    หน้าแรก
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-96">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/sportblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    แทงบอลออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2975">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/casinoblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    คาสิโนออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-981">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/slotblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    สล็อตออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2974">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/sportbet")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    เดิมพันกีฬา
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2972">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/bacaratbet")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    บาคาร่าออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2973">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/lottoonline")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    หวยออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/promotionblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    โปรโมชั่น
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/blog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    บทความ
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2504">
                                                                                <a
                                                                                    role="button"
                                                                                    href={this.state.linelink}
                                                                                    className="elementor-item elementor-item-anchor"
                                                                                >
                                                                                    ติดต่อเรา
                                                                                </a>
                                                                            </li>
                                                                        </ul>



                                                                    </nav>
                                                                    <nav
                                                                        className="elementor-nav-menu--dropdown elementor-nav-menu__container"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <ul id="menu-2-d7a5a5f" className="elementor-nav-menu">
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home menu-item-96">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/")}
                                                                                    className="elementor-item elementor-item-active"
                                                                                >
                                                                                    หน้าแรก
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-983">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/sportblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    แทงบอลออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2975">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/casinoblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    คาสิโนออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-981">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/slotblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    สล็อตออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2974">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/sportbet")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    เดิมพันกีฬา
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2972">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/bacaratbet")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    บาคาร่าออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2973">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/lottoonline")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    หวยออนไลน์
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                                                                <a
                                                                                    role="button"
                                                                                    onClick={(e) => this.checkurl(e, "/promotionblog")}
                                                                                    className="elementor-item"
                                                                                >
                                                                                    โปรโมชั่น
                                                                                </a>
                                                                            </li>
                                                                            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2504">
                                                                                <a
                                                                                    role="button"
                                                                                    href={this.state.linelink}
                                                                                    className="elementor-item elementor-item-anchor"
                                                                                >
                                                                                    ติดต่อเรา
                                                                                </a>
                                                                            </li>
                                                                        </ul>

                                                                    </nav>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-726495a"
                                                        data-id="726495a"
                                                        data-element_type="column"
                                                    >
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div
                                                                className="elementor-element elementor-element-bea988e elementor-align-right elementor-widget elementor-widget-button"
                                                                data-id="bea988e"
                                                                data-element_type="widget"
                                                                data-widget_type="button.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-button-wrapper">
                                                                        <a
                                                                            className="elementor-button elementor-button-link elementor-size-md"
                                                                            href={this.state.webnamex + "/login"}
                                                                        >
                                                                            <span className="elementor-button-content-wrapper">
                                                                                <span className="elementor-button-icon">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fas fa-lock"
                                                                                    />
                                                                                </span>
                                                                                <span className="elementor-button-text">
                                                                                    เข้าสู่ระบบ
                                                                                </span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="elementor-element elementor-element-e115767 elementor-align-right elementor-widget elementor-widget-button"
                                                                data-id="e115767"
                                                                data-element_type="widget"
                                                                data-widget_type="button.default"
                                                            >
                                                                <div className="elementor-widget-container">
                                                                    <div className="elementor-button-wrapper">
                                                                        <a
                                                                            className="elementor-button elementor-button-link elementor-size-md"
                                                                            href={this.state.webnamex + "/register"}
                                                                        >
                                                                            <span className="elementor-button-content-wrapper">
                                                                                <span className="elementor-button-icon">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fas fa-user-plus"
                                                                                    />
                                                                                </span>
                                                                                <span className="elementor-button-text">
                                                                                    สมัครสมาชิก
                                                                                </span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div

                        className={`x-hamburger js-hamburger-toggle sidebarCollapse second ${isOpen ? "open" : ""
                            }`}
                        onClick={this.toggleSidebar}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                    <div
                        className={`insidebarleft ${isOpen ? "active" : ""}`}
                        style={{ overflow: "hidden" }}
                    >
                        <a
                            role="button" onClick={(e) => {
                                this.checkurl(e, "/")
                            }}
                            className="custom-logo-link"
                            rel="home"
                            aria-current="page"
                        >
                            <img
                                fetchpriority="high"
                                width={512}
                                height={137}
                                src={this.state.logoimg}
                                className="custom-logo"
                                alt="APPUFA"
                                decoding="async"
                                //  srcSet="https://appufa.com/wp-content/uploads/2022/03/cropped-1_0.png 512w, https://appufa.com/wp-content/uploads/2022/03/cropped-1_0-300x80.png 300w"
                                sizes="(max-width: 512px) 100vw, 512px"
                            />
                        </a>
                        <div className="menu-primary-menu-container">
                            <ul id="menu-primary-menu" className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-96">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/")
                                    }} aria-current="page">
                                        หน้าแรก
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-983">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/sportblog")
                                    }}>
                                        แทงบอลออนไลน์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2975">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/casinoblog")
                                    }}>
                                        คาสิโนออนไลน์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-981">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/slotblog")
                                    }}>
                                        สล็อตออนไลน์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2974">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/sportbet")
                                    }}>
                                        เดิมพันกีฬา
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2972">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/bacaratbet")
                                    }}>
                                        บาคาร่าออนไลน์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2973">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/lottoonline")
                                    }}>
                                        หวยออนไลน์
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/promotionblog")
                                    }}>
                                        โปรโมชั่น
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                    <a role="button" onClick={(e) => {
                                        this.checkurl(e, "/blog")
                                    }}>
                                        โปรโมชั่น
                                    </a>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2504">
                                    <a href={this.state.linelink}>ติดต่อเรา</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="overlaysidebar" />
                    <div className="fixbottom">
                        <div className="togglemenu">
                            <i className="fas fa-bars" />
                        </div>
                        <div className="bottommenu">
                            <div className="menu-primary-menu-container">
                                <ul id="menu-primary-menu1" className="menu">
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-96">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/")
                                        }} aria-current="page">
                                            หน้าแรก
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-983">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/sportblog")
                                        }}>
                                            แทงบอลออนไลน์
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2975">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/casinoblog")
                                        }}>
                                            คาสิโนออนไลน์
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-981">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/slotblog")
                                        }}>
                                            สล็อตออนไลน์
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2974">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/sportbet")
                                        }}>
                                            เดิมพันกีฬา
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2972">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/bacaratbet")
                                        }}>
                                            บาคาร่าออนไลน์
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2973">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/lottoonline")
                                        }}>
                                            หวยออนไลน์
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/promotionblog")
                                        }}>
                                            โปรโมชั่น
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6195">
                                        <a role="button" onClick={(e) => {
                                            this.checkurl(e, "/blog")
                                        }}>
                                            โปรโมชั่น
                                        </a>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2504">
                                        <a href={this.state.linelink}>ติดต่อเรา</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: tabselect == 0 || tabselect == 10 || tabselect == 11 || tabselect == 12 || tabselect == 13 || tabselect == 14 ? "" : "none" }} data-elementor-type="wp-page" data-elementor-id={154} className="elementor elementor-154" data-elementor-post-type="page" >
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-93474e8 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="93474e8"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1d64719"
                                    data-id="1d64719"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-012cc9d elementor-widget elementor-widget-shortcode"
                                            data-id="012cc9d"
                                            data-element_type="widget"
                                            data-widget_type="shortcode.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="elementor-shortcode">
                                                    <div className="cover-slide">
                                                        <div id="infinite-1" className="highway-slider">
                                                            <div className="container highway-barrier">
                                                                <div className="highway-lane fade">
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-pragmaticplay.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-sagaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-wm.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Cow-Cow-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Dreamgaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Golden-Wealth-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Dice.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Roulette.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Peek-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Sexy-Gaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Thai-HI-LO.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-เวอร์ชวล-ป๊อกเด้ง.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="infinite-2" className="highway-slider">
                                                            <div className="container highway-barrier">
                                                                <div className="highway-lane fade">
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-aesexy.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-cpragmaticplay.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-cq9.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-creedroomz.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-dreamgaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-ebet.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-sa.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-ob88-pv-casino-wm.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-aesexy.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-cq9.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-creedroomz.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-dreamgaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-2"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-ebet.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="infinite-1" className="highway-slider">
                                                            <div className="container highway-barrier">
                                                                <div className="highway-lane fade">
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Dice.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Roulette.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Peek-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Thai-HI-LO.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-เวอร์ชวล-ป๊อกเด้ง.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-pragmaticplay.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-sagaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-wm.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Golden-Wealth-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Baccarat.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-rcm-Lightning-Roulette.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-dreamgaming.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-cq9.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-aesexy.png"
                                                                    />
                                                                    <img
                                                                        decoding="async"
                                                                        alt=""
                                                                        className="sliders-1"
                                                                        src="https://appufa.com/wp-content/uploads/2023/10/pv-hw888-casino-ebet.png"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-f77a81d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="f77a81d"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-63fbac2"
                                                    data-id="63fbac2"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-5aafdd9 elementor-widget elementor-widget-site-logo"
                                                            data-id="5aafdd9"
                                                            data-element_type="widget"
                                                            data-settings='{"width":{"unit":"px","size":300,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":236,"sizes":[]},"space":{"unit":"%","size":75,"sizes":[]},"align":"center","space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="site-logo.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="hfe-site-logo">
                                                                    <a
                                                                        data-elementor-open-lightbox=""
                                                                        className="elementor-clickable"
                                                                        href="#"
                                                                    >
                                                                        <div className="hfe-site-logo-set">
                                                                            <div className="hfe-site-logo-container">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="hfe-site-logo-img elementor-animation-"
                                                                                    src="https://cdn.javisx.com/image/b7c42ddd-dacc-407c-b720-141256c5cf32.png"
                                                                                    alt="logo ENJOY289 521x200"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-0b82ec7 elementor-widget elementor-widget-text-editor"
                                                            data-id="0b82ec7"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <h1>
                                                                    <strong>ENJOY289</strong>
                                                                </h1>
                                                                <p>
                                                                    ลิขสิทธ์แท้จาก <strong>ufabet</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-df7f3c7 elementor-widget elementor-widget-text-editor"
                                                            data-id="df7f3c7"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    เดิมพันออนไลน์ อันดับ 1 ของไทย ฝากถอนรวดเร็ว
                                                                    ออโต้ทั้งระบบ
                                                                </p>
                                                                <p>
                                                                    แทงบอล บาคาร่า คาสิโนออนไลน์ เสือมังกร โปรแรงสุดในไทย
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-9a0bb3c elementor-align-center elementor-widget elementor-widget-button"
                                                            data-id="9a0bb3c"
                                                            data-element_type="widget"
                                                            data-widget_type="button.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-button-wrapper">
                                                                    <a
                                                                        className="elementor-button elementor-button-link elementor-size-sm"
                                                                        href={this.state.webnamex + "/register"}
                                                                    >
                                                                        <span className="elementor-button-content-wrapper">
                                                                            <span className="elementor-button-text">
                                                                                สมัคร ufabet
                                                                            </span>
                                                                        </span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-64b03df"
                                                    data-id="64b03df"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-575a189 elementor-widget elementor-widget-image"
                                                            data-id="575a189"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    decoding="async"
                                                                    width={622}
                                                                    height={446}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/PNG1.png"
                                                                    className="attachment-full size-full wp-image-6410"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/PNG1.png 622w, https://appufa.com/wp-content/uploads/2024/07/PNG1-300x215.png 300w"
                                                                    sizes="(max-width: 622px) 100vw, 622px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-e1dacc0 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                            data-id="e1dacc0"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f6c0af6"
                                                    data-id="f6c0af6"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"classic"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-87f7512 elementor-widget elementor-widget-heading"
                                                            data-id="87f7512"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    ENJOY289 ลิขสิทธ์แท้จาก UFABET
                                                                    สุดยอดเว็บไซต์เดิมพันออนไลน์อันดับ 1 ของไทย
                                                                    สมัครง่ายเพียงขั้นตอนเดียว ฝาก-ถอนอัตโนมัติรวดเร็วด้วย
                                                                    AI ระบบธนาคารที่ทันสมัยที่สุดในขณะนี้ แทงบอล บาคาร่า
                                                                    สล็อต หวย ครบ จบ ในที่เดียว
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-b79ea6a elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="b79ea6a"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-dc6c488"
                                    data-id="dc6c488"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">


                                        <Banner />

                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-42440e7e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="42440e7e"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                        >
                            <div className="elementor-background-overlay" />
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-26cd7cdb"
                                    data-id="26cd7cdb"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-706ee6a3 tabcontain elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="706ee6a3"
                                            data-element_type="section"
                                            data-settings='{"background_background":"classic"}'
                                        >
                                            <div className="elementor-container elementor-column-gap-no">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c47bfc4"
                                                    data-id="c47bfc4"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <section
                                                            data-particle_enable="false"
                                                            data-particle-mobile-disabled="false"
                                                            className="elementor-section elementor-inner-section elementor-element elementor-element-681e3e8d elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                            data-id="681e3e8d"
                                                            data-element_type="section"
                                                        >
                                                            <div className="elementor-container elementor-column-gap-default">
                                                                <div className={`elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-28cffef4 tablink ${tabselect == 0 ? "active" : ""
                                                                    }`}
                                                                    style={{ height: "71px" }}
                                                                    data-id="28cffef4"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, "/")
                                                                    }}
                                                                    data-element_type="column"
                                                                    id="tabmain01"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-3e8eaead elementor-widget elementor-widget-image"
                                                                            data-id="3e8eaead"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    decoding="async"
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src="https://cdn.javisx.com/image/29a626d6-4f5e-4d0d-a291-48465d62fbfb.png"
                                                                                    className="attachment-full size-full wp-image-6276"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-6afbb17a elementor-widget elementor-widget-heading"
                                                                            data-id="6afbb17a"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <span className="elementor-heading-title elementor-size-default">
                                                                                    ยอดนิยม
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-281c71c9 tablink ${tabselect == 14 ? "active" : ""
                                                                    }`}
                                                                    data-id="281c71c9"
                                                                    data-element_type="column"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, "/sport")
                                                                    }}
                                                                    id="tabmain04"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-55e8d22b elementor-widget elementor-widget-image"
                                                                            data-id="55e8d22b"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/01/ajax-ic-nav-menu-sport.png"
                                                                                    className="attachment-large size-large wp-image-6038"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-6410d96b elementor-widget elementor-widget-heading"
                                                                            data-id="6410d96b"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <span className="elementor-heading-title elementor-size-default">
                                                                                    กีฬา
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-66b0d117 tablink ${tabselect == 10 ? "active" : ""
                                                                    }`}
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, "/casino")
                                                                    }}
                                                                    data-id="66b0d117"
                                                                    data-element_type="column"
                                                                    id="tabmain03"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-74ceebc2 elementor-widget elementor-widget-image"
                                                                            data-id="74ceebc2"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/01/ajax-ic-nav-menu-casino.png"
                                                                                    className="attachment-large size-large wp-image-6035"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-56c9e03f elementor-widget elementor-widget-heading"
                                                                            data-id="56c9e03f"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <span className="elementor-heading-title elementor-size-default">
                                                                                    คาสิโน
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-15d64e8d tablink ${tabselect == 13 ? "active" : ""
                                                                    }`}
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, "/slot")
                                                                    }}
                                                                    data-id="15d64e8d"
                                                                    data-element_type="column"
                                                                    id="tabmain02"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-71ef63d2 elementor-widget elementor-widget-image"
                                                                            data-id="71ef63d2"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/01/ajax-ic-nav-menu-slot.png"
                                                                                    className="attachment-large size-large wp-image-6037"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-37b373dc elementor-widget elementor-widget-heading"
                                                                            data-id="37b373dc"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <span className="elementor-heading-title elementor-size-default">
                                                                                    สล็อตเกม
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-5f18f7d7 tablink ${tabselect == 12 ? "active" : ""
                                                                    }`}
                                                                    data-id="5f18f7d7"
                                                                    onClick={(e) => {
                                                                        this.checkurl(e, "/fishing")
                                                                    }}
                                                                    data-element_type="column"
                                                                    id="tabmain06"
                                                                    data-settings='{"background_background":"classic"}'
                                                                >
                                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                                        <div
                                                                            className="elementor-element elementor-element-7bf90546 elementor-widget elementor-widget-image"
                                                                            data-id="7bf90546"
                                                                            data-element_type="widget"
                                                                            data-widget_type="image.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={100}
                                                                                    height={100}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/01/EZ-Casino-เกมยิงปลาออนไลน์-เครดิตฟรีตั้งแต่แรกฝาก.png"
                                                                                    className="attachment-large size-large wp-image-6043"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="elementor-element elementor-element-713d2be2 elementor-widget elementor-widget-heading"
                                                                            data-id="713d2be2"
                                                                            data-element_type="widget"
                                                                            data-widget_type="heading.default"
                                                                        >
                                                                            <div className="elementor-widget-container">
                                                                                <span className="elementor-heading-title elementor-size-default">
                                                                                    ยิงปลา
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section data-id="1bc093e1"
                                            data-particle-mobile-disabled="false"
                                            data-element_type="section" id="tab-3" className="elementor-section elementor-inner-section elementor-element elementor-element-1bc093e1 tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default" style={{ display: tabselect == 0 ? "" : "none" }}>

                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6d912586"
                                                    data-id="6d912586"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-1acc604d elementor-widget elementor-widget-image"
                                                            data-id="1acc604d"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <figure className="wp-caption">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={681}
                                                                        height={629}
                                                                        src="https://appufa.com/wp-content/uploads/2024/01/tewt.png"
                                                                        className="attachment-full size-full wp-image-5760"
                                                                        alt=""
                                                                        srcSet="https://appufa.com/wp-content/uploads/2024/01/tewt.png 681w, https://appufa.com/wp-content/uploads/2024/01/tewt-300x277.png 300w"
                                                                        sizes="(max-width: 681px) 100vw, 681px"
                                                                    />
                                                                    <figcaption className="widget-image-caption wp-caption-text">
                                                                        แทงบอลออนไลน์
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-4e98613 uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="4e98613"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/พรีเมียร์_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6395"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/พรีเมียร์_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/พรีเมียร์_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            Premier League
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ลาลีก้า_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6396"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ลาลีก้า_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/ลาลีก้า_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">LaLiga</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/บุนเดิสลีก้า_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6394"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/บุนเดิสลีก้า_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/บุนเดิสลีก้า_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">Bundesliga</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/เซเรียอา_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6393"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/เซเรียอา_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/เซเรียอา_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">Serie A</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ลีกเอิง_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6397"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ลีกเอิง_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/ลีกเอิง_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">Ligue 1</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UEFA-Champions-League_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6391"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UEFA-Champions-League_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/UEFA-Champions-League_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            UEFA Champions League
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UEFA-Europa-League_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6392"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UEFA-Europa-League_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/UEFA-Europa-League_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            UEFA Europa League
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/thai-league.jpg"
                                                                                            className="attachment-full size-full wp-image-6414"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/thai-league.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/thai-league-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            thai league
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-135ac74e elementor-widget elementor-widget-image"
                                                            data-id="135ac74e"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <figure className="wp-caption">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={100}
                                                                        height={100}
                                                                        src="https://appufa.com/wp-content/uploads/2024/01/ajax-ic-nav-menu-casino.png"
                                                                        className="attachment-full size-full wp-image-6035"
                                                                        alt=""
                                                                    />
                                                                    <figcaption className="widget-image-caption wp-caption-text">
                                                                        คาสิโนออนไลน์ ยอดนิยม
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-2a55cfd uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="2a55cfd"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/Sexy-Baccarat-1.jpg"
                                                                                            className="attachment-full size-full wp-image-6490"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/Sexy-Baccarat-1.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/Sexy-Baccarat-1-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            Sexy Baccarat
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/SA-Gaming_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6485"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/SA-Gaming_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/SA-Gaming_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">SA Gaming</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UFA-Casino_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6484"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UFA-Casino_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/UFA-Casino_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">UFA Casino</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/MG-Live_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6487"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/MG-Live_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/MG-Live_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">MG Live</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-2a60d77 elementor-widget elementor-widget-image"
                                                            data-id="2a60d77"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <figure className="wp-caption">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={100}
                                                                        height={100}
                                                                        src="https://appufa.com/wp-content/uploads/2024/01/ajax-ic-nav-menu-slot.png"
                                                                        className="attachment-full size-full wp-image-6037"
                                                                        alt=""
                                                                    />
                                                                    <figcaption className="widget-image-caption wp-caption-text">
                                                                        สล็อต ยอดนิยม
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-9ef941d uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="9ef941d"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={600}
                                                                                            height={380}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/01/ezc-wtm-pg-soft2.png"
                                                                                            className="attachment-full size-full wp-image-5881"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/01/ezc-wtm-pg-soft2.png 600w, https://appufa.com/wp-content/uploads/2024/01/ezc-wtm-pg-soft2-300x190.png 300w"
                                                                                            sizes="(max-width: 600px) 100vw, 600px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">PG SOFT</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UFA-Slot_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6452"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UFA-Slot_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/UFA-Slot_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">UFA SLOT</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={317}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/01/ezc-wt-pragmatic-play.png"
                                                                                            className="attachment-full size-full wp-image-5888"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/01/ezc-wt-pragmatic-play.png 500w, https://appufa.com/wp-content/uploads/2024/01/ezc-wt-pragmatic-play-300x190.png 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">
                                                                                            Pragmatic Play
                                                                                        </div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/JOKER_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6451"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/JOKER_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/JOKER_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">JOKER</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-9a82e6d elementor-widget elementor-widget-image"
                                                            data-id="9a82e6d"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <figure className="wp-caption">
                                                                    <img
                                                                        loading="lazy"
                                                                        decoding="async"
                                                                        width={300}
                                                                        height={300}
                                                                        src="https://appufa.com/wp-content/uploads/2024/07/300x300_0.png"
                                                                        className="attachment-full size-full wp-image-6774"
                                                                        alt=""
                                                                        srcSet="https://appufa.com/wp-content/uploads/2024/07/300x300_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/300x300_0-150x150.png 150w"
                                                                        sizes="(max-width: 300px) 100vw, 300px"
                                                                    />
                                                                    <figcaption className="widget-image-caption wp-caption-text">
                                                                        กีฬาออนไลน์ ยอดนิยม
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-977e045 uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="977e045"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/มวย.jpg"
                                                                                            className="attachment-full size-full wp-image-6457"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/มวย.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/มวย-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">มวย</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ไก่ชนไทย_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6459"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ไก่ชนไทย_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/ไก่ชนไทย_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">ไก่ชน</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/slotto_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6458"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/slotto_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/slotto_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">หวย</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={500}
                                                                                            height={300}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ยิงปลา_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6460"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ยิงปลา_0.jpg 500w, https://appufa.com/wp-content/uploads/2024/07/ยิงปลา_0-300x180.jpg 300w"
                                                                                            sizes="(max-width: 500px) 100vw, 500px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                                <figcaption className="uael-grid-img-content">
                                                                                    <div className="uael-grid-img-caption">
                                                                                        <div className="uael-grid-caption-text">ยิงปลา</div>
                                                                                    </div>
                                                                                </figcaption>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section data-id="1bc093e1"
                                            data-particle-mobile-disabled="false"
                                            data-element_type="section" id="tab-3" className="elementor-section elementor-inner-section elementor-element elementor-element-1bc093e1 tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default" style={{ display: tabselect == 10 ? "" : "none" }}>

                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-61f8c81"
                                                    data-id="61f8c81"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-196dee73 uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="196dee73"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1.png"
                                                                                            className="attachment-full size-full wp-image-6579"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1.png 300w, https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0.png"
                                                                                            className="attachment-full size-full wp-image-6580"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0.png"
                                                                                            className="attachment-full size-full wp-image-6578"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0.png"
                                                                                            className="attachment-full size-full wp-image-6577"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/MIKI_0.png"
                                                                                            className="attachment-full size-full wp-image-6576"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/MIKI_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/MIKI_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ASTAR_0.png"
                                                                                            className="attachment-full size-full wp-image-6573"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ASTAR_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/ASTAR_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/EVO_0.png"
                                                                                            className="attachment-full size-full wp-image-6574"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/EVO_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/EVO_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0.png"
                                                                                            className="attachment-full size-full wp-image-6575"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-7816f828 tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="7816f828"
                                            data-element_type="section"
                                            id="tab-6" style={{ display: tabselect == 12 ? "" : "none" }}>

                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6b34bb54"
                                                    data-id="6b34bb54"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-6f27e596 uael-img-grid-mobile__column-3 uael-img-grid-tablet__column-6 uael-img-grid__column-4 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="6f27e596"
                                                            data-element_type="widget"
                                                            data-settings='{"gallery_columns_mobile":"3","column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns_tablet":"6","images_valign":"center","gallery_columns":"4","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-5.png"
                                                                                            className="attachment-full size-full wp-image-3933"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-3.png"
                                                                                            className="attachment-full size-full wp-image-3932"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-2.png"
                                                                                            className="attachment-full size-full wp-image-3931"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufafishing.png"
                                                                                            className="attachment-full size-full wp-image-3930"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-slot-fish.png"
                                                                                            className="attachment-full size-full wp-image-3929"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-play8-fish.png"
                                                                                            className="attachment-full size-full wp-image-3928"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-mt-fish.png"
                                                                                            className="attachment-full size-full wp-image-3927"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-joker-fish.png"
                                                                                            className="attachment-full size-full wp-image-3926"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-9">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-fachai-fishing.png"
                                                                                            className="attachment-full size-full wp-image-3925"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-10">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-cq9-fish.png"
                                                                                            className="attachment-full size-full wp-image-3924"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-11">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-bbinfish-fishing.png"
                                                                                            className="attachment-full size-full wp-image-3923"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-12">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-aug-fish.png"
                                                                                            className="attachment-full size-full wp-image-3922"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </section>
                                        <section data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-79f5dfe0 tabcontent elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="79f5dfe0"
                                            data-element_type="section"
                                            id="tab-2" style={{ display: tabselect == 13 ? "" : "none" }}>

                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7d917d25"
                                                    data-id="7d917d25"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-60bf2b56 uael-img-grid__column-5 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="60bf2b56"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"gallery_columns":"5","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-8.png"
                                                                                            className="attachment-full size-full wp-image-3919"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-5ht-1.png"
                                                                                            className="attachment-full size-full wp-image-3917"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-fachai-2.png"
                                                                                            className="attachment-full size-full wp-image-3912"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-7-1.png"
                                                                                            className="attachment-full size-full wp-image-3918"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={256}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-slot-1.png"
                                                                                            className="attachment-full size-full wp-image-3913"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-4.png"
                                                                                            className="attachment-full size-full wp-image-3916"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-pp.png"
                                                                                            className="attachment-full size-full wp-image-3911"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-play8-slot.png"
                                                                                            className="attachment-full size-full wp-image-3910"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-9">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-spadegaming2.png"
                                                                                            className="attachment-full size-full wp-image-3914"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-10">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/relaxgaming.png"
                                                                                            className="attachment-full size-full wp-image-3915"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </section>
                                        <section data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-158a2e1d tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="158a2e1d"
                                            data-element_type="section"
                                            id="tab-4" style={{ display: tabselect == 14 ? "" : "none" }}>
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1d10c8d5"
                                                    data-id="1d10c8d5"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-248a116f uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="248a116f"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"images_valign":"center","gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ฟุตบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6522"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/บาสเกตบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6523"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/มวยไทย_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6518"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/เทนนิส_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6521"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/วอลเลย์บอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6519"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/เบสบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6520"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/รักบี้_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6517"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/คริกเกต_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6524"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-79f5dfe0 tabcontent elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="79f5dfe0"
                                            data-element_type="section"
                                            id="tab-2"
                                            style={{ display: "none" }}
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7d917d25"
                                                    data-id="7d917d25"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-60bf2b56 uael-img-grid__column-5 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="60bf2b56"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"gallery_columns":"5","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]},"images_valign":"flex-start"}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-8.png"
                                                                                            className="attachment-full size-full wp-image-3919"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-5ht-1.png"
                                                                                            className="attachment-full size-full wp-image-3917"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-fachai-2.png"
                                                                                            className="attachment-full size-full wp-image-3912"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-7-1.png"
                                                                                            className="attachment-full size-full wp-image-3918"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={256}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-slot-1.png"
                                                                                            className="attachment-full size-full wp-image-3913"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-4.png"
                                                                                            className="attachment-full size-full wp-image-3916"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-pp.png"
                                                                                            className="attachment-full size-full wp-image-3911"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-play8-slot.png"
                                                                                            className="attachment-full size-full wp-image-3910"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-9">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-spadegaming2.png"
                                                                                            className="attachment-full size-full wp-image-3914"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-10">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/relaxgaming.png"
                                                                                            className="attachment-full size-full wp-image-3915"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-1bc093e1 tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="1bc093e1"
                                            data-element_type="section"
                                            id="tab-3"
                                            style={{ display: "none" }}
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-61f8c81"
                                                    data-id="61f8c81"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-196dee73 uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal uael-img-caption-valign-bottom elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="196dee73"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":30,"sizes":[]},"row_gap_mobile":{"unit":"px","size":20,"sizes":[]},"images_valign":"center","caption_padding":{"unit":"px","top":"0","right":"0","bottom":"0","left":"0","isLinked":true},"gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption-on-image uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1.png"
                                                                                            className="attachment-full size-full wp-image-6579"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1.png 300w, https://appufa.com/wp-content/uploads/2024/07/SEXY_0-1-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0.png"
                                                                                            className="attachment-full size-full wp-image-6580"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/UFACASINO_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0.png"
                                                                                            className="attachment-full size-full wp-image-6578"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/SAGAMING_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0.png"
                                                                                            className="attachment-full size-full wp-image-6577"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/pragmatic-play-casino_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/MIKI_0.png"
                                                                                            className="attachment-full size-full wp-image-6576"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/MIKI_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/MIKI_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ASTAR_0.png"
                                                                                            className="attachment-full size-full wp-image-6573"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/ASTAR_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/ASTAR_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/EVO_0.png"
                                                                                            className="attachment-full size-full wp-image-6574"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/EVO_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/EVO_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={600}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0.png"
                                                                                            className="attachment-full size-full wp-image-6575"
                                                                                            alt=""
                                                                                            srcSet="https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0.png 300w, https://appufa.com/wp-content/uploads/2024/07/HOTROAD_0-150x300.png 150w"
                                                                                            sizes="(max-width: 300px) 100vw, 300px"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-158a2e1d tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="158a2e1d"
                                            data-element_type="section"
                                            id="tab-4"
                                            style={{ display: "none" }}
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1d10c8d5"
                                                    data-id="1d10c8d5"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-248a116f uael-img-grid__column-4 uael-img-grid-tablet__column-3 uael-img-grid-mobile__column-2 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="248a116f"
                                                            data-element_type="widget"
                                                            data-settings='{"column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"images_valign":"center","gallery_columns":"4","gallery_columns_tablet":"3","gallery_columns_mobile":"2","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"column_gap_mobile":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/ฟุตบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6522"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/บาสเกตบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6523"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/มวยไทย_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6518"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/เทนนิส_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6521"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/วอลเลย์บอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6519"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/เบสบอล_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6520"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/รักบี้_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6517"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2024/07/คริกเกต_0.jpg"
                                                                                            className="attachment-full size-full wp-image-6524"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-7816f828 tabcontent elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="7816f828"
                                            data-element_type="section"
                                            id="tab-6"
                                            style={{ display: "none" }}
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6b34bb54"
                                                    data-id="6b34bb54"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-6f27e596 uael-img-grid-mobile__column-3 uael-img-grid-tablet__column-6 uael-img-grid__column-4 uael-ins-normal elementor-widget elementor-widget-uael-image-gallery"
                                                            data-id="6f27e596"
                                                            data-element_type="widget"
                                                            data-settings='{"gallery_columns_mobile":"3","column_gap":{"unit":"px","size":10,"sizes":[]},"row_gap":{"unit":"px","size":10,"sizes":[]},"column_gap_mobile":{"unit":"px","size":5,"sizes":[]},"row_gap_mobile":{"unit":"px","size":5,"sizes":[]},"gallery_columns_tablet":"6","images_valign":"center","gallery_columns":"4","column_gap_tablet":{"unit":"px","size":"","sizes":[]},"row_gap_tablet":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="uael-image-gallery.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="uael-gallery-parent uael-caption- uael-gallery-unjustified">
                                                                    <div
                                                                        className="uael-img-gallery-wrap uael-img-grid-wrap uael-img-grid-masonry-wrap"
                                                                        data-filter-default="All"
                                                                    >
                                                                        <div className="uael-grid-item  uael-img-gallery-item-1">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-5.png"
                                                                                            className="attachment-full size-full wp-image-3933"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-2">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-3.png"
                                                                                            className="attachment-full size-full wp-image-3932"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-3">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/uf-2.png"
                                                                                            className="attachment-full size-full wp-image-3931"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-4">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufafishing.png"
                                                                                            className="attachment-full size-full wp-image-3930"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-5">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-ufa-slot-fish.png"
                                                                                            className="attachment-full size-full wp-image-3929"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-6">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-play8-fish.png"
                                                                                            className="attachment-full size-full wp-image-3928"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-7">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-mt-fish.png"
                                                                                            className="attachment-full size-full wp-image-3927"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-8">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-joker-fish.png"
                                                                                            className="attachment-full size-full wp-image-3926"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-9">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-fachai-fishing.png"
                                                                                            className="attachment-full size-full wp-image-3925"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-10">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={251}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-cq9-fish.png"
                                                                                            className="attachment-full size-full wp-image-3924"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-11">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-bbinfish-fishing.png"
                                                                                            className="attachment-full size-full wp-image-3923"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uael-grid-item  uael-img-gallery-item-12">
                                                                            <div className="uael-grid-item-content">
                                                                                <figure className="uael-grid-img uael-grid-gallery-img uael-ins-hover elementor-clickable">
                                                                                    <div className="uael-grid-img-thumbnail uael-ins-target">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            decoding="async"
                                                                                            width={300}
                                                                                            height={255}
                                                                                            src="https://appufa.com/wp-content/uploads/2023/07/img-aug-fish.png"
                                                                                            className="attachment-full size-full wp-image-3922"
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="uael-grid-img-overlay" />
                                                                                </figure>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-dd73479 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="dd73479"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                        >
                            <div className="elementor-background-overlay" />
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-afd4567"
                                    data-id="afd4567"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-ee9508b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="ee9508b"
                                            data-element_type="section"
                                            data-settings='{"background_background":"gradient"}'
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-831b9a8"
                                                    data-id="831b9a8"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-ef2294f elementor-widget elementor-widget-image"
                                                            data-id="ef2294f"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={1024}
                                                                    height={341}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/PNG2-1.png"
                                                                    className="attachment-large size-large wp-image-6418"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/PNG2-1.png 1024w, https://appufa.com/wp-content/uploads/2024/07/PNG2-1-300x100.png 300w, https://appufa.com/wp-content/uploads/2024/07/PNG2-1-768x256.png 768w"
                                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-dcc29dc elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="dcc29dc"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5890c1b"
                                                    data-id="5890c1b"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-70b1996 elementor-widget elementor-widget-heading"
                                                            data-id="70b1996"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    สุดยอด คาสิโนออนไลน์ อันดับ 1 รวมไว้ที่ ENJOY289
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-db6231e customgamebox elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="db6231e"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-39e855b"
                                                    data-id="39e855b"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-7caade1 elementor-widget elementor-widget-image"
                                                            data-id="7caade1"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/SEXY_0.png"
                                                                    className="attachment-large size-large wp-image-6422"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/SEXY_0.png 400w, https://appufa.com/wp-content/uploads/2024/07/SEXY_0-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-3220a6f elementor-widget elementor-widget-heading"
                                                            data-id="3220a6f"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    Sexy Baccarat
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-0410a56 elementor-widget elementor-widget-text-editor"
                                                            data-id="0410a56"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    <strong>Sexy Baccarat</strong>
                                                                    นับว่าเป็นเว็บไซต์ที่เป็นที่นิยมอย่างมากในกลุ่มนักเล่นพนันออนไลน์ของไทย
                                                                    เนื่องจากมีความเป็นเซ็กซี่จากดีลเลอร์สาวและเนื้อหาที่น่าสนใจ
                                                                    มี <strong>บาคาร่าสด</strong>
                                                                    โดยเฉพาะกับสาวสวยที่จะคอยให้บริการ
                                                                    ทำให้การเล่นบาคาร่ากลายเป็นประสบการณ์ที่น่าทึ่งและสนุกสนานเป็นอย่างมาก
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-967a3d5"
                                                    data-id="967a3d5"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-b01e644 elementor-widget elementor-widget-image"
                                                            data-id="b01e644"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2023/10/ZABBET-SA-คาสิโน-บาคาร่า.png"
                                                                    className="attachment-large size-large wp-image-5388"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2023/10/ZABBET-SA-คาสิโน-บาคาร่า.png 400w, https://appufa.com/wp-content/uploads/2023/10/ZABBET-SA-คาสิโน-บาคาร่า-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-23d12c0 elementor-widget elementor-widget-heading"
                                                            data-id="23d12c0"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    SA GAMING
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-c323e2a elementor-widget elementor-widget-text-editor"
                                                            data-id="c323e2a"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    SA GAMING แพลตฟอร์มคาสิโนออนไลน์ยอดนิยมอันดับ 1
                                                                    ในการเล่นคาสิโนที่ได้รับความนิยมอย่างมากในปัจจุบัน
                                                                    กับการให้บริการที่มีคุณภาพและปลอดภัย
                                                                    มั่นใจได้ว่าไม่การโกง
                                                                    ระบบมั่นคงทำให้ผู้เล่นทั่วโลกต้องติดใจ
                                                                    ตัวจริงของวงการที่อยู่มานานมากกว่า 10 ปี
                                                                    การันตีคุณภาพจากการกวาดรางวัลมาแล้วนับไม่ถ้วน
                                                                    เป็นเบอร์ต้นๆ ของประเทศ
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-9e5af48"
                                                    data-id="9e5af48"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-401521c elementor-widget elementor-widget-image"
                                                            data-id="401521c"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/UFACASUNO_0.png"
                                                                    className="attachment-large size-large wp-image-6423"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/UFACASUNO_0.png 400w, https://appufa.com/wp-content/uploads/2024/07/UFACASUNO_0-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-98b9c08 elementor-widget elementor-widget-heading"
                                                            data-id="98b9c08"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    UFA Casino
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-dcf9460 elementor-widget elementor-widget-text-editor"
                                                            data-id="dcf9460"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    <strong>UFA Casino</strong> ถูกพัฒนาขึ้นมาโดยทีมงานของ
                                                                    <strong>ufabet</strong>
                                                                    โดยตรงเพื่อที่จะตอยสนองต่อความต้องการของผู้เล่นคาสิโนออนไลน์ในประเทศไทยทุกคน&nbsp;
                                                                    <strong>บาคาร่า ป๊อกเด้ง รูเล็ต เสือมังกร</strong>
                                                                    ระบบถ่ายทอดสดทันสมัยมีภาพคมชัดเสถียร
                                                                    และดีลเลอร์สาวสวยปฏิบัติหน้าที่อย่างมืออาชีพ
                                                                    เริ่มต้นเดิมพันกับ <strong>UFA Casino</strong> แค่ 1
                                                                    บาท เท่านั้น
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-b4e9cb9 customgamebox elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="b4e9cb9"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-425eb91"
                                                    data-id="425eb91"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-94911ac elementor-widget elementor-widget-image"
                                                            data-id="94911ac"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/Baccrat_0.png"
                                                                    className="attachment-large size-large wp-image-6424"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/Baccrat_0.png 400w, https://appufa.com/wp-content/uploads/2024/07/Baccrat_0-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-b1c46ec elementor-widget elementor-widget-heading"
                                                            data-id="b1c46ec"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    บาคาร่า
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-b657bd2 elementor-widget elementor-widget-text-editor"
                                                            data-id="b657bd2"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    <strong>บาคาร่า</strong>
                                                                    คือการเกมการเดิมพันในรูปแบบไพ่
                                                                    ที่ได้รับความนิยืมมากที่สุดในคาสิโนออนไลน์
                                                                    มีความสนุกที่ทันสมัยจนถึงปัจจุบัน
                                                                    ถ้านักเดิมพันทุกท่านเข้ามาลองเล่นแล้ว
                                                                    รับประกันความสนุกตื่นเต้นและได้เงินเแน่นอน
                                                                    จะสร้างประสบการณ์สุดพิเศษด้วยมือของท่านเอง
                                                                    กับการเล่นเกมไพ่ บาคารา
                                                                    ผ่านเทคโนโลยีที่มีความทันสมัยในปัจจุบันนี้
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-a712d94"
                                                    data-id="a712d94"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-0e3b9e3 elementor-widget elementor-widget-image"
                                                            data-id="0e3b9e3"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/SICBO.png"
                                                                    className="attachment-large size-large wp-image-6425"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/SICBO.png 400w, https://appufa.com/wp-content/uploads/2024/07/SICBO-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-6e54e18 elementor-widget elementor-widget-heading"
                                                            data-id="6e54e18"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    ไฮโล
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-6a41b41 elementor-widget elementor-widget-text-editor"
                                                            data-id="6a41b41"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    บอกลาการเล่น <strong>ไฮโล</strong> แบบเก่าๆไปได้เลย
                                                                    เพราะ <strong>ufabet</strong>
                                                                    จะมามอบประสบการณ์สุดพิเศษที่คุณจะไม่สามารถหาที่ไหนได้อีก
                                                                    เพราะเราได้ยก ไฮโล ไทย
                                                                    รวมไว้ให้คุณได้เลือกกันอย่างจัดเต็ม ไฮโล เว็บตรง
                                                                    ผ่านการรีวิวและทดลองเล่นจากทีมงานเรียบร้อยแล้ว
                                                                    ระบบมีความเสถียร รวดเร็ว ใช้งานได้ผ่านมือถือทุกรุ่น
                                                                    ทุกความเสี่ยงที่เกิดขึ้นจะจบไป เพียงเลือกกับ
                                                                    <strong>ENJOY289</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-249c0a4"
                                                    data-id="249c0a4"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-06f7329 elementor-widget elementor-widget-image"
                                                            data-id="06f7329"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={400}
                                                                    height={300}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/รูเล็ต_0.png"
                                                                    className="attachment-large size-large wp-image-6426"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/รูเล็ต_0.png 400w, https://appufa.com/wp-content/uploads/2024/07/รูเล็ต_0-300x225.png 300w"
                                                                    sizes="(max-width: 400px) 100vw, 400px"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-60bc008 elementor-widget elementor-widget-heading"
                                                            data-id="60bc008"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    รูเล็ต
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-91b109e elementor-widget elementor-widget-text-editor"
                                                            data-id="91b109e"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    <strong>รูเล็ต</strong>
                                                                    คือเกมเดิมพันยอดฮิตที่เป็นที่นิยมอย่างมากในวงการคาสิโนทั่วโลก
                                                                    โดยถือเป็นหนึ่งในเกมเดิมพันที่มีกฎเล่นที่ง่ายและเข้าใจง่ายที่สุด
                                                                    โดยมีลูกบอลที่อยู่ภายในล้อหมุน
                                                                    โดยล้อหมุนนี้จะแบ่งออกเป็นช่องต่างๆ
                                                                    ที่มีเลขหรือสัญลักษณ์ต่างๆ อยู่
                                                                    เพื่อให้ผู้เล่นวางเดิมพันลงเลข แถมจะมีค่าตอบแทนจากการ
                                                                    <strong>เล่นรูเล็ต</strong> ที่สูงถึง 33 เท่าอีกด้วย
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-559ea8d1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="559ea8d1"
                            data-element_type="section"
                            data-settings='{"background_background":"classic"}'
                        >
                            <div className="elementor-background-overlay" />
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-28d451bc"
                                    data-id="28d451bc"
                                    data-element_type="column"
                                    data-settings='{"background_background":"classic"}'
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-ea664a9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="ea664a9"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f05ac63"
                                                    data-id="f05ac63"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-62cc7fa elementor-widget elementor-widget-heading"
                                                            data-id="62cc7fa"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    ENJOY289 เดินพันออนไลน์มาตรฐานสากล อันดับ 1 ในไทย
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-2483aba elementor-widget elementor-widget-text-editor"
                                                            data-id="2483aba"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    เว็บแทงบอล บาคาร่าออนไลน์ ที่มาแรงที่สุดในขณะนี้
                                                                    ให้บริการด้วยระบบออโต้ ฝาก-ถอน รวดเร็ว
                                                                    ระบบความปลอดภัยระดับบสากล
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-89c5527 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="89c5527"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3e9522b"
                                    data-id="3e9522b"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-59a22c8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                            data-id="59a22c8"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div
                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-76c08da"
                                                    data-id="76c08da"
                                                    data-element_type="column"
                                                    data-settings='{"background_background":"gradient"}'
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-2c42de4 elementor-widget elementor-widget-site-logo"
                                                            data-id="2c42de4"
                                                            data-element_type="widget"
                                                            data-settings='{"width":{"unit":"px","size":210,"sizes":[]},"width_tablet":{"unit":"px","size":"","sizes":[]},"width_mobile":{"unit":"px","size":"","sizes":[]},"align":"center","space":{"unit":"%","size":"","sizes":[]},"space_tablet":{"unit":"%","size":"","sizes":[]},"space_mobile":{"unit":"%","size":"","sizes":[]},"image_border_radius":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"image_border_radius_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_tablet":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_padding_mobile":{"unit":"px","top":"","right":"","bottom":"","left":"","isLinked":true},"caption_space":{"unit":"px","size":0,"sizes":[]},"caption_space_tablet":{"unit":"px","size":"","sizes":[]},"caption_space_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="site-logo.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="hfe-site-logo">
                                                                    <a
                                                                        data-elementor-open-lightbox=""
                                                                        className="elementor-clickable"
                                                                        href="#"
                                                                    >
                                                                        <div className="hfe-site-logo-set">
                                                                            <div className="hfe-site-logo-container">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="hfe-site-logo-img elementor-animation-"
                                                                                    src={this.state.logoimg}
                                                                                    alt="default-logo"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-be67211 elementor-widget elementor-widget-heading"
                                                            data-id="be67211"
                                                            data-element_type="widget"
                                                            data-widget_type="heading.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-heading-title elementor-size-default">
                                                                    ENJOY289 ผู้ให้บริการ UFABET
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-element elementor-element-b2cf005 elementor-widget elementor-widget-text-editor"
                                                            data-id="b2cf005"
                                                            data-element_type="widget"
                                                            data-widget_type="text-editor.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <p>
                                                                    หากคุณกำลังมองหาเว็บ <strong>แทงบอลออนไลน์</strong>
                                                                    <strong>คาสิโนออนไลน์</strong> เว็บตรง อันดับ 1 ต้อง
                                                                    <a href="/">
                                                                        <strong>ENJOY289</strong>
                                                                    </a>
                                                                    เพราะเป็นระบบเว็บไซต์ ufabet ของแท้ ลิขสิทธิ์ถูกต้อง
                                                                    ในเวลานี้คงจะปฏิเสธไม่ได้เลยว่า เว็บเดิมพันอันอับ 1
                                                                    ของประเทศไทยคือ ufabet
                                                                    ที่ได้พัฒนาเว็บไซต์ของที่เองที่มีชื่อว่า ENJOY289
                                                                    มาให้ผู้เล่นชาวไทยได้ใช้งานกันได้อย่างทั่วโลก
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-53a082f"
                                                    data-id="53a082f"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div
                                                            className="elementor-element elementor-element-943a2dd elementor-widget elementor-widget-image"
                                                            data-id="943a2dd"
                                                            data-element_type="widget"
                                                            data-widget_type="image.default"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <img
                                                                    loading="lazy"
                                                                    decoding="async"
                                                                    width={1024}
                                                                    height={512}
                                                                    src="https://appufa.com/wp-content/uploads/2024/07/fold_mcboo-2.png"
                                                                    className="attachment-large size-large wp-image-6453"
                                                                    alt=""
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/07/fold_mcboo-2.png 1024w, https://appufa.com/wp-content/uploads/2024/07/fold_mcboo-2-300x150.png 300w, https://appufa.com/wp-content/uploads/2024/07/fold_mcboo-2-768x384.png 768w"
                                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-5da1729 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="5da1729"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-no">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-80da09b"
                                    data-id="80da09b"
                                    data-element_type="column"
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section
                                            data-particle_enable="false"
                                            data-particle-mobile-disabled="false"
                                            className="elementor-section elementor-inner-section elementor-element elementor-element-7c297688 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                            data-id="7c297688"
                                            data-element_type="section"
                                        >
                                            <div className="elementor-container elementor-column-gap-no">
                                                <div
                                                    className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6684d284"
                                                    data-id="6684d284"
                                                    data-element_type="column"
                                                >
                                                    <div className="elementor-widget-wrap elementor-element-populated e-swiper-container">
                                                        <div
                                                            className="elementor-element elementor-element-0f0a0ca elementor-widget elementor-widget-image-carousel e-widget-swiper"
                                                            data-id="0f0a0ca"
                                                            data-element_type="widget"
                                                            data-settings='{"slides_to_show":"10","slides_to_scroll":"1","navigation":"none","pause_on_hover":"no","autoplay_speed":0,"speed":5000,"image_spacing_custom":{"unit":"px","size":15,"sizes":[]},"slides_to_show_tablet":"9","slides_to_show_mobile":"5","autoplay":"yes","pause_on_interaction":"yes","infinite":"yes","image_spacing_custom_tablet":{"unit":"px","size":"","sizes":[]},"image_spacing_custom_mobile":{"unit":"px","size":"","sizes":[]}}'
                                                            data-widget_type="image-carousel.default"
                                                            aria-roledescription="carousel"
                                                            aria-label="Carousel | Horizontal scrolling: Arrow Left & Right"
                                                        >
                                                            <div className="elementor-widget-container">
                                                                <div
                                                                    className="elementor-image-carousel-wrapper swiper swiper-initialized swiper-horizontal swiper-pointer-events"
                                                                    dir="ltr"
                                                                >
                                                                    <div
                                                                        className="elementor-image-carousel swiper-wrapper"
                                                                        aria-live="off"
                                                                        style={{
                                                                            transform: "translate3d(-1185.8px, 0px, 0px)",
                                                                            transitionDuration: "0ms"
                                                                        }}
                                                                        id="swiper-wrapper-08a6218110d9e2469"
                                                                    >
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="7 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={6}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/MTV_1666176534.png"
                                                                                    alt="MTV_1666176534"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="8 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={7}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PMT_1697033652.png"
                                                                                    alt="PMT_1697033652"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="9 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={8}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PTG_1622784095.png"
                                                                                    alt="PTG_1622784095"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="10 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={9}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/SAG_1622783981.png"
                                                                                    alt="SAG_1622783981"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="11 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={10}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/SEX_1622783222.png"
                                                                                    alt="SEX_1622783222"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="12 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={11}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/WE_1661506193.png"
                                                                                    alt="WE_1661506193"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="13 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={12}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/WM_1662377341.png"
                                                                                    alt="WM_1662377341"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="14 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={13}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/XG_1682596395.png"
                                                                                    alt="XG_1682596395"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="15 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={14}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/YB_1661940446.png"
                                                                                    alt="YB_1661940446"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="16 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={15}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/05/img-tigerpakyok-square.png"
                                                                                    alt="มวย"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-prev"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="1 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={0}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/AG_1623420151.png"
                                                                                    alt="AG_1623420151"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-active"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="2 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={1}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/BETG_1623420397.png"
                                                                                    alt="BETG_1623420397"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-next"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="3 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={2}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/BIGG_1622705435.png"
                                                                                    alt="BIGG_1622705435"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="4 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={3}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/DGM_1622783837.png"
                                                                                    alt="DGM_1622783837"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="5 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={4}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/gd.png"
                                                                                    alt="gd"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="6 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={5}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/MG_1666957433.png"
                                                                                    alt="MG_1666957433"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="7 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={6}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/MTV_1666176534.png"
                                                                                    alt="MTV_1666176534"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="8 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={7}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PMT_1697033652.png"
                                                                                    alt="PMT_1697033652"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="9 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={8}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PTG_1622784095.png"
                                                                                    alt="PTG_1622784095"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="10 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={9}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/SAG_1622783981.png"
                                                                                    alt="SAG_1622783981"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="11 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={10}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/SEX_1622783222.png"
                                                                                    alt="SEX_1622783222"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="12 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={11}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/WE_1661506193.png"
                                                                                    alt="WE_1661506193"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="13 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={12}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/WM_1662377341.png"
                                                                                    alt="WM_1662377341"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="14 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={13}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/XG_1682596395.png"
                                                                                    alt="XG_1682596395"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="15 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={14}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/YB_1661940446.png"
                                                                                    alt="YB_1661940446"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="16 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={15}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/05/img-tigerpakyok-square.png"
                                                                                    alt="มวย"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-prev"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="1 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={0}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/AG_1623420151.png"
                                                                                    alt="AG_1623420151"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="2 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={1}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/BETG_1623420397.png"
                                                                                    alt="BETG_1623420397"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="3 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={2}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/BIGG_1622705435.png"
                                                                                    alt="BIGG_1622705435"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="4 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={3}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/DGM_1622783837.png"
                                                                                    alt="DGM_1622783837"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="5 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={4}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/gd.png"
                                                                                    alt="gd"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="6 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            data-swiper-slide-index={5}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/MG_1666957433.png"
                                                                                    alt="MG_1666957433"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="7 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={6}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/MTV_1666176534.png"
                                                                                    alt="MTV_1666176534"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="8 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={7}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PMT_1697033652.png"
                                                                                    alt="PMT_1697033652"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="9 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={8}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/PTG_1622784095.png"
                                                                                    alt="PTG_1622784095"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                        <div
                                                                            className="swiper-slide swiper-slide-duplicate"
                                                                            role="group"
                                                                            aria-roledescription="slide"
                                                                            aria-label="10 / 16"
                                                                            style={{ width: "92.8px", marginRight: 15 }}
                                                                            aria-hidden="true"
                                                                            inert=""
                                                                            data-swiper-slide-index={9}
                                                                        >
                                                                            <figure className="swiper-slide-inner">
                                                                                <img
                                                                                    decoding="async"
                                                                                    className="swiper-slide-image"
                                                                                    src="https://appufa.com/wp-content/uploads/2023/10/SAG_1622783981.png"
                                                                                    alt="SAG_1622783981"
                                                                                />
                                                                            </figure>
                                                                        </div>
                                                                    </div>
                                                                    <span
                                                                        className="swiper-notification"
                                                                        aria-live="assertive"
                                                                        aria-atomic="true"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section
                            data-particle_enable="false"
                            data-particle-mobile-disabled="false"
                            className="elementor-section elementor-top-section elementor-element elementor-element-30d51c7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                            data-id="30d51c7"
                            data-element_type="section"
                        >
                            <div className="elementor-container elementor-column-gap-default">
                                <div
                                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f4d40c8"
                                    data-id="f4d40c8"
                                    data-element_type="column"
                                    data-settings='{"background_background":"classic"}'
                                >
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div
                                            className="elementor-element elementor-element-497abe88 elementor-widget elementor-widget-image"
                                            data-id="497abe88"
                                            data-element_type="widget"
                                            data-widget_type="image.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <img
                                                    loading="lazy"
                                                    decoding="async"
                                                    width={512}
                                                    height={137}
                                                    src={this.state.logoimg}
                                                    className="attachment-full size-full wp-image-6227"
                                                    alt=""
                                                    //  srcSet="https://appufa.com/wp-content/uploads/2022/03/cropped-1_0.png 512w, https://appufa.com/wp-content/uploads/2022/03/cropped-1_0-300x80.png 300w"
                                                    sizes="(max-width: 512px) 100vw, 512px"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-element elementor-element-0470fa6 elementor-widget elementor-widget-heading"
                                            data-id="0470fa6"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="elementor-heading-title elementor-size-default">
                                                    การเงินมั่นคง ปลอยภัย 100%
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-element elementor-element-0a7d8f1 elementor-widget elementor-widget-image"
                                            data-id="0a7d8f1"
                                            data-element_type="widget"
                                            data-widget_type="image.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <img
                                                    loading="lazy"
                                                    decoding="async"
                                                    width={1024}
                                                    height={500}
                                                    src="https://appufa.com/wp-content/uploads/2023/10/gg11.png"
                                                    className="attachment-large size-large wp-image-5413"
                                                    alt=""
                                                    srcSet="https://appufa.com/wp-content/uploads/2023/10/gg11.png 1024w, https://appufa.com/wp-content/uploads/2023/10/gg11-300x146.png 300w, https://appufa.com/wp-content/uploads/2023/10/gg11-768x375.png 768w"
                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="elementor-element elementor-element-1e314068 elementor-widget elementor-widget-text-editor"
                                            data-id="1e314068"
                                            data-element_type="widget"
                                            data-widget_type="text-editor.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <p>
                                                    <span style={{ fontWeight: 400 }}>
                                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; ด้วยศักดิ์ศรีของ
                                                        <a href="/">
                                                            <strong>ufabet</strong>
                                                        </a>
                                                        ความที่เป็นความเป็นเว็บแทงบอล อันดับ 1 ของไทย
                                                        ทำให้เรานั้นได้ไว้ใจจากสมาชิกทุกคน
                                                        จึงไม่เสี่ยงกับการนำค่ายเกมที่ไม่มีคุณภาพมาให้บริการเด็ดขาด
                                                        เพราะฉะนั้นเราจึงได้ทำการคัดสรรเลือกหาค่ายเกมคุณภาพ
                                                        ที่พร้อมจะมอบประสบการณ์ที่น่าประทับใจให้กับสมาชิกทุก ๆ
                                                        คนที่วางใจเลือกมาใช้บริการกับ <strong>ENJOY289</strong> ของเรา
                                                        ไม่ว่าจะเป็น แทงบอลออนไลน์ มวยพักยก หวย สล็อตแตกง่าย หรือ
                                                        คาสิโนออนไลน์ บาคาร่า ตัวท็อปของวงการ
                                                        มาให้บริการอย่างครบถ้วน
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div
                                            className="elementor-element elementor-element-337abf9 elementor-widget elementor-widget-heading"
                                            data-id="337abf9"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="elementor-heading-title elementor-size-default">
                                                    บทความสุดพิเศษ
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div
                                            className="elementor-element elementor-element-60baa157 elementor-widget elementor-widget-uael-posts"
                                            data-id="60baa157"
                                            data-element_type="widget"
                                            data-settings='{"classic_slides_to_show_tablet":3,"classic_slides_to_show":3,"classic_slides_to_show_mobile":1,"classic_show_filters":"no"}'
                                            data-widget_type="uael-posts.classic"
                                        >
                                            <div className="elementor-widget-container">
                                                <div className="uael-post__header"></div>
                                                <div className="uael-post__body">
                                                    <div
                                                        className="uael-blog-terms-position- uael-post-image-top uael-post-grid uael-posts"
                                                        data-query-type="custom"
                                                        data-structure="normal"
                                                        data-layout=""
                                                        data-page={154}
                                                        data-skin="classic"
                                                        data-filter-default=""
                                                        data-offset-top={30}
                                                    >
                                                        <div className="uael-post-grid__inner uael-post__columns-3 uael-post__columns-tablet-3 uael-post__columns-mobile-1">
                                                            <div className="uael-post-wrapper   ทั้งหมด ข่าวกีฬาที่น่าสนใจ ">
                                                                <div className="uael-post__bg-wrap">
                                                                    <div className="uael-post__inner-wrap">
                                                                        <div className="uael-post__thumbnail">
                                                                            <a
                                                                                href="https://appufa.com/%e0%b9%82%e0%b8%94%e0%b8%99%e0%b8%96%e0%b8%b2%e0%b8%a1%e0%b8%95%e0%b8%a3%e0%b8%87%e0%b9%86-%e0%b9%80%e0%b8%9f%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%88%e0%b8%b4%e0%b8%a5-%e0%b8%9f%e0%b8%b2%e0%b8%99/"
                                                                                target="_self"
                                                                                title="โดนถามตรงๆ! เฟอร์จิล ฟาน ไดค์เคลียร์ชัด ซบเรอัล มาดริดหรือไม่?"
                                                                            >
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={800}
                                                                                    height={600}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1.jpg"
                                                                                    className="attachment-full size-full wp-image-7651"
                                                                                    alt=""
                                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1-768x576.jpg 768w"
                                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="uael-post__content-wrap">
                                                                            <h3 className="uael-post__title">
                                                                                <a
                                                                                    href="https://appufa.com/%e0%b9%82%e0%b8%94%e0%b8%99%e0%b8%96%e0%b8%b2%e0%b8%a1%e0%b8%95%e0%b8%a3%e0%b8%87%e0%b9%86-%e0%b9%80%e0%b8%9f%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%88%e0%b8%b4%e0%b8%a5-%e0%b8%9f%e0%b8%b2%e0%b8%99/"
                                                                                    target="_self"
                                                                                >
                                                                                    โดนถามตรงๆ! เฟอร์จิล ฟาน ไดค์เคลียร์ชัด
                                                                                    ซบเรอัล มาดริดหรือไม่?
                                                                                </a>
                                                                            </h3>
                                                                            <div className="uael-post__meta-data">
                                                                                <span className="uael-post__date">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-calendar"
                                                                                    />
                                                                                    29 November 2024
                                                                                </span>
                                                                                <span className="uael-post__comments">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-comments"
                                                                                    />
                                                                                    No Comments
                                                                                </span>
                                                                            </div>
                                                                            <a
                                                                                className="uael-post__read-more elementor-button"
                                                                                href="https://appufa.com/%e0%b9%82%e0%b8%94%e0%b8%99%e0%b8%96%e0%b8%b2%e0%b8%a1%e0%b8%95%e0%b8%a3%e0%b8%87%e0%b9%86-%e0%b9%80%e0%b8%9f%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%88%e0%b8%b4%e0%b8%a5-%e0%b8%9f%e0%b8%b2%e0%b8%99/"
                                                                                target="_self"
                                                                                aria-labelledby="uael-post-7650"
                                                                            >
                                                                                <span className="elementor-button-content-wrapper">
                                                                                    <span className="elementor-button-icon elementor-align-icon-left"></span>
                                                                                    <span
                                                                                        className="elementor-button-text"
                                                                                        id="uael-post-7650"
                                                                                    >
                                                                                        Read More →
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="uael-post-wrapper   ทั้งหมด ข่าวกีฬาที่น่าสนใจ ">
                                                                <div className="uael-post__bg-wrap">
                                                                    <div className="uael-post__inner-wrap">
                                                                        <div className="uael-post__thumbnail">
                                                                            <a
                                                                                href="https://appufa.com/%e0%b9%81%e0%b8%9a%e0%b8%a3%e0%b8%94%e0%b8%a5%e0%b8%b5%e0%b8%a2%e0%b9%8c%e0%b9%80%e0%b8%94%e0%b8%b5%e0%b9%89%e0%b8%a2%e0%b8%87-%e0%b8%a5%e0%b8%b4%e0%b9%80%e0%b8%a7%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%9e/"
                                                                                target="_self"
                                                                                title="แบรดลีย์เดี้ยง! ลิเวอร์พูลเตรียมส่งเทรนต์ อาร์โนลด์บู๊แมนซิตี้"
                                                                            >
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={800}
                                                                                    height={600}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้.jpg"
                                                                                    className="attachment-full size-full wp-image-7646"
                                                                                    alt=""
                                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้-768x576.jpg 768w"
                                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="uael-post__content-wrap">
                                                                            <h3 className="uael-post__title">
                                                                                <a
                                                                                    href="https://appufa.com/%e0%b9%81%e0%b8%9a%e0%b8%a3%e0%b8%94%e0%b8%a5%e0%b8%b5%e0%b8%a2%e0%b9%8c%e0%b9%80%e0%b8%94%e0%b8%b5%e0%b9%89%e0%b8%a2%e0%b8%87-%e0%b8%a5%e0%b8%b4%e0%b9%80%e0%b8%a7%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%9e/"
                                                                                    target="_self"
                                                                                >
                                                                                    แบรดลีย์เดี้ยง! ลิเวอร์พูลเตรียมส่งเทรนต์
                                                                                    อาร์โนลด์บู๊แมนซิตี้
                                                                                </a>
                                                                            </h3>
                                                                            <div className="uael-post__meta-data">
                                                                                <span className="uael-post__date">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-calendar"
                                                                                    />
                                                                                    29 November 2024
                                                                                </span>
                                                                                <span className="uael-post__comments">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-comments"
                                                                                    />
                                                                                    No Comments
                                                                                </span>
                                                                            </div>
                                                                            <a
                                                                                className="uael-post__read-more elementor-button"
                                                                                href="https://appufa.com/%e0%b9%81%e0%b8%9a%e0%b8%a3%e0%b8%94%e0%b8%a5%e0%b8%b5%e0%b8%a2%e0%b9%8c%e0%b9%80%e0%b8%94%e0%b8%b5%e0%b9%89%e0%b8%a2%e0%b8%87-%e0%b8%a5%e0%b8%b4%e0%b9%80%e0%b8%a7%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%9e/"
                                                                                target="_self"
                                                                                aria-labelledby="uael-post-7645"
                                                                            >
                                                                                <span className="elementor-button-content-wrapper">
                                                                                    <span className="elementor-button-icon elementor-align-icon-left"></span>
                                                                                    <span
                                                                                        className="elementor-button-text"
                                                                                        id="uael-post-7645"
                                                                                    >
                                                                                        Read More →
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="uael-post-wrapper   ทั้งหมด ข่าวกีฬาที่น่าสนใจ ">
                                                                <div className="uael-post__bg-wrap">
                                                                    <div className="uael-post__inner-wrap">
                                                                        <div className="uael-post__thumbnail">
                                                                            <a
                                                                                href="https://appufa.com/%e0%b8%84%e0%b8%a7%e0%b8%b5%e0%b8%a7%e0%b8%b4%e0%b8%99-%e0%b9%80%e0%b8%84%e0%b8%a5%e0%b9%80%e0%b8%a5%e0%b9%80%e0%b8%ae%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%ad%e0%b8%99%e0%b8%b2%e0%b8%84%e0%b8%95%e0%b8%8a/"
                                                                                target="_self"
                                                                                title="ควีวิน เคลเลเฮอร์อนาคตชัดเจน! บอสอาร์เน่อแจงแผนการ"
                                                                            >
                                                                                <img
                                                                                    loading="lazy"
                                                                                    decoding="async"
                                                                                    width={800}
                                                                                    height={600}
                                                                                    src="https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ.jpg"
                                                                                    className="attachment-full size-full wp-image-7643"
                                                                                    alt=""
                                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ-768x576.jpg 768w"
                                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="uael-post__content-wrap">
                                                                            <h3 className="uael-post__title">
                                                                                <a
                                                                                    href="https://appufa.com/%e0%b8%84%e0%b8%a7%e0%b8%b5%e0%b8%a7%e0%b8%b4%e0%b8%99-%e0%b9%80%e0%b8%84%e0%b8%a5%e0%b9%80%e0%b8%a5%e0%b9%80%e0%b8%ae%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%ad%e0%b8%99%e0%b8%b2%e0%b8%84%e0%b8%95%e0%b8%8a/"
                                                                                    target="_self"
                                                                                >
                                                                                    ควีวิน เคลเลเฮอร์อนาคตชัดเจน!
                                                                                    บอสอาร์เน่อแจงแผนการ
                                                                                </a>
                                                                            </h3>
                                                                            <div className="uael-post__meta-data">
                                                                                <span className="uael-post__date">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-calendar"
                                                                                    />
                                                                                    29 November 2024
                                                                                </span>
                                                                                <span className="uael-post__comments">
                                                                                    <i
                                                                                        aria-hidden="true"
                                                                                        className="fa fa-comments"
                                                                                    />
                                                                                    No Comments
                                                                                </span>
                                                                            </div>
                                                                            <a
                                                                                className="uael-post__read-more elementor-button"
                                                                                href="https://appufa.com/%e0%b8%84%e0%b8%a7%e0%b8%b5%e0%b8%a7%e0%b8%b4%e0%b8%99-%e0%b9%80%e0%b8%84%e0%b8%a5%e0%b9%80%e0%b8%a5%e0%b9%80%e0%b8%ae%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%ad%e0%b8%99%e0%b8%b2%e0%b8%84%e0%b8%95%e0%b8%8a/"
                                                                                target="_self"
                                                                                aria-labelledby="uael-post-7642"
                                                                            >
                                                                                <span className="elementor-button-content-wrapper">
                                                                                    <span className="elementor-button-icon elementor-align-icon-left"></span>
                                                                                    <span
                                                                                        className="elementor-button-text"
                                                                                        id="uael-post-7642"
                                                                                    >
                                                                                        Read More →
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uael-post__footer"></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div style={{ display: tabselect == 20 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={972}
                            className="elementor elementor-972"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3f3d6ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3f3d6ea"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49abbbb"
                                        data-id="49abbbb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5f0d025 elementor-widget elementor-widget-heading"
                                                data-id="5f0d025"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h1 className="elementor-heading-title elementor-size-default">
                                                        แทงบอลออนไลน์
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-f76710d elementor-widget elementor-widget-image"
                                                data-id="f76710d"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={1200}
                                                        height={460}
                                                        src="https://cdn.javisx.com/image/cbfe2a68-00b4-4985-aebf-02920954165d.png"
                                                        className="attachment-full size-full wp-image-6713"
                                                        alt=""
                                                       // srcSet="https://cdn.javisx.com/image/cbfe2a68-00b4-4985-aebf-02920954165d.png 1200w, https://appufa.com/wp-content/uploads/2024/07/แทงบอลออนไลน์-300x115.png 300w, https://appufa.com/wp-content/uploads/2024/07/แทงบอลออนไลน์-1024x393.png 1024w, https://appufa.com/wp-content/uploads/2024/07/แทงบอลออนไลน์-768x294.png 768w"
                                                        sizes="(max-width: 1200px) 100vw, 1200px"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-20e59d9 elementor-widget elementor-widget-text-editor"
                                                data-id="20e59d9"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        โอกาสทางการเงินในยุคดิจิทัล: การแทงบอลออนไลน์กับ
                                                        <a href="https://enjoy289.win/">
                                                            <strong>UFABET</strong>
                                                        </a>
                                                        &nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            ในยุคสมัยที่เปลี่ยนแปลงอย่างรวดเร็ว
                                                            การแทงบอลออนไลน์กำลังได้รับความนิยมอย่างมาก
                                                            โดยเฉพาะอย่างยิ่งในช่วงที่มีการแพร่ระบาดของ COVID-19
                                                            ซึ่งเป็นกิจกรรมที่สามารถสร้างรายได้จากการลงทุนควบคู่ไปกับการลดความเสี่ยงในการสัมผัสและการติดต่อของโรคระบาด
                                                        </span>
                                                    </p>
                                                    <p>
                                                        ด้วยเหตุนี้
                                                        การเลือกเว็บไซต์พนันออนไลน์ที่มีความน่าเชื่อถือและปลอดภัยจึงเป็นสิ่งสำคัญ
                                                        enjoy289.win
                                                        คือหนึ่งในเว็บไซต์ชั้นนำที่ให้บริการด้านการแทงบอลออนไลน์และพนันกีฬาชนิดอื่นๆ
                                                        อย่างครบวงจร พร้อมให้บริการตลอด 24 ชั่วโมง
                                                        ผู้เล่นสามารถเข้าถึงและทำธุรกรรมได้อย่างสะดวกผ่านอุปกรณ์ต่างๆ
                                                        ไม่ว่าจะเป็นโทรศัพท์มือถือ คอมพิวเตอร์ หรือแท็บเล็ต
                                                    </p>
                                                    <p>
                                                        การแทงบอลออนไลน์นั้นมีหลากหลายรูปแบบให้ผู้เล่นได้เลือกตามความต้องการ
                                                        ไม่ว่าจะเป็นบอลชุด บอลเดี่ยว บอลสเต็ป หรือบอลสูงต่ำ
                                                        นอกจากนี้ยังมีโปรโมชั่นและกิจกรรมพิเศษมากมายที่จะทำให้ประสบการณ์การเดิมพันของคุณยิ่งมีความสนุกและคุ้มค่ามากขึ้น&nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            ลงทุนง่าย สร้างรายได้ง่าย ด้วยการแทงบอลออนไลน์กับ enjoy289.win
                                                            เว็บไซต์ชั้นนำที่มอบความปลอดภัยและความสะดวกสบายในการเดิมพันของคุณ
                                                            <br />
                                                        </span>
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <span
                                                                style={{
                                                                    color: "var( --e-global-color-text )",
                                                                    fontFamily:
                                                                        "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                    fontWeight:
                                                                        "var( --e-global-typography-text-font-weight )",
                                                                    fontSize: "1rem"
                                                                }}
                                                            >
                                                                สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                                <a
                                                                    href={this.state.webnamex + "/register"}
                                                                    target="_blank"
                                                                    rel="noopener"
                                                                >
                                                                    {this.state.webnamex + "/register"}
                                                                </a>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-0620485 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id={""}
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2e802a6"
                                        data-id="2e802a6"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-e237b0b elementor-widget elementor-widget-text-editor"
                                                data-id="e237b0b"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>ข้อดีของการ แทงบอลออนไลน์</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-98e8584 elementor-widget elementor-widget-text-editor"
                                                data-id="98e8584"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ไม่ว่าคุณจะเป็นนักเดิมพันมือใหม่หรือมืออาชีพ
                                                        การแทงบอลออนไลน์นั้นมีข้อดีมากมายที่คุณไม่ควรพลาด
                                                    </p>
                                                    <p>
                                                        1. ความสะดวกสบายที่ไร้ขีดจำกัด
                                                        <br />
                                                        การแทงบอลออนไลน์นั้นสามารถทำได้ทุกที่ทุกเวลา ไม่ว่าจะอยู่ที่ไหน
                                                        เพียงแค่มีอุปกรณ์ที่เชื่อมต่ออินเทอร์เน็ต
                                                        คุณก็สามารถเข้าไปลงเดิมพันได้ทันที
                                                        นอกจากนี้ยังสามารถถอนเงินรางวัลได้อย่างรวดเร็ว เพียง 5-10
                                                        นาทีเท่านั้น
                                                    </p>
                                                    <p>
                                                        2. ความปลอดภัยอย่างแน่นอน
                                                        <br />
                                                        เมื่อเลือกใช้บริการจาก enjoy289.win
                                                        คุณจะไม่ต้องกังวลเรื่องความปลอดภัยของเงินทุน
                                                        เพราะเรามีทีมงานมืออาชีพคอยดูแลตลอด 24 ชั่วโมง
                                                        ไม่ว่าคุณจะเดิมพันหรือถอนเงินเท่าไหร่
                                                        เงินของคุณจะยังคงปลอดภัยอยู่เสมอ
                                                    </p>
                                                    <p>
                                                        3. ความหลากหลายของรูปแบบการเดิมพัน
                                                        <br />
                                                        ในการแทงบอลออนไลน์ คุณสามารถเลือกรูปแบบการเดิมพันที่หลากหลาย
                                                        ไม่ว่าจะเป็นบอลชุด บอลเดี่ยว บอลสเต็ป บอลสูงต่ำ หรือบอลเต็ง
                                                        นักเดิมพันทุกระดับจะได้สนุกสนานและเพิ่มความตื่นเต้นในการลงเดิมพันแต่ละครั้ง
                                                    </p>
                                                    <p>
                                                        4. โปรโมชั่นและโบนัสน่าสนใจ
                                                        <br />
                                                        เมื่อเลือกแทงบอลออนไลน์ คุณจะได้รับสิทธิพิเศษมากมาย
                                                        ไม่ว่าจะเป็นโบนัสตั้งแต่เป็นสมาชิกใหม่ โบนัสเมื่อฝากเงิน
                                                        หรือแม้แต่ค่าคอมมิชชั่นสำหรับการแนะนำเพื่อน
                                                        ซึ่งช่วยเพิ่มโอกาสในการทำกำไรให้คุณอย่างแน่นอน
                                                    </p>
                                                    <p>
                                                        5. ตัวเลือกบอลที่ครบครัน
                                                        <br />
                                                        ด้วยการแทงบอลออนไลน์
                                                        คุณสามารถเลือกแทงได้ทั้งบอลไทยและบอลต่างประเทศ
                                                        ไม่ว่าจะเป็นลีกใหญ่หรือลีกเล็ก
                                                        ซึ่งจะช่วยเพิ่มความสนุกสนานและความตื่นเต้นให้กับการเดิมพันของคุณ
                                                    </p>
                                                    <p>
                                                        ดังนั้น หากคุณกำลังมองหาประสบการณ์การเดิมพันที่ดีกว่า
                                                        การแทงบอลออนไลน์คือตัวเลือกที่ดีที่สุดสำหรับคุณ
                                                        ลองค้นหาข้อมูลเพิ่มเติมและเริ่มสร้างความสนุกสนานในการเดิมพันกันเลย
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-852b27c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="852b27c"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4dc8828"
                                        data-id="4dc8828"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-750bcd7 elementor-widget elementor-widget-text-editor"
                                                data-id="750bcd7"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>
                                                        แนะนำ เทคนิคการแทงบอลออนไลน์ ที่มีประสิทธิภาพสำหรับมือใหม่
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-2f23682 elementor-widget elementor-widget-text-editor"
                                                data-id="2f23682"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        การแทงบอลออนไลน์อาจดูเหมือนเป็นแค่การวัดดวง แต่จริงๆ แล้ว
                                                        มีเทคนิคมากมายที่สามารถช่วยให้คุณทำกำไรได้มากขึ้น
                                                        ลองนำเทคนิคต่อไปนี้มาทดลองใช้ดู:
                                                    </p>
                                                    <p>
                                                        1. วิเคราะห์ข้อมูลอย่างละเอียดก่อนลงเดิมพัน
                                                        <br />– ศึกษาสถิติ, ฟอร์ม, และสภาพความพร้อมของทีมก่อนตัดสินใจ
                                                        <br />– พิจารณาปัจจัยต่างๆ เช่น
                                                        ความได้เปรียบในการเล่นในบ้านหรือนอกบ้าน
                                                    </p>
                                                    <p>
                                                        2. อย่ารีบเร่งลงเดิมพันทันทีก่อนเกมเริ่ม
                                                        <br />– ใช้เวลาวิเคราะห์สถานการณ์ในช่วงครึ่งแรกก่อนตัดสินใจ
                                                        <br />– การรอช่วงก่อนหรือระหว่างเกมจะช่วยให้คุณตัดสินใจได้ดีขึ้น
                                                    </p>
                                                    <p>
                                                        3. หลีกเลี่ยงการเดิมพันบอลเต็งและบอลต่อ
                                                        <br />– บอลเต็งและบอลต่อมักมีความเสี่ยงสูง
                                                        และอาจมีการกำกับดูแลที่ไม่เป็นธรรม
                                                        <br />– แทนที่ให้เลือกเดิมพันบอลรอง
                                                        ซึ่งมีสถิติการทำกำไรที่ดีกว่า
                                                    </p>
                                                    <p>
                                                        จงใช้ความรอบคอบและวิเคราะห์อย่างถี่ถ้วน
                                                        การแทงบอลออนไลน์ที่ประสบความสำเร็จต้องอาศัยทั้งความรู้และการจัดการความเสี่ยงอย่างชาญฉลาด
                                                        หวังว่าเทคนิคเหล่านี้จะช่วยให้คุณสามารถทำกำไรจากการแทงบอลออนไลน์ได้มากขึ้น
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-c134f33 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="c134f33"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8938547"
                                        data-id={8938547}
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap"></div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 21 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={2950}
                            className="elementor elementor-2950"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3f3d6ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3f3d6ea"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49abbbb"
                                        data-id="49abbbb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5f0d025 elementor-widget elementor-widget-heading"
                                                data-id="5f0d025"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h1 className="elementor-heading-title elementor-size-default">
                                                        คาสิโนออนไลน์
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-d68e987 elementor-widget elementor-widget-image"
                                                data-id="d68e987"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={1200}
                                                        height={460}
                                                        src="https://cdn.javisx.com/image/1a1d9693-2d83-4efb-9f92-53dab3265cbd.png"
                                                        className="attachment-full size-full wp-image-6715"
                                                        alt=""
                                                      //  srcSet="https://cdn.javisx.com/image/1a1d9693-2d83-4efb-9f92-53dab3265cbd.png 1200w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์-300x115.png 300w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์-1024x393.png 1024w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์-768x294.png 768w"
                                                        sizes="(max-width: 1200px) 100vw, 1200px"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-a3461fa elementor-widget elementor-widget-text-editor"
                                                data-id="a3461fa"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        <strong>ENJOY289</strong>
                                                        คือผู้ให้บริการคาสิโนออนไลน์ชั้นนำที่มีประสบการณ์ยาวนาน
                                                        เราเปิดให้บริการเกมคาสิโนออนไลน์ครบวงจร ไม่ว่าจะเป็นบาคาร่า
                                                        รูเล็ต สล็อต และอื่นๆอีกมากมาย
                                                        สามารถเข้าร่วมสนุกได้ทุกที่ทุกเวลา ผ่านอุปกรณ์พกพาต่างๆ เช่น
                                                        โทรศัพท์มือถือ, แท็บเล็ต หรือคอมพิวเตอร์ ตามความสะดวกของคุณ
                                                    </p>
                                                    <p>
                                                        ยิ่งไปกว่านั้น เรายังมอบสิทธิประโยชน์พิเศษมากมาย ได้แก่
                                                        เครดิตฟรี โบนัสสุดคุ้ม และโปรโมชันดีๆอีกเพียบ
                                                        นอกจากนี้ระบบการทำธุรกรรมการเงินของเราได้รับการออกแบบมาอย่างปลอดภัยและไร้รอยต่อ
                                                        ทำให้คุณสามารถฝากและถอนเงินด้วยตนเองได้ตลอด 24 ชั่วโมง
                                                    </p>
                                                    <p>
                                                        ในสถานการณ์การแพร่ระบาดของโรคโควิด-19 ที่กำลังดำเนินอยู่
                                                        หากคุณยังคงเล่นพนันหรือประกอบอาชีพเสริมด้านการพนัน
                                                        แต่ไม่อยากเสี่ยงไปในสถานที่แออัดอย่างบ่อนหรือคาสิโน เราขอแนะนำ
                                                        <a href="https://enjoy289.win/">enjoy289.win</a>
                                                        เว็บคาสิโนออนไลน์อันดับหนึ่งที่จะมอบประสบการณ์การเล่นเกมคาสิโนออนไลน์อย่างสนุกสนานและปลอดภัย
                                                        พร้อมโอกาสในการสร้างรายได้เสริมให้แก่คุณ
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a
                                                                href={this.state.webnamex + "/register"}
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-9bb82ab elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="9bb82ab"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35b5d96"
                                        data-id="35b5d96"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-c9fca7c elementor-widget elementor-widget-text-editor"
                                                data-id="c9fca7c"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>คาสิโนออนไลน์ คืออะไร?</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-4f4fb74 elementor-widget elementor-widget-text-editor"
                                                data-id="4f4fb74"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        เรามาเริ่มกันเลยครับ
                                                        คาสิโนออนไลน์เป็นรูปแบบการพนันที่กำลังได้รับความนิยมอย่างสูงจากนักพนันชาวไทยในช่วงไม่กี่ปีที่ผ่านมา
                                                        โดยเฉพาะอย่างยิ่งในปีก่อนหน้า
                                                        จำนวนนักพนันหน้าใหม่ที่เล่นคาสิโนออนไลน์ในประเทศไทยพุ่งสูงขึ้นอย่างมหาศาล
                                                        ถือได้ว่าเป็นยุคทองของวงการคาสิโนออนไลน์เลยทีเดียว
                                                        มีปัจจัยหลายประการที่ทำให้คาสิโนออนไลน์เป็นที่นิยมของนักพนันชาวไทย
                                                    </p>
                                                    <p>
                                                        หนึ่งในสาเหตุหลักที่ส่งผลให้คาสิโนออนไลน์ได้รับความนิยมอย่างกว้างขวาง
                                                        คือการเข้าถึงเทคโนโลยีที่ง่ายขึ้นในยุคปัจจุบัน
                                                        เพียงแค่มีสมาร์ทโฟนเครื่องเดียว
                                                        ก็สามารถสร้างรายได้ให้กับตนเองได้แล้ว ดังนั้น ธุรกิจต่างๆ
                                                        รวมถึงการสื่อสารในปัจจุบันจึงจำเป็นต้องอาศัยอินเตอร์เน็ตเป็นหลัก
                                                        เนื่องจากช่วยประหยัดทั้งเงินและเวลา
                                                        คาสิโนออนไลน์จึงกลายเป็นทางเลือกที่ดีและเหมาะสมที่สุดสำหรับนักพนัน
                                                    </p>
                                                    <p>
                                                        นอกจากความนิยมและกระแสของคาสิโนออนไลน์ในช่วง 1-2 ปีที่ผ่านมาแล้ว
                                                        ตั้งแต่ก่อนสิ้นปีที่แล้วจนถึงต้นปี 2564
                                                        เหตุการณ์ที่ส่งผลกระทบรุนแรงต่อทั่วโลกทั้งทางตรงและทางอ้อม
                                                        คือการแพร่ระบาดของไวรัสโคโรนาสายพันธุ์ใหม่
                                                        หรือที่เรารู้จักกันในนาม COVID-19
                                                    </p>
                                                    <p>
                                                        เพื่อควบคุมการแพร่ระบาดของโรคร้ายนี้
                                                        หลายประเทศจึงได้ออกมาตรการเร่งด่วน เนื่องจากโควิด-19
                                                        เป็นไวรัสสายพันธุ์ใหม่ที่ไม่เคยพบมาก่อน
                                                        ยังไม่มีแนวทางการรักษาที่ชัดเจน และสิ่งที่น่ากังวลคือ
                                                        โรคนี้แพร่กระจายได้อย่างรวดเร็วและรุนแรง
                                                        จึงมีคำสั่งปิดสถานที่ที่มีการรวมกลุ่มคน เช่น ห้างสรรพสินค้า
                                                        สถานศึกษา บ่อนคาสิโน และสนามกีฬา เป็นต้น
                                                    </p>
                                                    <p>
                                                        สำหรับประเทศที่ยังคงประสบกับสถานการณ์การแพร่ระบาดที่รุนแรงอย่างต่อเนื่อง
                                                        ได้มีการประกาศใช้กฎหมายควบคุมสถานการณ์ฉุกเฉิน
                                                        เพื่อสั่งปิดสถานที่ต่างๆ เป็นการชั่วคราวจนกว่าสถานการณ์จะดีขึ้น
                                                        แม้ว่าประเทศไทยจะไม่มีบ่อนคาสิโนถูกกฎหมาย
                                                        แต่ก็ยอมรับไม่ได้ว่ามีการเปิดให้บริการบ่อนพนันผิดกฎหมายจำนวนมากในประเทศ
                                                    </p>
                                                    <p>
                                                        การปิดบ่อนพนันหรือบ่อนคาสิโนเหล่านี้
                                                        ทำให้นักพนันหลายคนต้องหยุดเล่นพนันไปโดยปริยาย
                                                        และไม่ง่ายเลยที่จะห้ามปรามนักพนันกลุ่มนี้ให้เลิกเล่น
                                                        เพราะบางคนถือว่าการพนันคือหนึ่งในอาชีพของพวกเขา
                                                        แต่สิ่งสำคัญที่ทุกคนควรให้ความสนใจมากที่สุด คือการดูแลสุขภาพ
                                                        เพราะมีผลโดยตรงต่อการดำรงชีวิตทั้งในปัจจุบันและอนาคต
                                                    </p>
                                                    <p>
                                                        เพื่อลดความเสี่ยงในการติดเชื้อหรือแพร่กระจายเชื้อไวรัส
                                                        คาสิโนออนไลน์จึงกลายเป็นทางเลือกที่ดีที่สุดในช่วงเวลานี้
                                                        เนื่องจากคาสิโนออนไลน์ได้รับการพัฒนาทั้งระบบและรูปแบบการ
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3fb3949 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3fb3949"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5fb3ca2"
                                        data-id="5fb3ca2"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-e39b730 elementor-widget elementor-widget-text-editor"
                                                data-id="e39b730"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>
                                                        เล่นคาสิโนออนไลน์ยังไงให้ปลอดภัย ได้เงินจริงแน่นอน ไม่โดนโกง
                                                        <br />
                                                        <br />
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-ae84f42 elementor-widget elementor-widget-text-editor"
                                                data-id="ae84f42"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ในการเข้าสู่วงการพนันออนไลน์อย่างมั่นคงและปลอดภัย
                                                        มีข้อควรคำนึงที่สำคัญยิ่งดังนี้
                                                    </p>
                                                    <p>
                                                        1. เลือกเว็บไซต์ที่น่าเชื่อถือและมีความมั่นคงสูง เช่น enjoy289.win
                                                        ซึ่งเป็นแพลตฟอร์มยอดนิยมในหมู่นักพนัน
                                                        เนื่องจากระบบการรักษาความปลอดภัยที่เข้มงวดและมั่นใจได้ว่าผู้เล่นจะไม่ต้องวิตกกังวลใดๆ
                                                    </p>
                                                    <p>
                                                        2. ระบบเว็บไซต์ต้องมีเสถียรภาพสูง
                                                        เพื่อให้ผู้เล่นสามารถเพลิดเพลินกับการพนันได้อย่างราบรื่น
                                                        ไร้การสะดุดหรือความกระตุกใดๆ ที่อาจทำให้เกิดความรำคาญใจได้
                                                    </p>
                                                    <p>
                                                        3. เลือกแพลตฟอร์มที่มีช่องทางการติดต่อสื่อสารที่สะดวกและรวดเร็ว
                                                        เพื่อให้สามารถแก้ไขปัญหาหรือข้อสงสัยต่างๆ ได้อย่างทันท่วงที
                                                    </p>
                                                    <p>
                                                        4. ศึกษาระบบการฝากและถอนเงินให้ละเอียดว่ามีขั้นตอนที่ง่าย สะดวก
                                                        และรวดเร็วหรือไม่ เพื่อความมั่นใจและสบายใจในการใช้บริการ
                                                    </p>
                                                    <p>
                                                        5. เลือกเว็บไซต์ที่มีโปรโมชั่นและข้อเสนอสุดพิเศษ
                                                        รวมถึงโบนัสต้อนรับเพื่อเพิ่มสีสันและความสนุกสนานในการเดิมพัน
                                                    </p>
                                                    <p>
                                                        6.
                                                        ทำความเข้าใจกฎกติกาและเงื่อนไขของแพลตฟอร์มอย่างละเอียดก่อนเริ่มเดิมพัน
                                                        รวมถึงกฎของแต่ละเกมพนันที่ตนสนใจ
                                                    </p>
                                                    <p>
                                                        7.
                                                        ทดลองเล่นเกมพนันที่สนใจด้วยตนเองก่อนเพื่อหาสิ่งที่ถนัดและชื่นชอบ
                                                        เนื่องจากบางเกมอาจให้ความรู้สึกแตกต่างจากการเล่นในคาสิโนจริง
                                                    </p>
                                                    <p>
                                                        8. ใช้เงินลงทุนอย่างระมัดระวัง
                                                        โดยแน่ใจว่าหากเสียเงินเดิมพันจะไม่ส่งผลกระทบต่อสถานะทางการเงินส่วนตัว
                                                    </p>
                                                    <p>
                                                        9. รักษาสมาธิและควบคุมอารมณ์ได้
                                                        เนื่องจากการชนะหรือแพ้เป็นเรื่องปกติในการพนัน
                                                        ไม่ควรหงุดหงิดหรือโมโหจนขาดสติเมื่อแพ้
                                                    </p>
                                                    <p>
                                                        10. เลือกเล่นเฉพาะเกมที่ตนเองสนใจจริงๆ จากหลากหลายทางเลือก
                                                        เพราะจะช่วยเพิ่มโอกาสในการชนะได้มากขึ้น
                                                    </p>
                                                    <p>
                                                        นี่คือคำแนะนำสำคัญสำหรับนักพนันทั้งมือใหม่และมืออาชีพที่ต้องการสัมผัสประสบการณ์คาสิโนออนไลน์อย่างสนุกสนานและปลอดภัย
                                                        หากปฏิบัติตามข้อแนะนำเหล่านี้อย่างเคร่งครัด
                                                        การสร้างรายได้จำนวนมหาศาลจากการพนันออนไลน์ก็มิใช่เรื่องยากเกินเอื้อมแน่นอน
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-4a83d6d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4a83d6d"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bf8c9ea"
                                        data-id="bf8c9ea"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap"></div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 22 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={978}
                            className="elementor elementor-978"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-e329bf3 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="e329bf3"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c39cde5"
                                        data-id="c39cde5"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-82f7754 elementor-widget elementor-widget-heading"
                                                data-id="82f7754"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h1 className="elementor-heading-title elementor-size-default">
                                                        สล็อตออนไลน์
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-cd43f7e elementor-widget elementor-widget-image"
                                                data-id="cd43f7e"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        decoding="async"
                                                        src="https://cdn.javisx.com/image/fe90e982-f489-4329-990e-1e3b9241ddf0.png"
                                                        title="สล็อตออนไลน์"
                                                        alt="สล็อตออนไลน์"
                                                        loading="lazy"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-105a8d9 elementor-widget elementor-widget-text-editor"
                                                data-id="105a8d9"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        เกมสล็อตออนไลน์ หรือที่เรียกว่าสล็อตออนไลน์นั้น
                                                        ถือเป็นหนึ่งในเกมพนันยอดนิยมบนโลกออนไลน์ในขณะนี้
                                                        เนื่องด้วยรูปแบบการเล่นที่เข้าใจง่าย น่าตื่นเต้น
                                                        พร้อมโอกาสในการชนะรางวัลใหญ่ที่สูงกว่าเกมอื่นๆ
                                                        อีกทั้งยังมีความสะดวกสบายในการเข้าร่วมสนุก ไม่ซับซ้อนยุ่งยาก
                                                        มีทั้งภาพกราฟิกและเสียงประกอบที่หลากหลายน่าสนใจ
                                                        รวมถึงมีเกมให้เลือกเล่นมากมายหลายรูปแบบ
                                                    </p>
                                                    <p>
                                                        ด้วยเหตุนี้ <strong>ENJOY289</strong> จึงได้พัฒนาระบบ ยูฟ่าสล็อต
                                                        ขึ้นมาโดยเฉพาะ
                                                        เพื่ออำนวยความสะดวกแก่สมาชิกที่ต้องการเล่นเกมสล็อตออนไลน์ต่างๆ
                                                        โดยเว็บไซต์ของเราเป็นตัวแทนอย่างเป็นทางการของ
                                                        <a href="https://enjoy289.win/">
                                                            <strong>UFABET</strong>
                                                        </a>
                                                        ผู้ให้บริการเว็บตรงชั้นนำ ไม่ต้องผ่านนายหน้าใดๆ
                                                        ซึ่งได้รับอนุญาตให้ดำเนินการในประเทศไทยอย่างถูกต้อง
                                                        เราได้พัฒนาระบบเพื่อรองรับสมาชิกชาวไทยให้สามารถเข้าร่วมสนุกได้ครบทุกเกม
                                                        ทุกค่ายผู้ผลิต สามารถเล่นสล็อตออนไลน์ได้ทั้งบนมือถือ คอมพิวเตอร์
                                                        และแท็บเล็ต ไม่ว่าคุณจะอยู่ที่ใด
                                                        ก็สามารถเพลิดเพลินกับสล็อตออนไลน์ได้ทุกเมื่อตามต้องการ
                                                    </p>
                                                    <p>
                                                        นอกจากนี้ ยังมีโบนัสและสิทธิประโยชน์มากมายรอคุณอยู่
                                                        รวมถึงสล็อตออนไลน์ฟรีเครดิต ที่นักพนันสล็อตทุกท่านไม่ควรพลาด
                                                        พร้อมระบบอัตโนมัติที่ไม่มีวงเงินฝาก-ถอนขั้นต่ำ
                                                        และมีทีมงานมืออาชีพคอยดูแลให้คำปรึกษาตลอด 24 ชั่วโมง
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a
                                                                href={this.state.webnamex + "/register"}
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-652b7b9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="652b7b9"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-50ec2cc"
                                        data-id="50ec2cc"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-adfed8b elementor-widget elementor-widget-text-editor"
                                                data-id="adfed8b"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>สล็อตออนไลน์ คืออะไร?</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-b144444 elementor-widget elementor-widget-text-editor"
                                                data-id="b144444"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ในยุคสมัยที่เทคโนโลยีมีบทบาทสำคัญในชีวิตประจำวัน
                                                        การพนันออนไลน์ก็ได้รับความนิยมเพิ่มขึ้นอย่างมาก
                                                        โดยเฉพาะสล็อตออนไลน์
                                                        ซึ่งเป็นการนำเอาตู้สล็อตแมชชีนดั้งเดิมมาปรับให้อยู่ในรูปแบบเกมอิเล็กทรอนิกส์บนอินเทอร์เน็ต
                                                        ผู้เล่นสามารถเพลิดเพลินไปกับการหมุนวงล้อสล็อตได้ผ่านโปรแกรมหรือเว็บคาสิโนออนไลน์ต่างๆ
                                                    </p>
                                                    <p>
                                                        สิ่งที่ทำให้สล็อตออนไลน์โดดเด่นกว่าสล็อตแมชชีนแบบดั้งเดิมก็คือความสะดวกสบายในการเข้าถึง
                                                        ด้วยเพียงการเชื่อมต่ออินเทอร์เน็ต
                                                        ผู้เล่นก็สามารถสนุกกับเกมสล็อตได้ทุกหนทุกแห่ง
                                                        ไม่ว่าจะเป็นบนมือถือ แท็บเล็ต หรือคอมพิวเตอร์
                                                        ขั้นตอนก็สะดวกรวดเร็ว เริ่มจากการสมัครสมาชิก ฝากเงิน
                                                        และก็พร้อมออกรอบแล้ว
                                                    </p>
                                                    <p>
                                                        นอกจากนี้ สล็อตออนไลน์ยังมีข้อได้เปรียบในด้านความหลากหลายของเกม
                                                        ด้วยตัวเลือกมากกว่า 200 เกมพร้อมกราฟิกและเสียงประกอบสมจริง
                                                        ผู้เล่นจึงไม่ต้องเบื่อกับความน่าตื่นเต้นและโอกาสในการสร้างรายได้จากการเดิมพันอีกต่อไป
                                                        เว็บไซต์ufathai.com
                                                        เป็นจุดหมายปลายทางที่น่าสนใจสำหรับนักเสี่ยงโชคชาวไทย
                                                        ด้วยบริการสล็อตออนไลน์ที่มีมาตรฐานและถูกกฎหมาย
                                                    </p>
                                                    <p>
                                                        การเล่นสล็อตออนไลน์ในคาสิโนออนไลน์นั้นมีกฎกติกาและข้อตกลงคล้ายคลึงกับตู้สล็อตแมชชีนทั่วไป
                                                        แต่ด้วยข้อได้เปรียบในด้านความสะดวกสบาย ความหลากหลาย
                                                        และความบันเทิงระดับพรีเมียม
                                                        สล็อตออนไลน์จึงกลายเป็นทางเลือกยอดนิยมของคนรุ่นใหม่และนักพนันมืออาชีพในปัจจุบัน
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-0ee8359 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="0ee8359"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a45b130"
                                        data-id="a45b130"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-e659f49 elementor-widget elementor-widget-text-editor"
                                                data-id="e659f49"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>สล็อตออนไลน์เล่นยังไง</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-d2fd0d2 elementor-widget elementor-widget-text-editor"
                                                data-id="d2fd0d2"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        สำหรับผู้ที่หลงใหลในการเสี่ยงโชคกับเกมสล็อตออนไลน์
                                                        กฎเกณฑ์และกติกาพื้นฐานที่ใช้ในการเล่นนั้นมีความคล้ายคลึงกันในทุกเว็บไซต์
                                                        เมื่อเริ่มต้นเล่นเกมสล็อตออนไลน์
                                                        ผู้เล่นจะต้องใช้ยอดเครดิตที่มีอยู่ในบัญชีผู้ใช้งาน
                                                        ซึ่งเปรียบเสมือนเหรียญที่ใช้หยอดในตู้สล็อตแบบดั้งเดิม
                                                        จากนั้นให้เลือกเกมสล็อตออนไลน์ที่สนใจ
                                                        กำหนดอัตราการเดิมพันและจำนวนไลน์ที่ต้องการเล่น แล้วกดปุ่ม Play
                                                        หรือ Spin
                                                    </p>
                                                    <p>
                                                        ผู้เล่นจะต้องหมุนกงล้อให้ได้สัญลักษณ์ภาพเรียงกันตามไลน์ที่เลือกไว้
                                                        โดยคิดคะแนนจากการเรียงสัญลักษณ์ตั้งแต่ 2 หรือ 3 ภาพขึ้นไป
                                                        ซึ่งอาจเรียงจากซ้ายไปขวาหรือขวาไปซ้ายก็ได้ขึ้นอยู่กับเกม
                                                        แต่โดยส่วนใหญ่แล้ว ในการเล่นเกมสล็อตออนไลน์แบบ 5 รีล 5 กงล้อนั้น
                                                        สัญลักษณ์ภาพจะต้องเรียงกันตั้งแต่ 3 ภาพขึ้นไป
                                                        หรือเรียงจากซ้ายไปขวา หากชนะ
                                                        ผู้เล่นจะได้รับรางวัลตามกติกาของแต่ละเว็บไซต์
                                                    </p>
                                                    <p>
                                                        คำว่า “สล็อตออนไลน์ฟรีเครดิต”
                                                        หมายถึงโปรโมชั่นที่คาสิโนออนไลน์จัดทำขึ้น
                                                        เพื่อดึงดูดลูกค้าให้เข้ามาเล่นเกม
                                                        ไม่ว่าจะเป็นคาสิโนเว็บไซต์โดยตรงหรือคาสิโนผ่านเอเย่นต์
                                                        ต่างก็แข่งขันกันจัดโปรโมชั่นและโบนัสฟรีให้กับผู้เล่น
                                                        ซึ่งเงื่อนไขไม่ยุ่งยากนัก
                                                        เพียงแค่เข้าร่วมกิจกรรมตามเงื่อนไขที่กำหนด
                                                        เว็บไซต์ส่วนใหญ่จะใช้โปรโมชั่นในการแจกเงินเริ่มต้นตั้งแต่ 100
                                                        บาท จนถึง 300 บาทขึ้นไป
                                                        หรือบางเว็บก็มีโปรโมชั่นแจกเงินเพิ่มเมื่อเติมเงินครบตามจำนวนที่กำหนด
                                                        เช่น เติมเงิน 500 บาท จะได้รับโบนัสฟรีอีก 300 บาท เป็นต้น
                                                    </p>
                                                    <p>
                                                        เว็บ enjoy289.win คือตัวเลือกที่ปลอดภัยสำหรับคุณ&nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            ในโลกออนไลน์ที่ซับซ้อนและมีความเสี่ยงมากมาย
                                                            การเลือกเว็บไซต์สล็อตที่ปลอดภัยและน่าเชื่อถือจึงเป็นเรื่องสำคัญ
                                                            ซึ่งเว็บ enjoy289.win
                                                            สามารถตอบโจทย์ความกังวลของผู้เล่นได้เป็นอย่างดี
                                                        </span>
                                                    </p>
                                                    <p>
                                                        ด้วยมาตรฐานการให้บริการที่รวดเร็วและระบบรักษาความปลอดภัยในระดับสูง
                                                        คุณสามารถวางใจได้ว่าทุกการเดิมพันจะปลอดภัยจากการโกงหรือการเจาะระบบ
                                                        เราให้ความสำคัญกับความปลอดภัยของผู้เล่นเป็นอันดับแรก&nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            เลือก enjoy289.win
                                                            เพื่อประสบการณ์การเล่นสล็อตที่ปลอดภัยและมั่นใจ
                                                            รับรองว่าคุณจะได้รับเงินรางวัลอย่างแน่นอน
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-0dcd3e0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="0dcd3e0"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-be648bb"
                                        data-id="be648bb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap"></div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 23 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={2951}
                            className="elementor elementor-2951"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3f3d6ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3f3d6ea"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49abbbb"
                                        data-id="49abbbb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5f0d025 elementor-widget elementor-widget-heading"
                                                data-id="5f0d025"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">
                                                        เดิมพันกีฬา กับ UFABET
                                                    </h4>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-bdb893e elementor-widget elementor-widget-image"
                                                data-id="bdb893e"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={1200}
                                                        height={800}
                                                        src="https://cdn.javisx.com/image/f53babd1-209a-4a68-ad10-8488f772dd0d.png"
                                                        className="attachment-full size-full wp-image-6623"
                                                        alt=""
                                                      //  srcSet="https://appufa.com/wp-content/uploads/2024/07/เดิมพันกีฬา.png 1200w, https://appufa.com/wp-content/uploads/2024/07/เดิมพันกีฬา-300x200.png 300w, https://appufa.com/wp-content/uploads/2024/07/เดิมพันกีฬา-1024x683.png 1024w, https://appufa.com/wp-content/uploads/2024/07/เดิมพันกีฬา-768x512.png 768w"
                                                        sizes="(max-width: 1200px) 100vw, 1200px"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-9937d50 elementor-widget elementor-widget-text-editor"
                                                data-id="9937d50"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ในยุคดิจิทัลที่เทคโนโลยีก้าวหน้าไปอย่างรวดเร็ว
                                                        การเดิมพันกีฬาก็ได้รับการปรับเปลี่ยนให้ทันสมัยตามไปด้วย
                                                        ด้วยการเปิดตัวของแพลตฟอร์มเดิมพันกีฬาออนไลน์อย่าง
                                                        <a href="https://enjoy289.win/">
                                                            <strong>UFABET</strong>
                                                        </a>
                                                        ที่ได้รวบรวมการเดิมพันกีฬาทุกรูปแบบไว้ในที่เดียว
                                                        ไม่ว่าจะเป็นฟุตบอล บาสเกตบอล เทนนิส หรือกีฬายอดนิยมอื่นๆ
                                                        ผู้ใช้งานสามารถเข้าถึงและเดิมพันได้อย่างสะดวกสบาย ตลอด 24
                                                        ชั่วโมง
                                                        <br />
                                                        ENJOY289 มอบประสบการณ์การเดิมพันที่ไร้ขีดจำกัด
                                                    </p>
                                                    <p>
                                                        ด้วยการออกแบบแพลตฟอร์มที่ใช้งานง่าย
                                                        เข้าถึงได้อย่างรวดเร็วผ่านทุกอุปกรณ์ไม่ว่าจะเป็นคอมพิวเตอร์
                                                        โทรศัพท์มือถือ หรือแท็บเล็ต เพียงปลายนิ้วสัมผัส
                                                        คุณก็สามารถเข้าถึงการเดิมพันกีฬาชนิดต่างๆ ได้ตลอดเวลา
                                                        ด้วยการรวบรวมกีฬาชั้นนำระดับโลกไว้ในแพลตฟอร์มเดียว ENJOY289
                                                        จึงเป็นช่องทางที่เหมาะสำหรับนักเดิมพันที่ต้องการสัมผัสประสบการณ์การเดิมพันที่หลากหลาย
                                                        นอกจากฟุตบอลและบาสเกตบอลแล้ว คุณยังสามารถเดิมพันกีฬาอื่นๆ
                                                        อย่างเช่น เทนนิส มวยปล้ำ กอล์ฟ และอื่นๆ อีกมากมาย
                                                    </p>
                                                    <p>
                                                        ซึ่งช่วยเพิ่มความตื่นเต้นและความหลากหลายให้กับการเดิมพันของคุณ
                                                        ENJOY289 ใช้ระบบรักษาความปลอดภัยที่ทันสมัย
                                                        ด้วยการเข้ารหัสข้อมูลและการตรวจสอบการทำธุรกรรมอย่างเข้มงวด
                                                        เพื่อมอบความมั่นใจให้กับผู้ใช้งานในทุกการเดิมพัน นอกจากนี้
                                                        ยังมีการสนับสนุนและให้บริการลูกค้าตลอด 24 ชั่วโมง
                                                        เพื่อตอบสนองความต้องการของนักเดิมพันอย่างรวดเร็วและมีประสิทธิภาพ
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a
                                                                href={this.state.webnamex + "/register"}
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-5420530 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id={5420530}
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a08c39e"
                                        data-id="a08c39e"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-ca7e7b5 elementor-widget elementor-widget-text-editor"
                                                data-id="ca7e7b5"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>มวยพักยก การ แทงมวยออนไลน์ รูปแบบใหม่</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-0028baf elementor-widget elementor-widget-text-editor"
                                                data-id="0028baf"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        มวยพักยก
                                                        เป็นรูปแบบใหม่ของการแทงมวยที่กำลังได้รับความนิยมอย่างแพร่หลายในปัจจุบัน
                                                        ซึ่งเป็นผลมาจากการเจริญเติบโตของเทคโนโลยีและการเชื่อมต่ออินเทอร์เน็ตที่ทำให้ผู้คนสามารถเข้าถึงการแข่งขันมวยจากทุกที่ทุกเวลาได้
                                                        ไม่ว่าจะเป็นผู้เล่นมวยมืออาชีพหรือผู้ชื่นชอบมวยทั่วไป
                                                    </p>
                                                    <p>
                                                        ก็สามารถเข้าร่วมแทงมวยได้อย่างง่ายดายและสะดวกสบาย
                                                        มวยพักยกนั้นมีลักษณะการแข่งขันคล้ายกับมวยปกติ
                                                        แต่มีข้อแตกต่างอยู่ที่การแข่งขันจะเป็นการแสดงผลทันทีหลังจบรอบ
                                                        โดยไม่ต้องรอจนกว่ารอบถัดไปจะเริ่มขึ้น
                                                        นอกจากนี้ยังมีการเสนออัตราการจ่ายเงินรางวัลและอัตราการแทงที่หลากหลายมากขึ้น
                                                        ทำให้ผู้เล่นสามารถเลือกแทงตามความต้องการและความสามารถทางการเงินของตนเองได้อย่างอิสระ
                                                    </p>
                                                    <p>
                                                        การแทงมวยออนไลน์รูปแบบใหม่นี้เป็นอีกทางเลือกหนึ่งที่ทำให้ผู้คนสามารถเพลิดเพลินกับการแข่งขันมวยได้อย่างสมจริง
                                                        โดยไม่จำเป็นต้องออกไปสนามมวยหรือไปตามการแข่งขันที่ต่างประเทศ
                                                        นอกจากนี้ยังมีความปลอดภัยที่มีความสำคัญมาก
                                                        เนื่องจากผู้เล่นสามารถทำการแทงผ่านทางเว็บไซต์ที่เป็นทางการ
                                                        ซึ่งมั่นใจได้ว่าจะได้รับการปกป้องข้อมูลส่วนบุคคลและการทำธุรกรรมที่ปลอดภัย
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-59dc8a4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="59dc8a4"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7dde0a5"
                                        data-id="7dde0a5"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-1691626 elementor-widget elementor-widget-text-editor"
                                                data-id={1691626}
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>R1UFABET แทงมวยออนไลน์ เว็บตรง ufabet</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-7f407a2 elementor-widget elementor-widget-text-editor"
                                                data-id="7f407a2"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        r1ufabet เป็นเว็บไซต์ที่นำเสนอบริการแทงมวยออนไลน์โดยตรง
                                                        โดยมีความจริงจังเป็นแนวทางหลักในการให้บริการ
                                                        พวกเราให้ความสำคัญกับความน่าเชื่อถือและความปลอดภัยของผู้เล่นทุกคนที่เข้ามาใช้บริการกับเรา
                                                        ในเว็บไซต์ของเรา คุณสามารถเลือกแทงมวยได้ตลอด 24 ชั่วโมง
                                                        ไม่ว่าจะเป็นเวลากลางคืนหรือเวลาเย็น
                                                        พวกเรามีระบบที่มีประสิทธิภาพสูงเพื่อให้คุณสามารถทำการแทงได้อย่างรวดเร็วและสะดวกสบาย
                                                        ทั้งนี้เพื่อให้คุณสามารถทดลองสร้างความรู้สึกแบบเข้มข้นโดยไม่ต้องเสียเงินในการลงทุนแรกเข้ากับเรา
                                                        นอกจากนี้ r1ufabet ยังมีระบบรองรับการแทงมวยออนไลน์ที่หลากหลาย
                                                        เช่น แทงผลชนะ-แพ้ แทงตำแหน่งชนะ เลือกแทงลูกที่ชนะ และอื่นๆ
                                                        ที่คุณสามารถเลือกแทงได้ตามความชอบและความสนใจของตัวเอง
                                                        เว็บไซต์ของเรายังมีเทคโนโลยีที่ทันสมัย
                                                        เพื่อให้การเดิมพันของคุณเป็นไปอย่างราบรื่นและไม่มีปัญหา
                                                    </p>
                                                    <p>
                                                        เรามีระบบการฝาก-ถอนที่ง่ายและรวดเร็ว
                                                        พร้อมทั้งระบบรองรับการใช้งานผ่านมือถือที่สะดวกสบาย
                                                        ทำให้คุณสามารถเข้าถึงเกมส์และการแทงมวยได้ทุกที่ทุกเวลา รวมถึง
                                                        พวกเรายังมีทีมงานที่มีประสบการณ์และคุณภาพ
                                                    </p>
                                                    <p>
                                                        เพื่อให้คุณได้รับการบริการที่ดีที่สุด
                                                        เรามีทีมสนับสนุนลูกค้าที่พร้อมให้ความช่วยเหลือและแก้ไขปัญหาให้กับคุณตลอด
                                                        24 ชั่วโมง ดังนั้น
                                                        หากคุณกำลังมองหาเว็บแทงมวยออนไลน์ที่มีความน่าเชื่อถือ
                                                        ความปลอดภัย และบริการที่มีคุณภาพ อย่าลืมที่จะเข้ามาสนุกกับเราที่
                                                        r1ufabet แทงมวยออนไลน์ เว็บตรง ufabet
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-1b62f87 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="1b62f87"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-074a9d1"
                                        data-id="074a9d1"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-b2c3655 elementor-widget elementor-widget-text-editor"
                                                data-id="b2c3655"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>กติกาการแทงมวย</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-2a41af3 elementor-widget elementor-widget-text-editor"
                                                data-id="2a41af3"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        1. หากนักมวยถูกกรรมการไล่ลงหรือชกไม่สมศักดิ์ศรี
                                                        เว็บไซต์จะยกเลิกการแข่งขันนั้น
                                                        <br />
                                                        2. กติกาเดิมพันมวยมุมแดงหรือมวยมุมน้ำเงิน
                                                        กำหนดให้เดิมพันต้องทายผลให้ถูกต้องว่ามวยมุมใดชนะมากกว่ากันในวันนั้นหรือศึกนั้น
                                                        <br />– หากมวยเสมอ มวยไล่ลง หรือมวยยกเลิกการตัดสิน
                                                        ถือว่าคู่นั้นไม่คิดผล
                                                        <br />
                                                        -หากมวยสลับมุม หรือมวยถอนชก ถือว่าคู่นั้นได้เสียตามปกติ
                                                        <br />
                                                        3. กติกามวยเสมออย่างน้อย 1 คู่ จะต้องเป็นผลเสมอเท่านั้น
                                                        ไม่ถือเป็นผลเสมอในกรณีที่มีมวยสลับลำดับการแข่งขัน
                                                        ให้ใช้ลำดับคู่ที่ 1-4 เป็นหลักในการตัดสินการแข่งขัน
                                                        <br />
                                                        4. กติกาการเล่นแบบครบยกหรือไม่ครบยก
                                                        <br />– หากมวยถูกไล่ลงครบยกหรือไม่ครบยก ถือว่ามวยคู่นั้นครบยก
                                                        มีผลได้เสียตามปกติ
                                                        <br />– ในกรณีที่มีการไล่ลง การเล่นแบบครบยกหรือไม่ครบยก
                                                        ถือว่ามีผลเป็นครบยก
                                                        <br />– ไม่ว่ามวยจะชก 5 ยกหรือ 3 ยก ถ้ามีผลการแข่งขัน
                                                        ถือว่ามีผลได้เสียตามปกติ
                                                        <br />– หากมวยคู่นั้นมีนักมวยคนใดถอนชก ให้ถือว่าไม่ได้เสีย
                                                        <br />– กรณีที่มีการชนะหรือแพ้ฟาล์วครบยกหรือไม่ครบยก
                                                        ถือเป็นไม่ครบยก
                                                        <br />
                                                        5. กติกาการเล่นมวยสด live สด
                                                        <br />– หากมีการนับหรือน๊อคภายใน 10 วินาทีหลังเดิมพันแทงเว็บ
                                                        ทางเว็บไซต์ขอสงวนสิทธิ์ในการยกเลิกตั๋วทั้งต่อและรองที่เล่นเข้ามา
                                                        <br />– กรณีเล่นไลฟ์สดกลางมีการเปิดราคาผิดจากท้องตลาดเกิน 20%
                                                        ทางเว็บไซต์ขอสงวนสิทธิ์ในการยกเลิกตั๋ว
                                                        การตัดสินของเว็บไซต์ถือเป็นที่สิ้นสุด
                                                        <br />
                                                        6. กรณีเล่นมวย น็อคไม่น็อค 8 คู่หลัก 4 คู่แรกและ 4
                                                        คู่หลังของรายการ ยึดตามกระดานชั่งน้ำหนัก ถ้ามีมวยแข่งขันไม่ถึง 8
                                                        คู่ในรายการที่กำหนด เช่น แข่งขัน 7 คู่ หรือ 6 คู่ให้ถือว่ายกเลิก
                                                        <br />– กรณีเล่นมวย น็อคไม่น็อค 8 คู่หลัก
                                                        ถ้ามีนักมวยเปลี่ยนกระทันหัน สลับมุม ให้เสียตามปกติ
                                                        <br />– กรณีเล่นมวย น็อคไม่น็อค 8 คู่หลัก ถ้ามีมวยถูกไล่ลง
                                                        มวยเสมอ ยกเลิกการแข่งขันไม่ว่ายกไหน ให้ถือว่าไม่น๊อคครบยก
                                                        <br />– กรณีเล่นมวย น็อคไม่น็อค 8 คู่หลัก
                                                        แพ้ฟาว์ลทุกยกถือว่าน็อคไม่ครบยก
                                                        <br />
                                                        7. หากชื่อนักมวยที่เปิดให้เล่นไม่ตรงกับที่ขึ้นชกจริง เช่น
                                                        มีการเปลี่ยนตัวนักมวย, ไม่ต่อยกันแล้ว
                                                        เนื่องจากฝ่ายหนึ่งฝ่ายใดโดนวางยาหรือป่วยกระทันหัน เป็นต้น
                                                        ทางเว็บไซต์จะทำการยกเลิกมวยคู่นั้นและสเต็ปยังไม่ตาย
                                                        <br />
                                                        8. ในกรณีที่นักมวยที่ขึ้นชกมีการเปลี่ยนชื่อใหม่แต่เป็นคนเดิม
                                                        ไม่ว่าทางเว็บไซต์จะเปิดให้เล่นเป็นชื่อเก่าหรือชื่อใหม่
                                                        ให้ถือว่ามีการได้เสียตามปกติ
                                                        <br />
                                                        9. หากชื่อนักมวยที่เปิดให้เล่นมีความผิดปกติในเรื่องของวรรณยุกต์
                                                        เช่น เสอืหมอบ, สะเกด็ดาว, พัยัค์คำราม เป็นต้น
                                                        หรือสะกดชื่อผิดแต่ยังพ้องเสียงกัน เช่น จ้าวฤทธิ์-จ้าวริด,
                                                        เพชรธารา-เพชรทารา, จ้าวแจ็ค-จ้าวแจ๊ค เป็นต้น
                                                        ให้ถือว่ามีการได้-เสียตามปกติ
                                                        <br />
                                                        10. กรณีมวยที่ขึ้นชกมีการ “สลับมุม”
                                                        (มุมที่เปิดให้เล่นไม่ตรงกับที่ขึ้นชกจริง)
                                                        ให้ยึดชื่อนักมวยเป็นหลัก 23. หากเล่นสเต็ป (Parlay)
                                                        แล้วในสเต็ปนั้นมีคู่ที่มีผลเสมอ สเต็ปนั้นจะยังไม่ตาย 11.
                                                        หากราคาที่เปิดให้เล่นผิดจากราคาท้องตลาดเกิน 20%
                                                        ทางเว็บไซต์ขอสงวนสิทธิ์ในการยกเลิกตั๋วที่เล่นราคาผิดปกตินั้น
                                                        <br />
                                                        12. การเล่น “ยึดตามตั๋วที่เข้าระบบของเว็บไซต์” เป็นหลัก 26.
                                                        หากตั๋วไม่มีการคิดผลเกิน 30 นาทีหลังมวยจบ ให้รีบแจ้งทีมงานทันที
                                                        <br />
                                                        13. ผลการแข่งขัน “ยึดตามผลที่สนามมวยประกาศอย่างเป็นทางการ”
                                                        เท่านั้น
                                                        <br />
                                                        14. การเปลี่ยนแปลงต่างๆ เช่น การเปิด-ปิดราคา มีราคาระหว่างพักยก
                                                        (Live)-ไม่มีราคาระหว่างพักยก เป็นต้น
                                                        ทางเว็บไซต์สามารถดำเนินการเปลี่ยนแปลงได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                        <br />
                                                        15. จ่ายสูงสุดต่อตั๋วไม่เกิน 100,000 บาทเท่านั้น!!
                                                        <br />
                                                        16. กรุณาตรวจสอบตั๋วหลังการเล่นทุกครั้ง
                                                        หากพบสิ่งผิดปกติให้รีบแจ้งเว็บไซต์ทันที
                                                        <br />
                                                        17. หากตั๋วที่เล่นมีการคำนวนผิดพลาด ให้รีบแจ้งเว็บไซต์ภายใน 12
                                                        ชั่วโมงหลังเกิดเหตุเท่านั้น
                                                        มิเช่นนั้นทางเว็บไซต์จะไม่ดำเนินการใดๆทั้งสิ้น
                                                        <br />
                                                        18. หากต้องการร้องเรียนเรื่องใดๆก็ตาม ให้ร้องเรียนภายใน 12
                                                        ชั่วโมงหลังเกิดเหตุเท่านั้น
                                                        มิเช่นนั้นทางเว็บไซต์จะไม่ดำเนินการใดๆทั้งสิ้น
                                                        <br />
                                                        19.
                                                        ในกรณีที่ชื่อนักมวยที่เปิดให้เล่นมีชื่อค่ายไม่ตรงกับที่ขึ้นชกจริง
                                                        ให้เอาชื่อนักมวยเป็นหลัก ถ้าหากชื่อนักมวยตรงกัน
                                                        ถือว่ามีการได้-เสียตามปกติ
                                                        <br />
                                                        20. การตัดสินของเว็บไซต์ถือเป็นที่สิ้นสุด
                                                        <br />
                                                        21. ท่านสามารถเล่นสเต็ปได้ 30,000 บาท/1 user ถ้ามีการเล่นเกินจาก
                                                        user อื่นๆ ที่คาดการณ์ว่ามาจากบุคคลคนเดียวกัน
                                                        ทางเว็บขอสงวนสิทธิ์ในการยกเลิกตั๋วนั้นๆ
                                                        <br />
                                                        22. หากมวยคู่ใดมีการเปลี่ยนแปลงจำนวนยกที่ชกกัน (เช่นจาก 5 ยก
                                                        เหลือ 3 ยก) มวยคู่นั้นถือว่ามีการได้-เสียตามปกติ
                                                        <br />
                                                        23. “ศึกมวยไทยซุปเปอร์แชมป์ และ ศึกแฟร์เท็กซ์ไฟต์” สรุปผลใน 3
                                                        ยกเท่านั้นเสมอ ต่อเวลาไม่นับผล
                                                        <br />
                                                        24. หากมวยคู่ใดไม่ทำการแข่งขันภายใน 24 ชั่วโมงของวันที่ลงรายการ
                                                        ทางเว็บไซต์จะทำการยกเลิกคู่นั้น
                                                        <br />
                                                        25.
                                                        หากทางเว็บตรวจสอบพบว่ามีตั๋วหรือรายการเล่นที่ลูกค้าเดิมพันเข้ามาผิดปกติ
                                                        ทางเว็บขอสงวนสิทธิ์ในการยกเลิกตั๋วหรือรายการเล่นนั้นๆ
                                                        โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
                                                        <br />
                                                        26.
                                                        การตัดสินยึดถือกรรมการบนเวทีชูมือเป็นหลักเมื่อกรรมการลงจากเวทีถือเป็นที่สิ้นสุดถ้ามีการเปลี่ยนแปลงการตัดสินในภายหลัง
                                                        ถือว่าไม่เป็นผลใดๆทั้งสิ้น
                                                        <br />
                                                        27. กรณีครบ 3 ยกหรือไม่ครบ 3 ยก ตัดสินหลังจากระฆังหมดยกนั้นๆ
                                                        เช่น ระฆังหยดยกที่ 3 แล้วระหว่างให้น้ำถือเป็นยกที่ 4<br />
                                                        28. หากมีการแสวงหากำไรโดยมาในรูปแบบกลุ่มหรือองค์กร
                                                        ทางเว็บไซต์ขอสงวนสิทธิ์ในการยกเลิกตั๋วเหล่านั้น
                                                        <br />
                                                        29. กรณีมวยมีแข่งขันแล้วแต่ยังมีราคาเปิดอยู่
                                                        ทางเว็บขอยกเลิกตั๋วที่แข่งขันไปแล้วทั้งต่อและทั้งรอง
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-c542d83 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="c542d83"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-bc04e03"
                                        data-id="bc04e03"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap"></div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 24 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={2953}
                            className="elementor elementor-2953"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3f3d6ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3f3d6ea"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49abbbb"
                                        data-id="49abbbb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5f0d025 elementor-widget elementor-widget-heading"
                                                data-id="5f0d025"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h4 className="elementor-heading-title elementor-size-default">
                                                        บาคาร่าออนไลน์
                                                    </h4>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-769953a elementor-widget elementor-widget-image"
                                                data-id="769953a"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={1200}
                                                        height={460}
                                                        src="https://cdn.javisx.com/image/e011ecd1-5450-46d9-ab04-ad37c0619fa0.png"
                                                        className="attachment-full size-full wp-image-6644"
                                                        alt=""
                                                        //srcSet="https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์_0.png 1200w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์_0-300x115.png 300w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์_0-1024x393.png 1024w, https://appufa.com/wp-content/uploads/2024/07/คาสิโนออนไลน์_0-768x294.png 768w"
                                                        sizes="(max-width: 1200px) 100vw, 1200px"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-36fa81e elementor-widget elementor-widget-text-editor"
                                                data-id="36fa81e"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ขอนำเสนอ บาคาร่าออนไลน์ หนึ่งในเกมคาสิโนยอดนิยม
                                                        ซึ่งเป็นรูปแบบการเล่นไพ่ที่มีกฎกติกาใกล้เคียงกับไพ่ป๊อกเด้ง
                                                        แต่มีความเข้าใจง่ายกว่า
                                                        ปัจจุบันบาคาร่าออนไลน์ได้รับความนิยมอย่างแพร่หลาย
                                                        สามารถเล่นได้ทั้งบนมือถือและคอมพิวเตอร์ ไม่ว่าคุณจะอยู่ที่ใด
                                                        คุณก็สามารถเพลิดเพลินกับเกมนี้ได้ตลอดเวลา
                                                    </p>
                                                    <p>
                                                        ด้วยความนิยมที่เพิ่มขึ้นอย่างต่อเนื่อง เราขอแนะนำ
                                                        <a href="https://enjoy289.win/">enjoy289.COM</a>
                                                        เว็บไซต์พนันออนไลน์ที่มีความน่าเชื่อถือและได้รับความนิยมสูงสุด
                                                        <strong>enjoy289</strong>
                                                        มอบประสบการณ์การเล่นบาคาร่าออนไลน์ที่ครบครัน
                                                        ทั้งการเลือกเล่นหลากหลายค่าย
                                                        และสามารถทำธุรกรรมด้วยตนเองผ่านระบบอัตโนมัติ 24 ชั่วโมง
                                                        พร้อมทีมงานมืออาชีพคอยให้การสนับสนุนตลอดเวลา
                                                        เปิดประสบการณ์ใหม่กับบาคาร่าออนไลน์ที่ enjoy289.win
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a
                                                                href={this.state.webnamex + "/register"}
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-5aa6392 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="5aa6392"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a1a2ed1"
                                        data-id="a1a2ed1"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-ca3b1f7 elementor-widget elementor-widget-text-editor"
                                                data-id="ca3b1f7"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>บาคาร่าออนไลน์ คืออะไร?</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-13f6b4c elementor-widget elementor-widget-text-editor"
                                                data-id="13f6b4c"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ก่อนอื่นเราต้องทำความรู้จักกับ “บาคาร่า” กันก่อน
                                                        บาคาร่าเป็นหนึ่งในเกมไพ่ที่มีลักษณะคล้ายกับ “ไพ่ป๊อกเด้ง”
                                                        โดยผู้เล่นและเจ้ามือจะวางเดิมพันกัน
                                                        และมีคนทำหน้าที่แจกไพ่ให้ทั้งสองฝ่าย
                                                        ในการเล่นจะมีการตัดสินผลด้วยคะแนนสูงสุดเพียง 3 ใบไพ่เท่านั้น
                                                    </p>
                                                    <p>
                                                        เมื่อเข้าสู่โลกของบาคาร่าออนไลน์
                                                        คุณจะพบกับตารางแสดงผลการเล่นในรอบก่อนหน้า
                                                        ซึ่งเป็นข้อมูลที่มีประโยชน์ในการตัดสินใจเดิมพันในครั้งต่อไป
                                                        นอกจากนี้ยังมีกราฟิกที่สวยงามและระบบถ่ายทอดสดที่คมชัดระดับ 4K
                                                        ให้คุณเพลิดเพลินไปกับประสบการณ์การเล่นที่สมจริง
                                                    </p>
                                                    <p>
                                                        ปัจจุบัน การเล่นบาคาร่าออนไลน์เป็นเรื่องใกล้ตัวมากขึ้น
                                                        คุณสามารถเข้าถึงได้ง่ายเพียงผ่านอินเทอร์เน็ต
                                                        ไม่ต้องเดินทางไปยังต่างประเทศ
                                                        เพียงเชื่อมต่อผ่านคอมพิวเตอร์หรือสมาร์ทโฟนก็สามารถเล่นได้ทันที
                                                    </p>
                                                    <p>
                                                        สำหรับมือใหม่ที่ยังไม่มั่นใจ
                                                        อาจมีความกังวลว่าจะโดนหลอกหรือไม่ได้เงิน
                                                        แต่หากคุณศึกษากฎกติกาและวิธีการเล่นอย่างถี่ถ้วน
                                                        และเลือกใช้บริการจากเว็บไซต์ที่น่าเชื่อถือ เช่น enjoy289.win แล้ว
                                                        คุณก็สามารถสร้างรายได้จากการเล่นบาคาร่าออนไลน์ได้เช่นกัน
                                                        เพียงเล่นอย่างพอดี ไม่โลภมากและมีสติ ก็จะสามารถสร้างผลกำไรได้
                                                    </p>
                                                    <p>
                                                        สำหรับผู้ที่เคยเล่นบาคาร่าออนไลน์มาแล้ว หากพบว่าตัวเองขาดทุน
                                                        ก็อาจศึกษาวิธีการเล่นใหม่ๆ เพื่อหาแนวทางสร้างรายได้จากการเล่น
                                                        หรือหาเทคนิคที่จะช่วยลดการสูญเสียลง
                                                        โดยสามารถค้นหาข้อมูลเหล่านี้ได้จากเว็บไซต์หรือ YouTube
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-4c62d33 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="4c62d33"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-38677d0"
                                        data-id="38677d0"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-05e712e elementor-widget elementor-widget-text-editor"
                                                data-id="05e712e"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>วิธีการเล่นบาคาร่าออนไลน์</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-c2a8a5f elementor-widget elementor-widget-text-editor"
                                                data-id="c2a8a5f"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        1. ศึกษากฎกติกาและเลือกเว็บไซต์ที่น่าเชื่อถือ
                                                        <br />
                                                        ในปัจจุบันมีเว็บไซต์บาคาร่าออนไลน์มากมายให้เลือก
                                                        แต่ละเว็บก็มีโปรโมชั่นและโบนัสที่แตกต่างกัน
                                                        ควรเลือกเว็บที่มีชื่อเสียง มีคนใช้บริการเป็นจำนวนมาก
                                                        และมีฐานการเงินที่มั่นคง เพื่อความปลอดภัยในการเล่น
                                                    </p>
                                                    <p>
                                                        2. สมัครสมาชิกและล็อกอินเข้าเล่น
                                                        <br />
                                                        หากเป็นสมาชิกอยู่แล้ว ก็สามารถล็อกอินเข้าเล่นได้ทันที
                                                        แต่หากยังไม่มีบัญชี ก็ต้องทำการสมัครสมาชิกก่อน
                                                        จากนั้นจึงล็อกอินเพื่อเข้าสู่ห้องเกมบาคาร่า
                                                    </p>
                                                    <p>
                                                        เมื่อล็อกอินเข้าสู่ระบบแล้ว
                                                        ผู้เล่นสามารถเลือกห้องเดิมพันได้ตามความต้องการและความสามารถของตน
                                                        โดยแนะนำให้มือใหม่เริ่มจากห้องเบสิกก่อน
                                                        เพื่อให้มีโอกาสชนะมากขึ้น หลังจากนั้น
                                                        ผู้เล่นก็สามารถเดิมพันได้ทันที หรือหากยังไม่มั่นใจ
                                                        ก็สามารถดูรูปแบบการเล่นก่อนหน้านี้ได้ก่อน
                                                    </p>
                                                    <p>
                                                        ในการเดิมพันบาคาร่าออนไลน์ มีสองรูปแบบให้เลือก คือ
                                                        การเล่นแบบออนไลน์ปกติ
                                                        และการเดิมพันแบบเรียวไทม์จากบ่อนคาสิโนโดยตรง
                                                        ผู้เล่นจะต้องเสียค่าคอมมิชชั่น 5% ในทุกครั้งที่ชนะการเดิมพัน
                                                    </p>
                                                    <p>
                                                        นอกจากนี้
                                                        ผู้เล่นควรทำความเข้าใจตัวเลขและสถิติในการเล่นบาคาร่าด้วย
                                                        เพราะจะช่วยในการตัดสินใจวางเดิมพัน
                                                        โดยพบว่าโอกาสชนะฝั่งเจ้ามือจะมากกว่าฝั่งผู้เล่น
                                                        แต่ก็ต้องระวังเรื่องอัตราการจ่ายผลตอบแทนที่ไม่เท่ากัน
                                                    </p>
                                                    <p>
                                                        ทั้งนี้ การเดิมพันแบบเสมอและไพ่คู่ ถึงแม้จะมีอัตราการจ่ายสูงสุด
                                                        แต่โอกาสชนะจริงๆ กลับค่อนข้างต่ำ เพียงประมาณ 9.52% และ 7.46%
                                                        ตามลำดับ ดังนั้น จึงไม่ควรเสี่ยงวางเดิมพันในลักษณะดังกล่าวมากนัก
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-d635b68 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="d635b68"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cbcc15e"
                                        data-id="cbcc15e"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-8293f93 elementor-widget elementor-widget-text-editor"
                                                data-id="8293f93"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>เทคนิคการเล่น บาคาร่าออนไลน์ แบบมืออาชีพ</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-7b7bb66 elementor-widget elementor-widget-text-editor"
                                                data-id="7b7bb66"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        บาคาร่าออนไลน์ กำลังเป็นที่นิยมอย่างมากในประเทศไทยในปัจจุบัน
                                                        เพราะมีความสะดวกสบาย ระบบใช้งานง่าย และเข้าถึงได้ง่าย
                                                        เพียงใช้คอมพิวเตอร์หรือโทรศัพท์ที่มีอินเทอร์เน็ตก็สามารถเล่นได้จากที่บ้านเลย
                                                        เหมือนอยู่ในบ่อนคาสิโนใกล้บ้านแล้ว
                                                        วันนี้เราจะพาคุณไปดูเทคนิคการเล่นบาคาร่าแบบมืออาชีพ
                                                    </p>
                                                    <p>
                                                        กลยุทธ์ Golden Eagle
                                                        เป็นวิธีการเล่นที่ช่วยเพิ่มโอกาสในการชนะให้สูงขึ้นถึงสองเท่า
                                                        โดยมีการกำหนดทุนเริ่มต้นต่ำ และสามารถเล่นได้ทั้งเกมรุกและเกมรับ
                                                        ทำให้ได้เงินเพิ่มขึ้นอย่างแน่นอน
                                                        เหมาะสำหรับทั้งมือใหม่และมืออาชีพ
                                                    </p>
                                                    <p>
                                                        กลยุทธ์ Silver Tiger เป็นอีกหนึ่งวิธีที่ได้รับความนิยมสูง
                                                        โดยมีลักษณะคล้ายกับ Golden Eagle
                                                        แต่มุ่งเน้นการเล่นแบบดุดันมากขึ้น
                                                        เพื่อให้ได้เงินรางวัลที่มากขึ้นอย่างรวดเร็ว
                                                    </p>
                                                    <p>
                                                        สำหรับกลยุทธ์ Sure Win เป็นการวางแผนเล่นที่ดี มีเป้าหมายชัดเจน
                                                        และเป็นตัวช่วยสำคัญสำหรับผู้ที่ชอบความท้าทาย
                                                        และต้องการหารายได้จากการเดิมพัน อย่างไรก็ตาม
                                                        ยังไม่มีสูตรที่ใช้ได้ผลกับทุกคน เพราะต้องอาศัยสถานการณ์ที่เป็นใจ
                                                        และการวิเคราะห์เกมอย่างดี
                                                    </p>
                                                    <p>มาร่วมค้นหา “เทคนิคการเล่นบาคาร่าแบบมืออาชีพ” กันเลย!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 25 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={2952}
                            className="elementor elementor-2952"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-3f3d6ea elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="3f3d6ea"
                                data-element_type="section"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div className="elementor-container elementor-column-gap-no">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-49abbbb"
                                        data-id="49abbbb"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-5f0d025 elementor-widget elementor-widget-heading"
                                                data-id="5f0d025"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h1 className="elementor-heading-title elementor-size-default">
                                                        หวยออนไลน์
                                                    </h1>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-321e7e2 elementor-widget elementor-widget-image"
                                                data-id="321e7e2"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={1200}
                                                        height={460}
                                                        src="https://cdn.javisx.com/image/04a19ff0-6d0f-4fac-b23f-abbf46d2f970.png"
                                                        className="attachment-full size-full wp-image-6692"
                                                        alt=""
                                                       // srcSet="https://appufa.com/wp-content/uploads/2024/07/หวยออนไลน์.png 1200w, https://appufa.com/wp-content/uploads/2024/07/หวยออนไลน์-300x115.png 300w, https://appufa.com/wp-content/uploads/2024/07/หวยออนไลน์-1024x393.png 1024w, https://appufa.com/wp-content/uploads/2024/07/หวยออนไลน์-768x294.png 768w"
                                                        sizes="(max-width: 1200px) 100vw, 1200px"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-61f30fa elementor-widget elementor-widget-text-editor"
                                                data-id="61f30fa"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        สำหรับนักเสี่ยงโชคทุกท่าน
                                                        การแทงหวยออนไลน์ถือเป็นช่องทางใหม่ที่ตอบโจทย์ความสะดวกสบายและรวดเร็วในการร่วมเสี่ยงโชคได้อย่างครบครัน
                                                        เพียงคุณมีอินเตอร์เน็ต
                                                        คุณก็สามารถเข้าร่วมลุ้นโชคจากที่ใดก็ได้ตลอด 24 ชั่วโมง
                                                        ไม่ว่าคุณจะอยู่ที่ไหนหรือเวลาใด
                                                        ความต้องการของนักเสี่ยงโชคจะไม่มีวันสิ้นสุดอีกต่อไป
                                                    </p>
                                                    <p>
                                                        ด้วยเหตุนี้ เว็บไซต์
                                                        <a href="https://enjoy289.win/">
                                                            <strong>enjoy289.win</strong>
                                                        </a>
                                                        จึงเปิดให้บริการหวยออนไลน์อย่างครบวงจร
                                                        มอบความมั่นใจด้วยระบบที่ปลอดภัย น่าเชื่อถือ
                                                        จ่ายเงินรางวัลอย่างรวดเร็ว ไม่มีการหลอกลวงแน่นอน
                                                        นับเป็นจุดหมายปลายทางสำหรับนักเสี่ยงโชคทั้งมือใหม่และมือเก่าที่ต้องการลุ้นรางวัลเข้ากระเป๋าตลอดเวลา
                                                        สิ่งสำคัญที่สุดคือ
                                                        ระบบของเราได้รับการออกแบบให้คุณสามารถทำธุรกรรมทางการเงินด้วยตนเองผ่านระบบอัตโนมัติ
                                                        ไม่ว่าจะเป็นการฝากเงิน ถอนเงิน หรือโอนเงินเข้า-ออกบัญชีส่วนตัว
                                                        ท่านสามารถดำเนินการได้ด้วยตนเองตลอด 24 ชม. นอกจากนี้
                                                        ยังมีทีมงานมืออาชีพคอยให้บริการและสนับสนุนตลอดเวลา
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a
                                                                href={this.state.webnamex + "/register"}
                                                                target="_blank"
                                                                rel="noopener"
                                                            >
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-dd4ed68 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="dd4ed68"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-960a2bc"
                                        data-id="960a2bc"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-0edf850 elementor-widget elementor-widget-text-editor"
                                                data-id="0edf850"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>หวย&nbsp; คืออะไร</h2>
                                                    <p>
                                                        ประวัติศาสตร์ของธุรกิจการพนันในประเทศไทยมีรากฐานมาจากยุคสมัยที่เศรษฐกิจตกต่ำ
                                                        เมื่อราวพุทธศักราช 2375
                                                        ท่ามกลางภาวะเงินเฟ้อและราคาสินค้าที่พุ่งสูงขึ้น
                                                        ประชาชนจำนวนมากหันมาเก็บรักษาเงินสดไว้แทนการใช้จ่าย
                                                        โดยมีการฝังเงินไว้ใต้ดิน เพื่อรักษามูลค่าและป้องกันการสูญเสีย
                                                    </p>
                                                    <p>
                                                        เพื่อแก้ปัญหาการขาดสภาพคล่องทางการเงินในสังคม
                                                        รัฐบาลจึงได้ริเริ่มธุรกิจการพนันขึ้น โดยในระยะแรกนั้น
                                                        กลุ่มผู้เล่นหลักประกอบด้วยชาวจีนที่อาศัยอยู่ในประเทศไทย
                                                        พวกเขาเรียกกิจกรรมนี้ว่า “ฮวยหวย” ซึ่งแปลได้ว่า “ชุมนุมดอกไม้”
                                                        เนื่องจากในยุคนั้นมีการเขียนสัญลักษณ์บนใบหวยในรูปแบบของดอกไม้ต่างๆ
                                                    </p>
                                                    <p>
                                                        ต่อมา วิธีการเล่นหวยได้มีการเปลี่ยนแปลงไปใช้ระบบป้ายชื่อ
                                                        โดยจะมีป้ายทั้งหมด 34 ป้าย
                                                        แต่ละป้ายจะระบุชื่อของบุคคลสำคัญหรือมีชื่อเสียง
                                                        ผู้เล่นจะทายว่าหวยงวดนั้นจะออกตรงกับชื่อบนป้ายใด
                                                    </p>
                                                    <p>
                                                        ในเวลาต่อมา การพนันหวยก็ได้รับความนิยมเพิ่มขึ้นอย่างมาก
                                                        เมื่อมีการนำอักษรไทยมาใช้ในการออกหวย ซึ่งเรียกว่า “หวยก ข”
                                                        โดยจะใช้ตัวอักษรไทยทั้งหมด 36 ตัว
                                                        ทำให้ธุรกิจนี้กลายเป็นรายได้สำคัญของรัฐบาล
                                                    </p>
                                                    <p>
                                                        จนกระทั่งในพุทธศักราช 2482
                                                        รัฐบาลได้เปลี่ยนรูปแบบมาเป็นการออกสลากกินแบ่งรัฐบาล
                                                        หรือที่รู้จักกันดีในนาม “ลอตเตอรี่”
                                                        เพื่อนำรายได้ส่วนหนึ่งไปสมทบทุนการกุศลต่างๆ
                                                        และได้ดำเนินการออกลอตเตอรี่อย่างต่อเนื่องจนถึงปัจจุบัน
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-5a8dadf elementor-widget elementor-widget-text-editor"
                                                data-id="5a8dadf"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>หวยออนไลน์ มีความเป็นมาอย่างไร</h2>
                                                    <p>
                                                        ยุคสมัยที่เทคโนโลยีดิจิทัลเข้ามามีบทบาทสำคัญในการดำรงชีวิตของมนุษย์
                                                        ธุรกิจหวยก็ได้ปรับตัวให้ทันสมัยด้วยการนำเสนอบริการหวยในรูปแบบออนไลน์
                                                        เพื่อตอบสนองความต้องการของผู้รักการเสี่ยงโชคให้สามารถเข้าถึงการซื้อหวยได้อย่างสะดวกสบายมากยิ่งขึ้น
                                                    </p>
                                                    <p>
                                                        การเล่นหวยออนไลน์ หมายถึง
                                                        กระบวนการซื้อขายหวยผ่านช่องทางอินเทอร์เน็ต
                                                        โดยใช้เว็บไซต์ให้บริการแทงหวยออนไลน์เป็นสื่อกลาง
                                                        เพียงแค่ผู้เล่นมีอุปกรณ์คอมพิวเตอร์ แท็บเล็ต
                                                        หรือสมาร์ทโฟนเท่านั้น
                                                        ก็สามารถเสี่ยงโชคกับการแทงหวยได้ทุกหนทุกแห่ง ตลอดเวลา
                                                        โดยไม่มีข้อจำกัดในการแทงหวย
                                                    </p>
                                                    <p>
                                                        ธุรกิจหวยออนไลน์สามารถแบ่งออกได้เป็น 2 ประเภทหลัก ได้แก่
                                                        ประเภทแรก คือ
                                                        การเป็นเจ้าของเว็บไซต์หรือผู้ประกอบการขายหวยออนไลน์โดยตรง
                                                        และประเภทที่สอง คือ
                                                        การเป็นตัวแทนจำหน่ายหวยออนไลน์ให้กับผู้ประกอบการรายอื่น
                                                    </p>
                                                    <p>
                                                        ในปัจจุบันมีเว็บไซต์ให้บริการหวยออนไลน์จำนวนมากที่เสนอบริการในรูปแบบที่แตกต่างกันออกไป
                                                        ดังนั้นผู้เล่นจึงควรระมัดระวังและเลือกใช้บริการจากเว็บไซต์ที่มีความน่าเชื่อถือ
                                                        เช่น enjoy289.win เป็นต้น
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-158f2fb elementor-widget elementor-widget-text-editor"
                                                data-id="158f2fb"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>ข้อดีของหวยออนไลน์</h2>
                                                    <p>
                                                        นักเสี่ยงโชคทุกท่าน
                                                        หากกำลังมองหาช่องทางการแทงหวยออนไลน์ที่ปลอดภัย สะดวกสบาย
                                                        และคุ้มค่าคุ้มราคา เว็บไซต์ UFATHAI.COM
                                                        คือคำตอบที่ท่านกำลังตามหา&nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            ด้วยระบบการซื้อหวยออนไลน์แบบตรง
                                                            ท่านสามารถเลือกซื้อเลขโปรดได้อย่างง่ายดาย
                                                            โดยไม่ต้องผ่านนายหน้าหรือพ่อค้าคนกลาง
                                                            ราคาเลขหวยถูกกำหนดอย่างชัดเจน ไม่มีการเปลี่ยนแปลง
                                                            นักเสี่ยงโชคจึงมั่นใจได้ว่าจะไม่โดนเอารัดเอาเปรียบ
                                                        </span>
                                                    </p>
                                                    <p>
                                                        หมดปัญหาเลขอั้น เพราะที่นี่ท่านสามารถซื้อได้ทุกตัวเลขที่ชื่นชอบ
                                                        ไม่ว่าจะเป็นเลขเด็ด เลขดัง หรือเลขที่มีคนเล่นกันมากก็ตาม
                                                        รูปแบบการแทงหวยผ่านเว็บไซต์ของเราเข้าใจง่าย
                                                        ไม่แตกต่างจากการแทงหวยกับเจ้ามือหวยตามท้องตลาด
                                                    </p>
                                                    <p>
                                                        ระบบการซื้อหวยออนไลน์บนเว็บไซต์ enjoy289.win
                                                        ได้รับการออกแบบให้ใช้งานสะดวกสบาย
                                                        ไม่ต้องนั่งเขียนโพยหรือคำนวณยอดแทงด้วยตนเอง
                                                        เพราะระบบจะคำนวณทุกอย่างให้อัตโนมัติ
                                                        นักเสี่ยงโชคจะได้รับผลตอบแทนในอัตราที่สูงกว่าการแทงกับเจ้ามือหวยทั่วไป
                                                    </p>
                                                    <p>
                                                        หากท่านถูกรางวัล ก็จะได้รับเงินรางวัลอย่างรวดเร็ว ไม่ต้องรอนาน
                                                        สามารถนำเงินไปใช้จ่ายได้ทันที นอกจากนี้ เว็บไซต์ UFATHAI.COM
                                                        ยังมีบริการเกมการพนันออนไลน์อื่นๆ ที่หลากหลายรูปแบบ
                                                        พร้อมตอบสนองความบันเทิงของนักเสี่ยงโชคได้อย่างครบครัน
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-9a7cffe elementor-widget elementor-widget-text-editor"
                                                data-id="9a7cffe"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>เทคนิค การเล่นหวยออนไลน์</h2>
                                                    <p>
                                                        ในยุคปัจจุบัน
                                                        การเสี่ยงโชคด้วยการเล่นหวยได้กลายเป็นส่วนหนึ่งของวิถีชีวิตคนไทย
                                                        ไม่ว่าจะเป็นช่วงเวลาใดก็ตาม
                                                        วันนี้เราจะมาแนะนำเคล็ดลับสำหรับการเล่นหวยออนไลน์อย่างชาญฉลาด
                                                        เพื่อให้ผู้เล่นสามารถสร้างรายได้เสริมในช่วงเศรษฐกิจถดถอยของประเทศไทย
                                                    </p>
                                                    <p>
                                                        เราจะนำเสนอองค์ความรู้เล็กๆ น้อยๆ
                                                        ที่ผู้เล่นสามารถนำไปประยุกต์ใช้ให้เข้ากับสไตล์การเล่นและงบประมาณของตนเองได้อย่างลงตัว
                                                        หากพร้อมแล้ว เชิญร่วมเปิดรับความรู้ใหม่ๆ ไปพร้อมกัน
                                                        <br />
                                                        การพนันหวยเป็นเรื่องที่ต้องระมัดระวังเป็นอย่างยิ่ง
                                                        เนื่องจากผู้เล่นจำเป็นต้องตระหนักว่าการชนะรางวัลนั้นขึ้นอยู่กับดวงเป็นสำคัญ
                                                        ดังนั้น ผู้เล่นทุกคนควรวิเคราะห์อย่างรอบคอบ
                                                        และไม่ควรเสี่ยงเงินจำนวนมากจนเกินไป
                                                        เพราะอาจนำไปสู่ปัญหาทางการเงินได้
                                                        และย่อมไม่ก่อให้เกิดผลดีแต่อย่างใด
                                                    </p>
                                                    <p>
                                                        เพื่อการเล่นอย่างมีสติ ผู้เล่นควรกำหนดงบประมาณในการเล่นให้ชัดเจน
                                                        เลือกแทงเฉพาะตัวเลขที่มั่นใจเท่านั้น
                                                        ทั้งนี้เพื่อประหยัดเงินในกระเป๋าของตนเอง
                                                        และยังคงมีโอกาสชนะรางวัลจากเลขที่วางใจอีกด้วย&nbsp;
                                                        <span
                                                            style={{
                                                                color: "var( --e-global-color-text )",
                                                                fontFamily:
                                                                    "var( --e-global-typography-text-font-family ), Sans-serif",
                                                                fontWeight: "var( --e-global-typography-text-font-weight )",
                                                                fontSize: "1rem"
                                                            }}
                                                        >
                                                            ไม่ว่าจะเป็นการเล่นหวยรัฐบาล หวยใต้ดิน หรือหวยออนไลน์
                                                            ล้วนถือเป็นการเสี่ยงโชคทั้งสิ้น ผู้เล่นจึงต้องตระหนักเสมอว่า
                                                            ไม่มีใครสามารถชนะรางวัลได้ทุกงวด
                                                            เนื่องจากความน่าจะเป็นในการชนะนั้นขึ้นอยู่กับดวงเป็นส่วนใหญ่
                                                            ดังนั้น หากตัดสินใจเล่น
                                                            ผู้เล่นควรตั้งความหวังในระดับที่เหมาะสม
                                                            และจงจำไว้ว่าการเล่นเป็นเพียงการวัดดวงเท่านั้น
                                                            และหากดวงดีในวันใด
                                                            รางวัลที่ได้รับก็คงคุ้มค่ากับสิ่งที่คาดหวังอย่างแน่นอน
                                                        </span>
                                                    </p>
                                                    <p>
                                                        ก่อนเข้าร่วมเล่นหวยออนไลน์ในแต่ละงวด
                                                        ผู้เล่นสามารถตรวจสอบสถิติการออกรางวัลย้อนหลังได้
                                                        วิธีนี้จะทำให้ทราบว่าในอดีตมีรูปแบบและตัวเลขใดที่ออกบ่อยที่สุด
                                                        ช่วยให้คาดการณ์ตัวเลขที่อาจออกในรอบต่อไปได้ดียิ่งขึ้น
                                                        ปัจจุบันสามารถย้อนดูสถิติหวยออนไลน์ได้ไกลถึง 20 ปีที่ผ่านมา
                                                    </p>
                                                    <p>
                                                        การกระจายความเสี่ยงนับเป็นกลยุทธ์ที่ช่วยเพิ่มโอกาสในการชนะรางวัลอย่างแน่นอน
                                                        ยิ่งผู้เล่นกระจายความเสี่ยงมากเท่าใด
                                                        โอกาสที่จะถูกรางวัลก็ยิ่งมากขึ้นเท่านั้น นอกจากนี้
                                                        ยังเพิ่มโอกาสในการประสบความสำเร็จสูงสุดอีกด้วย
                                                    </p>
                                                    <p>
                                                        สุดท้าย
                                                        การเลือกซื้อหวยออนไลน์ตามความเชื่อส่วนบุคคลนั้นเป็นอีกทางเลือกหนึ่ง
                                                        แม้จะไม่ได้หมายความว่าจะไม่มีโอกาสถูกรางวัลเลย
                                                        แต่โอกาสดังกล่าวอาจน้อยกว่าวิธีอื่น ไม่ว่าจะเป็นการตีความฝัน
                                                        หรือเลขเด็ดจากคนรอบข้าง
                                                        ผลลัพธ์อาจจะถูกหรือไม่ถูกรางวัลก็เป็นได้ทั้งสิ้น อย่างไรก็ตาม
                                                        วิธีนี้ยังดีกว่าการซื้อหวยแบบสุ่มโดยปราศจากที่มา
                                                        และมีผู้เล่นจำนวนไม่น้อยที่ประสบความสำเร็จจากการเชื่อถือวิธีเหล่านี้
                                                        รวมถึงบางรายที่ได้รับรางวัลใหญ่อย่างคาดไม่ถึง
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 26 ? "" : "none" }}  >
                        <div
                            data-elementor-type="wp-page"
                            data-elementor-id={2508}
                            className="elementor elementor-2508"
                            data-elementor-post-type="page"
                        >
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-59d61ec elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="59d61ec"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9a2708d"
                                        data-id="9a2708d"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-59ad01c elementor-widget elementor-widget-heading"
                                                data-id="59ad01c"
                                                data-element_type="widget"
                                                data-widget_type="heading.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h1 className="elementor-heading-title elementor-size-default">
                                                        โปรโมชั่น UFABET ใหม่ล่าสุด
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-2638fd7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="2638fd7"
                                data-element_type="section"
                            >
                                {/* <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-bfad7f5"
                                        data-id="bfad7f5"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-d3abb8c elementor-widget elementor-widget-image"
                                                data-id="d3abb8c"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        fetchpriority="high"
                                                        decoding="async"
                                                        width={600}
                                                        height={400}
                                                        src="https://appufa.com/wp-content/uploads/2024/07/คืนยอดเส่นทุกวัน_0.jpg"
                                                        className="attachment-full size-full wp-image-6706"
                                                        alt=""
                                                        srcSet="https://appufa.com/wp-content/uploads/2024/07/คืนยอดเส่นทุกวัน_0.jpg 600w, https://appufa.com/wp-content/uploads/2024/07/คืนยอดเส่นทุกวัน_0-300x200.jpg 300w"
                                                        sizes="(max-width: 600px) 100vw, 600px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-04fcfb6"
                                        data-id="04fcfb6"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-2b593fc elementor-widget elementor-widget-image"
                                                data-id="2b593fc"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        decoding="async"
                                                        width={600}
                                                        height={400}
                                                        src="https://appufa.com/wp-content/uploads/2024/07/คืนยอดเสีย-5_0.jpg"
                                                        className="attachment-full size-full wp-image-6707"
                                                        alt=""
                                                        srcSet="https://appufa.com/wp-content/uploads/2024/07/คืนยอดเสีย-5_0.jpg 600w, https://appufa.com/wp-content/uploads/2024/07/คืนยอดเสีย-5_0-300x200.jpg 300w"
                                                        sizes="(max-width: 600px) 100vw, 600px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-717a6c0"
                                        data-id="717a6c0"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-182e0bc elementor-widget elementor-widget-image"
                                                data-id="182e0bc"
                                                data-element_type="widget"
                                                data-widget_type="image.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <img
                                                        decoding="async"
                                                        width={600}
                                                        height={400}
                                                        src="https://appufa.com/wp-content/uploads/2024/07/แนะนำเพื่อนรับรายได้_0.jpg"
                                                        className="attachment-full size-full wp-image-6708"
                                                        alt=""
                                                        srcSet="https://appufa.com/wp-content/uploads/2024/07/แนะนำเพื่อนรับรายได้_0.jpg 600w, https://appufa.com/wp-content/uploads/2024/07/แนะนำเพื่อนรับรายได้_0-300x200.jpg 300w"
                                                        sizes="(max-width: 600px) 100vw, 600px"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-be05947 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="be05947"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-db37603"
                                        data-id="db37603"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-d035c9a elementor-widget elementor-widget-text-editor"
                                                data-id="d035c9a"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        เว็บไซต์ พนันออนไลน์
                                                        ที่ให้บริการเกมคาสิโนและพนันกีฬาออนไลน์อย่างครบวงจร
                                                        โดยมีการออกแบบและพัฒนาเว็บไซต์ มาเป็นอย่างดี ใช้งานง่าย สะดวก
                                                        และตอบสนองต่อความต้องการของผู้ใช้งานได้ทุกคน อย่างเหมาะสม
                                                        <a href="https://enjoy289.win/">
                                                            <strong>enjoy289.win</strong>
                                                        </a>
                                                        โปรโมชั่น <strong>UFABET ใหม่ล่าสุด</strong>
                                                        เน้นความเป็นกันเองและสร้างประสบการณ์การเล่นเกมที่เป็นธรรมชาติและน่าสนใจสำหรับผู้เล่นทุกคน
                                                        โดยมีระบบที่ทันสมัยและการให้บริการที่มีคุณภาพ
                                                        เพื่อให้ผู้เล่นสามารถเพลิดเพลินกับการเล่นเกมและพนันออนไลน์ได้อย่างที่ต้องการ
                                                    </p>
                                                    <p>
                                                        มีส่วนสำคัญในการให้บริการที่ดีและเป็นธรรมชาติ
                                                        โดยให้ผู้เล่นสามารถเข้าถึงเกมคาสิโนที่หลากหลายและพนันกีฬาที่น่าตื่นเต้นได้อย่างง่ายดาย
                                                        อีกทั้งยังมีโปรโมชั่นใหม่ล่าสุดที่จะช่วยเพิ่มโอกาสในการชนะเลิศในการเดิมพัน
                                                        enjoy289.win โปรโมชั่น UFABET ใหม่ล่าสุด
                                                        ยังเน้นความปลอดภัยและความเป็นส่วนตัวของผู้เล่น
                                                        ด้วยการใช้เทคโนโลยีที่ทันสมัยเพื่อรักษาความมั่นคงและป้องกันการแอบแฝงจากบุคคลที่ไม่หวังดี
                                                        ทำให้ผู้เล่นมั่นใจในการทำธุรกรรมและการเล่นเกมในเว็บไซต์นี้
                                                    </p>
                                                    <p>
                                                        สร้างประสบการณ์การเล่นเกมที่น่าตื่นเต้นและพึงพอใจให้กับผู้เล่น
                                                        ด้วย enjoy289.win โปรโมชั่น UFABET ใหม่ล่าสุด
                                                        ที่ให้บริการเกมคาสิโนและพนันกีฬาออนไลน์ที่มีคุณภาพ
                                                        และประสบการณ์การเล่นที่ไม่มีที่ติ
                                                        หากคุณกำลังมองหาเว็บไซต์การพนันออนไลน์ที่มีความเป็นกันเอง
                                                        และให้บริการเกมคาสิโนและพนันกีฬาอย่างครบวงจร
                                                        อย่างง่ายดายและปลอดภัย ไม่ต้องสงสัย enjoy289.win โปรโมชั่น
                                                        <strong>UFABET</strong> ใหม่ล่าสุด
                                                        อาจเป็นตัวเลือกที่เหมาะสมสำหรับคุณ
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            สามารถ สมัคร แทงบอลออนไลน์ คาสิโนออนไลน์ เว็บตรง ได้ที่
                                                            <a href={this.state.webnamex + "/register"}>
                                                                {this.state.webnamex + "/register"}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-a6a0c02 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="a6a0c02"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-304c7cc"
                                        data-id="304c7cc"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-192c939 elementor-widget elementor-widget-text-editor"
                                                data-id="192c939"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>โปรโมชั่น คาสิโน</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-c28dcc6 elementor-widget elementor-widget-text-editor"
                                                data-id="c28dcc6"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        โปรโมชั่นคาสิโน คืนทุกยอดเล่น 0.2% ยิ่งเล่น ยิ่งได้
                                                        ไม่ต้องทำเทิร์น หนึ่งในเรื่องที่น่าสนใจมากในปัจจุบัน
                                                        โดยเฉพาะกับโปรโมชั่นที่คืนทุกยอดเล่น 0.2% โดยไม่ต้องทำเทิร์น
                                                        หรือไม่ต้องเล่นเกมให้ครบรอบเทิร์นที่กำหนด
                                                        เพื่อให้ผู้เล่นสามารถถอนเงินได้ทันที โดยไม่มีเงื่อนไขซับซ้อน
                                                        ในบทความนี้เราจะมาวิเคราะห์เหตุผลและผลกระทบของโปรโมชั่นนี้
                                                        รวมถึงแนวทางในการใช้ประโยชน์จากโปรโมชั่นนี้อย่างมีประสิทธิภาพ
                                                        และเพื่อให้ผู้เล่นได้รับประสบการณ์ที่ดีที่สุดในการเล่นคาสิโนออนไลน์
                                                    </p>
                                                    <p>
                                                        โปรโมชั่นคืนทุกยอดเล่น 0.2% ยิ่งเล่น ยิ่งได้
                                                        ไม่ต้องทำเทิร์นเป็นโปรโมชั่นที่มีความน่าสนใจสำหรับผู้เล่นคาสิโนออนไลน์
                                                        โดยเป็นการคืนเงินให้กับผู้เล่นโดยอัตโนมัติ
                                                        โดยไม่มีเงื่อนไขที่ต้องทำเทิร์นหรือเล่นเกมในรอบที่กำหนด
                                                        ผู้เล่นสามารถถอนเงินได้ทันที โดยไม่มีข้อจำกัดเพิ่มเติม
                                                        โปรโมชั่นนี้เป็นทางเลือกที่ดีสำหรับผู้ที่ต้องการความสะดวกสบายและความยืดหยุ่นในการเล่นคาสิโนออนไลน์
                                                    </p>
                                                    <p>
                                                        ความสำคัญของโปร โปรโมชั่นคืนทุกยอดเล่น 0.2% ยิ่งเล่น ยิ่งได้
                                                        ไม่ต้องทำเทิร์นเป็นโปรโมชั่นที่มีความสำคัญสูงสำหรับผู้เล่นคาสิโนออนไลน์
                                                        เนื่องจากมีผลกระทบต่อระบบเศรษฐกิจในระยะยาว
                                                        โดยเฉพาะผู้ที่สนใจในเรื่องการลงทุนในคาสิโนออนไลน์
                                                        โปรโมชั่นนี้ช่วยให้ผู้เล่นสามารถเพิ่มยอดเงินทุนได้อย่างรวดเร็วและมีโอกาสทดลองเล่นเกมใหม่ๆ
                                                        ได้อีกด้วย
                                                    </p>
                                                    <p>
                                                        การใช้ประโยชน์จากโปรโมชั่นคืนทุกยอดเล่น 0.2%
                                                        เพื่อให้ผู้เล่นได้รับประสบการณ์ที่ดีที่สุดในการเล่นคาสิโนออนไลน์
                                                        ควรทำความเข้าใจกับโปรโมชั่นนี้อย่างถี่ถ้วนและใช้ประโยชน์อย่างเต็มที่
                                                        โดยสามารถดำเนินการตามแนวทางดังต่อไปนี้
                                                    </p>
                                                    <p>
                                                        วางแผนการเล่น ก่อนที่จะเริ่มเล่นคาสิโนออนไลน์
                                                        ควรวางแผนการเล่นให้ดี
                                                        เพื่อให้สามารถเล่นได้อย่างมีประสิทธิภาพและเพิ่มโอกาสในการชนะ
                                                        เริ่มจากการกำหนดยอดเงินที่สามารถนำมาเล่นได้และกำหนดวัตถุประสงค์ที่ต้องการในการเล่น
                                                        เช่น การเพิ่มยอดเงินทุนหรือการศึกษาเกมที่ไม่คุ้นเคย
                                                    </p>
                                                    <p>
                                                        เลือกเกมที่เหมาะสม
                                                        โปรโมชั่นนี้ช่วยให้ผู้เล่นมีโอกาสลองเล่นเกมใหม่ๆ
                                                        ที่ไม่เคยเล่นมาก่อน
                                                        ควรเลือกเกมที่มีอัตราการชำระเงินที่สูงและมีโอกาสชนะสูง
                                                        เพื่อเพิ่มโอกาสในการทำกำไร
                                                    </p>
                                                    <p>
                                                        การจัดการเงิน เป็นสิ่งสำคัญที่ผู้เล่นควรใส่ใจ
                                                        โดยควรกำหนดวงเงินที่สามารถสูญเสียได้และไม่ควรเกินกำหนด
                                                        นอกจากนี้ยังควรระมัดระวังในการพนันเพื่อไม่ให้เสี่ยงต่อการสูญเสียเงินทุนที่มีอยู่
                                                    </p>
                                                    <p>
                                                        โปรโมชั่นคืนทุกยอดเล่น 0.2% ยิ่งเล่น ยิ่งได้ ไม่ต้องทำเทิร์น
                                                        เป็นโปรโมชั่นที่มีความสำคัญและเป็นทางเลือกที่ดีสำหรับผู้เล่นคาสิโนออนไลน์
                                                        โดยช่วยเพิ่มยอดเงินทุนและโอกาสในการทำกำไร
                                                        ในการใช้ประโยชน์จากโปรโมชั่นนี้ ควรวางแผนการเล่นอย่างรอบคอบ
                                                        และเลือกเกมที่เหมาะสม
                                                        นอกจากนี้ควรจัดการเงินอย่างมีระเบียบเพื่อไม่ให้เกิดความเสี่ยงต่อการสูญเสียเงินทุนที่มีอยู่
                                                        ในที่สุด
                                                        โปรโมชั่นนี้ช่วยให้ผู้เล่นคาสิโนออนไลน์สามารถเพลิดเพลินไปกับการเล่นเกมและสร้างรายได้ให้กับตัวเองได้อย่างมีประสิทธิภาพ
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-01129d0 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="01129d0"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1f134ec"
                                        data-id="1f134ec"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                                className="elementor-element elementor-element-7b1544c elementor-widget elementor-widget-text-editor"
                                                data-id="7b1544c"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <h2>โปรโมชั่น คืนยอดเสีย</h2>
                                                </div>
                                            </div>
                                            <div
                                                className="elementor-element elementor-element-8872f5d elementor-widget elementor-widget-text-editor"
                                                data-id="8872f5d"
                                                data-element_type="widget"
                                                data-widget_type="text-editor.default"
                                            >
                                                <div className="elementor-widget-container">
                                                    <p>
                                                        ความสำคัญของโปรโมชั่น คืนยอดเสีย 5%
                                                        เมื่อเราพูดถึงการเล่นสล็อตออนไลน์
                                                        สิ่งที่ผู้เล่นสนใจอย่างมากคือโอกาสในการชนะรางวัล
                                                        และการทำกำไรจากการเล่น
                                                        แต่เราก็ต้องยอมรับว่าการเล่นสล็อตออนไลน์นั้นมีความเสี่ยงมาก
                                                        เนื่องจากเป็นการพนันที่ผู้เล่นไม่สามารถควบคุมผลลัพธ์ได้เป็นอย่างดี
                                                        แต่เมื่อคุณเลือกเล่นสล็อตออนไลน์ที่มีโปรโมชั่น “คืนยอดเสีย 5%
                                                        ทุกสัปดาห์ ไม่ต้องทำเทิร์น เล่นได้ทุกอย่าง”
                                                        คุณจะได้รับความปลอดภัยทางการเงินและความสบายใจ
                                                        โดยไม่ต้องกังวลว่าถ้าหากคุณไม่มีโชคที่ดีในการเล่นสล็อต
                                                        คุณจะเสียเงินไปเป็นจำนวนมากโดยไม่ได้รับอะไรเป็นกลับกัน
                                                        โปรโมชั่นนี้จะช่วยให้คุณกลับรับเงินคืนในสัดส่วนของยอดเสียที่คุณเสียไป
                                                        5% ทุกสัปดาห์ นอกจากนี้ คุณยังไม่ต้องทำเทิร์นก่อนถอนเงินเลย
                                                        ซึ่งเป็นสิ่งที่น่าตื่นเต้นอย่างยิ่งสำหรับผู้เล่น
                                                    </p>
                                                    <p>
                                                        ความเป็นมาของโปรโมชั่น โปรโมชั่น “คืนยอดเสีย 5% ทุกสัปดาห์
                                                        ไม่ต้องทำเทิร์น เล่นได้ทุกอย่าง”
                                                        เป็นเครื่องมือที่ถูกออกแบบมาเพื่อให้ผู้เล่นสล็อตออนไลน์ได้รับประสบการณ์การเล่นที่ดีที่สุด
                                                        โดยที่ไม่ต้องกังวลว่าจะเสียเงินไปเป็นจำนวนมากโดยไม่ได้รับอะไรเป็นกลับกัน
                                                        โดยปกติแล้ว สำหรับผู้เล่นสล็อตออนไลน์ที่เสียเงินในการเล่น
                                                        เงินที่เสียไปจะหายไปอย่างไม่คืนกลับมาในกรณีที่ไม่มีโปรโมชั่นเสียเงินคืน
                                                        แต่โปรโมชั่นนี้จะทำให้คุณได้รับคืนเงินที่เสียไปในสัดส่วน 5%
                                                        ทุกสัปดาห์
                                                        ซึ่งเป็นจำนวนเงินที่สำคัญและสามารถทำให้คุณกลับมาเล่นสล็อตได้อีกครั้ง
                                                    </p>
                                                    <p>
                                                        ประโยชน์ของโปรโมชั่นแบบคืนยอดเสีย 5% โปรโมชั่นคืนยอดเสีย 5%
                                                        ทุกสัปดาห์ ไม่ต้องทำเทิร์น เล่นได้ทุกอย่าง
                                                        มีประโยชน์อย่างมากสำหรับผู้เล่นสล็อตออนไลน์
                                                        โดยเฉพาะอย่างยิ่งสำหรับผู้ที่ชื่นชอบการเล่นสล็อต
                                                        ดังนั้นเรามาดูว่าโปรโมชั่นนี้มีประโยชน์อย่างไรบ้าง
                                                    </p>
                                                    <p>
                                                        – รับเงินคืน:
                                                        โปรโมชั่นนี้จะช่วยให้คุณได้รับเงินคืนสำหรับยอดเสียที่คุณเสียไป
                                                        5% ทุกสัปดาห์
                                                        ซึ่งเป็นจำนวนเงินที่สำคัญและสามารถเพิ่มกำไรให้กับคุณในระยะยาว
                                                    </p>
                                                    <p>
                                                        – ไม่ต้องทำเทิร์นก่อนถอนเงิน:
                                                        โปรโมชั่นนี้ยังไม่ต้องกังวลเรื่องการทำเทิร์นก่อนถอนเงิน
                                                        เพราะคุณสามารถถอนเงินได้เลยโดยไม่มีข้อจำกัด
                                                        ซึ่งนั่นหมายความว่าคุณสามารถเพลิดเพลินไปกับเงินรางวัลที่คุณได้รับได้อย่างอิสระ
                                                    </p>
                                                    <p>
                                                        – เพิ่มโอกาสในการชนะ: โดยการคืนยอดเสีย 5% ทุกสัปดาห์
                                                        โปรโมชั่นนี้จะช่วยเพิ่มโอกาสให้คุณชนะรางวัลในการเล่นสล็อตออนไลน์
                                                        แม้ว่าคุณอาจจะไม่ชนะในรอบหนึ่ง
                                                        แต่โปรโมชั่นนี้จะช่วยให้คุณมีโอกาสกลับรับเงินคืน
                                                        และสามารถกลับมาลุ้นรางวัลอีกครั้ง
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                data-particle_enable="false"
                                data-particle-mobile-disabled="false"
                                className="elementor-section elementor-top-section elementor-element elementor-element-7b7906e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                data-id="7b7906e"
                                data-element_type="section"
                            >
                                <div className="elementor-container elementor-column-gap-default">
                                    <div
                                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cef4ce0"
                                        data-id="cef4ce0"
                                        data-element_type="column"
                                    >
                                        <div className="elementor-widget-wrap"></div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                    <div style={{ display: tabselect == 27 ? "" : "none" }}  >
                        <div className="px-2 mb-2">
                            <div className="containpage p-3">
                                <main className="flex-shrink-0  marginsinglepost bgsingle">
                                    <div className="row m-0">
                                        <div className="col-md-3 p-post text-center">
                                            <div className="category-post">
                                                <h2>Categories</h2>
                                                <ul>
                                                    <li className="cat-item cat-item-1 current-cat">
                                                        <a
                                                            aria-current="page"
                                                            href="https://appufa.com/category/posts/"
                                                        >
                                                            ทั้งหมด
                                                        </a>
                                                    </li>
                                                    {/* <li className="cat-item cat-item-6">
                                                        <a href="https://appufa.com/category/%e0%b8%82%e0%b9%88%e0%b8%b2%e0%b8%a7%e0%b8%81%e0%b8%b5%e0%b8%ac%e0%b8%b2%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%99%e0%b9%88%e0%b8%b2%e0%b8%aa%e0%b8%99%e0%b9%83%e0%b8%88/">
                                                            ข่าวกีฬาที่น่าสนใจ
                                                        </a>
                                                    </li>
                                                    <li className="cat-item cat-item-11">
                                                        <a href="https://appufa.com/category/%e0%b8%9a%e0%b8%97%e0%b8%84%e0%b8%a7%e0%b8%b2%e0%b8%a1%e0%b8%97%e0%b8%b5%e0%b9%88%e0%b8%99%e0%b9%88%e0%b8%b2%e0%b8%aa%e0%b8%99%e0%b9%83%e0%b8%88/">
                                                            บทความที่น่าสนใจ
                                                        </a>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-9  mt-sm-0 mt-2 p-post">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="v-pills-home"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-home-tab"
                                                >
                                                    <div className="gridpost">
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b9%82%e0%b8%94%e0%b8%99%e0%b8%96%e0%b8%b2%e0%b8%a1%e0%b8%95%e0%b8%a3%e0%b8%87%e0%b9%86-%e0%b9%80%e0%b8%9f%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%88%e0%b8%b4%e0%b8%a5-%e0%b8%9f%e0%b8%b2%e0%b8%99/'"
                                                            >
                                                                <img
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/โดนถามตรงๆ-เฟอร์จิล-ฟาน-ไดค์เคลียร์ชัด-ซบเรอัล-มาดริดหรือไม่-1-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                โดนถามตรงๆ! เฟอร์จิล ฟาน ไดค์เคลียร์ชัด ซบเรอัล
                                                                มาดริดหรือไม่?
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b9%81%e0%b8%9a%e0%b8%a3%e0%b8%94%e0%b8%a5%e0%b8%b5%e0%b8%a2%e0%b9%8c%e0%b9%80%e0%b8%94%e0%b8%b5%e0%b9%89%e0%b8%a2%e0%b8%87-%e0%b8%a5%e0%b8%b4%e0%b9%80%e0%b8%a7%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%9e/'"
                                                            >
                                                                <img
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/แบรดลีย์เดี้ยง-ลิเวอร์พูลเตรียมส่งเทรนต์-อาร์โนลด์บู๊แมนซิตี้-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                แบรดลีย์เดี้ยง! ลิเวอร์พูลเตรียมส่งเทรนต์
                                                                อาร์โนลด์บู๊แมนซิตี้
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%84%e0%b8%a7%e0%b8%b5%e0%b8%a7%e0%b8%b4%e0%b8%99-%e0%b9%80%e0%b8%84%e0%b8%a5%e0%b9%80%e0%b8%a5%e0%b9%80%e0%b8%ae%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%ad%e0%b8%99%e0%b8%b2%e0%b8%84%e0%b8%95%e0%b8%8a/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/ควีวิน-เคลเลเฮอร์อนาคตชัดเจน-บอสอาร์เน่อแจงแผนการ-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                ควีวิน เคลเลเฮอร์อนาคตชัดเจน! บอสอาร์เน่อแจงแผนการ
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%a5%e0%b8%b5%e0%b8%81%e0%b8%8b%e0%b8%b2%e0%b8%ad%e0%b8%b8%e0%b8%94%e0%b8%b4%e0%b8%97%e0%b8%b8%e0%b9%88%e0%b8%a1%e0%b8%aa%e0%b8%b8%e0%b8%94%e0%b8%95%e0%b8%b1%e0%b8%a7-%e0%b8%88%e0%b9%89%e0%b8%b2/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/ลีกซาอุดิทุ่มสุดตัว-จ้างโม-ซาลาห์-แพงสุดเป็นอันดับ-1-คริสเตียโน่-โรนัลโด้ชิดซ้าย.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/ลีกซาอุดิทุ่มสุดตัว-จ้างโม-ซาลาห์-แพงสุดเป็นอันดับ-1-คริสเตียโน่-โรนัลโด้ชิดซ้าย.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/ลีกซาอุดิทุ่มสุดตัว-จ้างโม-ซาลาห์-แพงสุดเป็นอันดับ-1-คริสเตียโน่-โรนัลโด้ชิดซ้าย-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/ลีกซาอุดิทุ่มสุดตัว-จ้างโม-ซาลาห์-แพงสุดเป็นอันดับ-1-คริสเตียโน่-โรนัลโด้ชิดซ้าย-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                ลีกซาอุดิทุ่มสุดตัว! จ้างโม ซาลาห์ แพงสุดเป็นอันดับ 1
                                                                คริสเตียโน่ โรนัลโด้ชิดซ้าย
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%aa%e0%b9%84%e0%b8%95%e0%b8%a5%e0%b9%8c%e0%b9%80%e0%b8%a3%e0%b8%b4%e0%b9%88%e0%b8%a1%e0%b8%8a%e0%b8%b1%e0%b8%94%e0%b9%80%e0%b8%88%e0%b8%99-%e0%b8%ad%e0%b9%82%e0%b8%a1%e0%b8%a3%e0%b8%b4%e0%b8%a1/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/สไตล์เริ่มชัดเจน-อโมริมพาแมนยูเก็บชัยหืดจับนัดสอง.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/สไตล์เริ่มชัดเจน-อโมริมพาแมนยูเก็บชัยหืดจับนัดสอง.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/สไตล์เริ่มชัดเจน-อโมริมพาแมนยูเก็บชัยหืดจับนัดสอง-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/สไตล์เริ่มชัดเจน-อโมริมพาแมนยูเก็บชัยหืดจับนัดสอง-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                สไตล์เริ่มชัดเจน! อโมริมพาแมนยูเก็บชัยหืดจับนัดสอง
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%ae%e0%b8%ad%e0%b8%a2%e0%b8%a5%e0%b8%b8%e0%b8%99%e0%b8%94%e0%b9%8c%e0%b9%80%e0%b8%9a%e0%b8%b4%e0%b9%89%e0%b8%a5-%e0%b9%81%e0%b8%a1%e0%b8%99%e0%b8%a2%e0%b8%b9%e0%b9%81%e0%b8%8b%e0%b8%87%e0%b8%8a/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/ฮอยลุนด์เบิ้ล-แมนยูแซงชนะโบโด-กลิมท์-คว้าชัยสุดมันส์-1.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/ฮอยลุนด์เบิ้ล-แมนยูแซงชนะโบโด-กลิมท์-คว้าชัยสุดมันส์-1.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/ฮอยลุนด์เบิ้ล-แมนยูแซงชนะโบโด-กลิมท์-คว้าชัยสุดมันส์-1-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/ฮอยลุนด์เบิ้ล-แมนยูแซงชนะโบโด-กลิมท์-คว้าชัยสุดมันส์-1-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                ฮอยลุนด์เบิ้ล! แมนยูแซงชนะโบโด กลิมท์ คว้าชัยสุดมันส์
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b9%80%e0%b8%8a%e0%b8%a5%e0%b8%8b%e0%b8%b5%e0%b9%80%e0%b8%ae%e0%b9%80%e0%b8%95%e0%b9%87%e0%b8%a1%e0%b8%a3%e0%b9%89%e0%b8%ad%e0%b8%a2-%e0%b8%8b%e0%b8%b2%e0%b8%99%e0%b9%82%e0%b8%8a%e0%b9%88%e0%b8%88/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/เชลซีเฮเต็มร้อย-ซานโช่จัด-2-แอสซิสต์-บุกทุบไฮเดมไฮม์-ครองจ่าฝูง.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/เชลซีเฮเต็มร้อย-ซานโช่จัด-2-แอสซิสต์-บุกทุบไฮเดมไฮม์-ครองจ่าฝูง.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/เชลซีเฮเต็มร้อย-ซานโช่จัด-2-แอสซิสต์-บุกทุบไฮเดมไฮม์-ครองจ่าฝูง-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/เชลซีเฮเต็มร้อย-ซานโช่จัด-2-แอสซิสต์-บุกทุบไฮเดมไฮม์-ครองจ่าฝูง-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                เชลซีเฮเต็มร้อย! ซานโช่จัด 2 แอสซิสต์ บุกทุบไฮเดมไฮม์
                                                                ครองจ่าฝูง
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%aa%e0%b9%80%e0%b8%9b%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%aa%e0%b8%8a%e0%b9%87%e0%b8%ad%e0%b8%81-%e0%b9%82%e0%b8%a3%e0%b8%a1%e0%b9%88%e0%b8%b2%e0%b8%95%e0%b8%b5%e0%b9%80%e0%b8%aa%e0%b8%a1%e0%b8%ad/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/สเปอร์สช็อก-โรม่าตีเสมอทดเจ็บ-แบ่งแต้มศึกยูโรปา-ลีก.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/สเปอร์สช็อก-โรม่าตีเสมอทดเจ็บ-แบ่งแต้มศึกยูโรปา-ลีก.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/สเปอร์สช็อก-โรม่าตีเสมอทดเจ็บ-แบ่งแต้มศึกยูโรปา-ลีก-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/สเปอร์สช็อก-โรม่าตีเสมอทดเจ็บ-แบ่งแต้มศึกยูโรปา-ลีก-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                สเปอร์สช็อก! โรม่าตีเสมอทดเจ็บ แบ่งแต้มศึกยูโรปา ลีก
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%a7%e0%b8%b4%e0%b9%80%e0%b8%84%e0%b8%a3%e0%b8%b2%e0%b8%b0%e0%b8%ab%e0%b9%8c%e0%b9%80%e0%b8%81%e0%b8%a1-%e0%b9%81%e0%b8%a1%e0%b8%99%e0%b8%a2%e0%b8%b9-%e0%b8%9e%e0%b8%9a-%e0%b9%82%e0%b8%9a/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/วิเคราะห์เกม-แมนยู-พบ-โบโดกลิมท์-28-พ.ย.-67.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/วิเคราะห์เกม-แมนยู-พบ-โบโดกลิมท์-28-พ.ย.-67.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/วิเคราะห์เกม-แมนยู-พบ-โบโดกลิมท์-28-พ.ย.-67-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/วิเคราะห์เกม-แมนยู-พบ-โบโดกลิมท์-28-พ.ย.-67-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                วิเคราะห์เกม! แมนยู พบ โบโดกลิมท์ 28 พ.ย. 67
                                                            </div>
                                                        </div>
                                                        <div className="ingridpost">
                                                            <div
                                                                className="iningridpost"
                                                                onclick="location='https://appufa.com/%e0%b8%aa%e0%b9%80%e0%b8%9b%e0%b8%ad%e0%b8%a3%e0%b9%8c%e0%b8%aa%e0%b8%ad%e0%b8%b1%e0%b8%9b%e0%b9%80%e0%b8%94%e0%b8%95%e0%b8%ad%e0%b8%b2%e0%b8%81%e0%b8%b2%e0%b8%a3-%e0%b8%81%e0%b8%b9%e0%b9%80%e0%b8%a2/'"
                                                            >
                                                                <img
                                                                    loading="lazy"
                                                                    width={800}
                                                                    height={600}
                                                                    src="https://appufa.com/wp-content/uploads/2024/11/สเปอร์สอัปเดตอาการ-กูเยลโม่-วิคาริโอ-พักยาวแค่ไหน.jpg"
                                                                    className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
                                                                    alt=""
                                                                    decoding="async"
                                                                    srcSet="https://appufa.com/wp-content/uploads/2024/11/สเปอร์สอัปเดตอาการ-กูเยลโม่-วิคาริโอ-พักยาวแค่ไหน.jpg 800w, https://appufa.com/wp-content/uploads/2024/11/สเปอร์สอัปเดตอาการ-กูเยลโม่-วิคาริโอ-พักยาวแค่ไหน-300x225.jpg 300w, https://appufa.com/wp-content/uploads/2024/11/สเปอร์สอัปเดตอาการ-กูเยลโม่-วิคาริโอ-พักยาวแค่ไหน-768x576.jpg 768w"
                                                                    sizes="(max-width: 800px) 100vw, 800px"
                                                                />
                                                                สเปอร์สอัปเดตอาการ! กูเยลโม่ วิคาริโอ พักยาวแค่ไหน?
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pages-post">
                                                <span aria-current="page" className="page-numbers current">
                                                    1
                                                </span>
                                                <a
                                                    className="page-numbers"
                                                    href="https://appufa.com/category/posts/page/2/"
                                                >
                                                    2
                                                </a>
                                                <a
                                                    className="page-numbers"
                                                    href="https://appufa.com/category/posts/page/3/"
                                                >
                                                    3
                                                </a>
                                                <span className="page-numbers dots">…</span>
                                                <a
                                                    className="page-numbers"
                                                    href="https://appufa.com/category/posts/page/22/"
                                                >
                                                    22
                                                </a>
                                                <a
                                                    className="next page-numbers"
                                                    href="https://appufa.com/category/posts/page/2/"
                                                >
                                                    Next »
                                                </a>
                                            </div>
                                        </div> */}
                                    </div>
                                </main>
                            </div>
                        </div>

                    </div>
                    <div className="x-button-actions" id="account-actions-mobile">
                        <div className="-outer-wrapper">
                            <div className="-left-wrapper">
                                <span className="-item-wrapper">
                                    <span className="-ic-img">
                                        <span className="-textfooter d-block">
                                            <a href="#">เข้าสู่ระบบ</a>
                                        </span>
                                        <a href={this.state.webnamex + "/login"}>
                                            <img
                                                width={150}
                                                height={150}
                                                src="https://appufa.com/wp-content/uploads/2023/10/ic-menu-login-animate-1.png"
                                                className="image wp-image-4827  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                            />
                                        </a>
                                    </span>
                                </span>
                                <span className="-item-wrapper">
                                    <span className="-ic-img">
                                        <span className="-textfooter d-block">
                                            <a href="#">สมัคร</a>
                                        </span>
                                        <a href={this.state.webnamex + "/register"}>
                                            <img
                                                width={50}
                                                height={50}
                                                src="https://appufa.com/wp-content/uploads/2023/10/ic-menu-register.png"
                                                className="image wp-image-4828  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                            />
                                        </a>
                                    </span>
                                </span>
                            </div>
                            <span className="-center-wrapper js-footer-lobby-selector js-menu-mobile-container">
                                <div className="-selected">
                                    <span>
                                        <a href="#">เข้าเล่น</a>
                                    </span>
                                    <a href={this.state.webnamex + "/login"}>
                                        <img
                                            width={192}
                                            height={211}
                                            src="https://appufa.com/wp-content/uploads/2022/04/unnamed-1.png"
                                            className="image wp-image-2375  attachment-full size-full"
                                            alt=""
                                            style={{ maxWidth: "100%", height: "auto" }}
                                            title="เข้าเล่น"
                                            decoding="async"
                                        />
                                    </a>
                                </div>
                            </span>
                            <div className="-fake-center-bg-wrapper">
                                <svg viewBox="-10 -1 30 12">
                                    <defs>
                                        <linearGradient
                                            id="rectangleGradient"
                                            x1="0%"
                                            y1="0%"
                                            x2="0%"
                                            y2="100%"
                                        >
                                            <stop offset="0%" stopColor="#383d44" />
                                            <stop offset="100%" stopColor="#141617" />
                                        </linearGradient>
                                    </defs>
                                    <path d="M-10 -1 H30 V12 H-10z M 5 5 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0z" />
                                </svg>
                            </div>
                            <div className="-right-wrapper">
                                <span className="-item-wrapper">
                                    <span className="-ic-img">
                                        <span className="-textfooter d-block">โปรโมชั่น</span>
                                        <a href={this.state.webnamex + "/login"}>
                                            <img
                                                width={258}
                                                height={257}
                                                src="https://appufa.com/wp-content/uploads/2023/10/tab_promotion-1.png"
                                                className="image wp-image-4831  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                title="โปรโมชั่น"
                                                decoding="async"
                                                srcSet="https://appufa.com/wp-content/uploads/2023/10/tab_promotion-1.png 258w, https://appufa.com/wp-content/uploads/2023/10/tab_promotion-1-150x150.png 150w"
                                                sizes="(max-width: 258px) 100vw, 258px"
                                            />
                                        </a>
                                    </span>
                                </span>
                                <span className="-item-wrapper">
                                    <span className="-ic-img">
                                        <span className="-textfooter d-block">ติดต่อเรา</span>
                                        <a href={this.state.linelink}>
                                            <img
                                                width={123}
                                                height={122}
                                                src="https://appufa.com/wp-content/uploads/2023/10/support.png"
                                                className="image wp-image-4830  attachment-full size-full"
                                                alt=""
                                                style={{ maxWidth: "100%", height: "auto" }}
                                                decoding="async"
                                            />
                                        </a>
                                    </span>
                                </span>
                            </div>
                            <div className="-fully-overlay js-footer-lobby-overlay" />
                        </div>
                    </div>
                    {/* Popper.JS */}
                    {/* Bootstrap JS */}
                    <footer
                        itemType="https://schema.org/WPFooter"
                        itemScope="itemscope"
                        id="colophon"
                        role="contentinfo"
                    >
                        <div className="footer-width-fixer">

                            <div
                                data-elementor-type="wp-post"
                                data-elementor-id={274}
                                className="elementor elementor-274"
                                data-elementor-post-type="elementor-hf"
                            >
                                <section
                                    data-particle_enable="false"
                                    data-particle-mobile-disabled="false"
                                    className="elementor-section elementor-top-section elementor-element elementor-element-2f2ddc41 linecuttop01 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="2f2ddc41"
                                    data-element_type="section"
                                    data-settings='{"background_background":"classic"}'
                                >
                                    <div className="elementor-container elementor-column-gap-default">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2e9347d2"
                                            data-id="2e9347d2"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="elementor-element elementor-element-05caac2 elementor-widget elementor-widget-image"
                                                    data-id="05caac2"
                                                    data-element_type="widget"
                                                    data-widget_type="image.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <img
                                                            width={512}
                                                            height={137}
                                                            src={this.state.logoimg}
                                                            className="attachment-large size-large wp-image-6227"
                                                            alt=""
                                                            // srcSet="https://appufa.com/wp-content/uploads/2022/03/cropped-1_0.png 512w, https://appufa.com/wp-content/uploads/2022/03/cropped-1_0-300x80.png 300w"
                                                            sizes="(max-width: 512px) 100vw, 512px"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-0cfbffa elementor-widget elementor-widget-text-editor"
                                                    data-id="0cfbffa"
                                                    data-element_type="widget"
                                                    data-widget_type="text-editor.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <p>
                                                            เราเป็นผู้ให้บริการ <strong>ufabet</strong>
                                                            <strong>แทงบอลออนไลน์</strong>
                                                            <strong>คาสิโนออนไลน์</strong> <strong>บาคาร่า</strong>
                                                            <strong>สล็อต</strong> รายใหญ่ที่สุดของไทย
                                                            <strong>ENJOY289</strong>
                                                            ได้มีการพัฒนาและปรับปรุงแก้ไขระบบอยู่ตลอดเวลา
                                                            ด้วยผู้เชี่ยวชาญเฉพาะด้านโดยเฉพาะ
                                                            อีกทั้งยังมีทีมงานแอดมินคอยควมคุมดูแลคอยแก้ปัญหาให้กับผู้เล่นตลอด
                                                            24 ชม.
                                                            เรายังเป็นผู้ให้บริการเว็บพนันออนไลน์ที่มีผู้ใช้บริการมากที่สุดในไทย
                                                            มีรูปแบบเกมการเดิมพันมากมายหลากหลายไม่ว่าจะเป็น
                                                            <strong>แทงบอล</strong> <strong>แทงมวย</strong>
                                                            <strong>แทงหวย</strong> <strong>บาคาร่า</strong>
                                                            <strong>สล็อต</strong>
                                                            โดยจะมีรูปแบบของเกมการเดิมพันอย่างหลากหลายให้ท่านได้เลือกรับความบันเทิง
                                                            <strong>ทางเข้า ufabet</strong> ของจริงอยู่ต้อง
                                                            <strong>ENJOY289</strong> เท่านั้น ทำให้การ
                                                            <strong>สมัคร ufabet</strong>
                                                            ทำได้อย่างถูกต้องและมั่นคงปลอดภัย
                                                            สมัครง่ายเพียงขั้นตอนเดียวเท่านั้น
                                                            และนอกจากนี้ท่านก็จะได้พบกับ
                                                            <strong>โปรโมชั่น ufabet</strong> สุดคุ้มแบบจัดเต็ม
                                                            ในแบบที่ท่านไม่เคยได้จากที่ไหนมาก่อนอย่างแน่นอน
                                                            ไม่ว่าจะเป็น คืนยอดเสีย คืนยอดเล่น และ ระบบ แนะนำเพื่อน
                                                            สามารถ <strong>สมัครufabet</strong> ได้แล้ววันนี้
                                                            สมัครง่ายเพียงคลิกเดียว
                                                            หรือให้แอดมินสมัครให้ก็สามารถทำได้เช่นเดียวกัน
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-e063ef9 elementor-widget elementor-widget-text-editor"
                                                    data-id="e063ef9"
                                                    data-element_type="widget"
                                                    data-widget_type="text-editor.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <p>
                                                            ติดต่อเรา แอดไลน์ Line :&nbsp;<strong>{this.state.lineid}</strong>
                                                            &nbsp;(มี@)
                                                        </p>
                                                    </div>
                                                </div>
                                                <section
                                                    data-particle_enable="false"
                                                    data-particle-mobile-disabled="false"
                                                    className="elementor-section elementor-inner-section elementor-element elementor-element-acdf53e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                    data-id="acdf53e"
                                                    data-element_type="section"
                                                >
                                                    <div className="elementor-container elementor-column-gap-default">
                                                        <div
                                                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-dcd7bfe"
                                                            data-id="dcd7bfe"
                                                            data-element_type="column"
                                                        >
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    className="elementor-element elementor-element-421aac8 elementor-widget elementor-widget-image"
                                                                    data-id="421aac8"
                                                                    data-element_type="widget"
                                                                    data-widget_type="image.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <img
                                                                            width={360}
                                                                            height={360}
                                                                            src="https://cdn.javisx.com/image/2d3f1cfc-b8cd-4463-bf53-d7a9a7080c0f.png"
                                                                            className="attachment-full size-full wp-image-6539"
                                                                            alt=""
                                                                            //srcSet="https://cdn.javisx.com/image/2d3f1cfc-b8cd-4463-bf53-d7a9a7080c0f.png 360w, https://appufa.com/wp-content/uploads/2022/03/M_gainfriends_2dbarcodes_GW-300x300.png 300w, https://appufa.com/wp-content/uploads/2022/03/M_gainfriends_2dbarcodes_GW-150x150.png 150w"
                                                                            sizes="(max-width: 360px) 100vw, 360px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-15e8431"
                                                            data-id="15e8431"
                                                            data-element_type="column"
                                                        >
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    className="elementor-element elementor-element-63cc0c7 elementor-widget elementor-widget-text-editor"
                                                                    data-id="63cc0c7"
                                                                    data-element_type="widget"
                                                                    data-widget_type="text-editor.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <p>
                                                                            สอบถามโปรโมชั่น ติดต่อ-สอบถาม
                                                                            <br />
                                                                            ADMIN ได้ตลอด 24 ชั่วโมง
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-element elementor-element-40eac44 elementor-mobile-align-center elementor-widget elementor-widget-button"
                                                                    data-id="40eac44"
                                                                    data-element_type="widget"
                                                                    data-widget_type="button.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-button-wrapper">
                                                                            <a
                                                                                className="elementor-button elementor-button-link elementor-size-sm"
                                                                                href={this.state.linelink}
                                                                            >
                                                                                <span className="elementor-button-content-wrapper">
                                                                                    <span className="elementor-button-text">
                                                                                        เพิ่มเพื่อนเรา
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <section
                                                    data-particle_enable="false"
                                                    data-particle-mobile-disabled="false"
                                                    className="elementor-section elementor-inner-section elementor-element elementor-element-2266617 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                    data-id={2266617}
                                                    data-element_type="section"
                                                >
                                                    <div className="elementor-container elementor-column-gap-default">
                                                        <div
                                                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-eb8eed1"
                                                            data-id="eb8eed1"
                                                            data-element_type="column"
                                                        >
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    className="elementor-element elementor-element-eeb24b1 elementor-widget elementor-widget-text-editor"
                                                                    data-id="eeb24b1"
                                                                    data-element_type="widget"
                                                                    data-widget_type="text-editor.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <p>ธนาคารที่เราให้บริการ</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-element elementor-element-4a3d3bf elementor-widget elementor-widget-image"
                                                                    data-id="4a3d3bf"
                                                                    data-element_type="widget"
                                                                    data-widget_type="image.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <img
                                                                            width={1024}
                                                                            height={198}
                                                                            src="https://appufa.com/wp-content/uploads/2022/03/lowres-ft-banklist.png"
                                                                            className="attachment-large size-large wp-image-5474"
                                                                            alt=""
                                                                            srcSet="https://appufa.com/wp-content/uploads/2022/03/lowres-ft-banklist.png 1024w, https://appufa.com/wp-content/uploads/2022/03/lowres-ft-banklist-300x58.png 300w, https://appufa.com/wp-content/uploads/2022/03/lowres-ft-banklist-768x149.png 768w"
                                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-4ee7ad9"
                                                            data-id="4ee7ad9"
                                                            data-element_type="column"
                                                        >
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    className="elementor-element elementor-element-981cf33 elementor-widget elementor-widget-text-editor"
                                                                    data-id="981cf33"
                                                                    data-element_type="widget"
                                                                    data-widget_type="text-editor.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <p>พาร์ทเนอร์ที่ร่วมกับเรา</p>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-element elementor-element-04dd2da elementor-widget elementor-widget-image"
                                                                    data-id="04dd2da"
                                                                    data-element_type="widget"
                                                                    data-widget_type="image.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <img
                                                                            width={1024}
                                                                            height={302}
                                                                            src="https://appufa.com/wp-content/uploads/2022/03/lowres-ft-provider.png"
                                                                            className="attachment-large size-large wp-image-5475"
                                                                            alt=""
                                                                            srcSet="https://appufa.com/wp-content/uploads/2022/03/lowres-ft-provider.png 1024w, https://appufa.com/wp-content/uploads/2022/03/lowres-ft-provider-300x88.png 300w, https://appufa.com/wp-content/uploads/2022/03/lowres-ft-provider-768x227.png 768w"
                                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div
                                                    className="elementor-element elementor-element-3e81cc3 elementor-widget elementor-widget-text-editor"
                                                    data-id="3e81cc3"
                                                    data-element_type="widget"
                                                    data-widget_type="text-editor.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <p>
                                                            การเงินมั่นคง มาพร้อมกับระบบฝาก-ถอน AUTO รวดเร็ว และ
                                                            ทันสมัยที่สุดในไทย
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-2a71aa0 elementor-widget elementor-widget-image"
                                                    data-id="2a71aa0"
                                                    data-element_type="widget"
                                                    data-widget_type="image.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <img
                                                            width={937}
                                                            height={67}
                                                            src="https://appufa.com/wp-content/uploads/2022/03/e4c5a43354.png"
                                                            className="attachment-large size-large wp-image-3726"
                                                            alt=""
                                                            srcSet="https://appufa.com/wp-content/uploads/2022/03/e4c5a43354.png 937w, https://appufa.com/wp-content/uploads/2022/03/e4c5a43354-300x21.png 300w, https://appufa.com/wp-content/uploads/2022/03/e4c5a43354-768x55.png 768w"
                                                            sizes="(max-width: 937px) 100vw, 937px"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="elementor-element elementor-element-1a0b089 elementor-widget elementor-widget-text-editor"
                                                    data-id="1a0b089"
                                                    data-element_type="widget"
                                                    data-widget_type="text-editor.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <p>Copyright ©2002, All Rights Reserved. WWW.enjoy289.win</p>
                                                    </div>
                                                </div>
                                                <section
                                                    data-particle_enable="false"
                                                    data-particle-mobile-disabled="false"
                                                    className="elementor-section elementor-inner-section elementor-element elementor-element-cae09c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                    data-id="cae09c6"
                                                    data-element_type="section"
                                                >
                                                    <div className="elementor-container elementor-column-gap-no">
                                                        <div
                                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d161a29"
                                                            data-id="d161a29"
                                                            data-element_type="column"
                                                        >
                                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                                <div
                                                                    className="elementor-element elementor-element-223a8a4 elementor-align-right elementor-widget elementor-widget-button"
                                                                    data-id="223a8a4"
                                                                    data-element_type="widget"
                                                                    id="hidealert"
                                                                    data-widget_type="button.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <div className="elementor-button-wrapper">
                                                                            <a
                                                                                className="elementor-button elementor-button-link elementor-size-sm"
                                                                                href="#"
                                                                            >
                                                                                <span className="elementor-button-content-wrapper">
                                                                                    <span className="elementor-button-icon">
                                                                                        <i
                                                                                            aria-hidden="true"
                                                                                            className="fas fa-times"
                                                                                        />
                                                                                    </span>
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="elementor-element elementor-element-5315aef elementor-widget elementor-widget-image"
                                                                    data-id="5315aef"
                                                                    data-element_type="widget"
                                                                    data-widget_type="image.default"
                                                                >
                                                                    <div className="elementor-widget-container">
                                                                        <a href={this.state.linelink} role='button'>
                                                                            <img
                                                                                width={300}
                                                                                height={300}
                                                                                src="https://appufa.com/wp-content/uploads/2022/04/line-300x300-1.png"
                                                                                className="attachment-large size-large wp-image-2368"
                                                                                alt=""
                                                                                srcSet="https://appufa.com/wp-content/uploads/2022/04/line-300x300-1.png 300w, https://appufa.com/wp-content/uploads/2022/04/line-300x300-1-150x150.png 150w"
                                                                                sizes="(max-width: 300px) 100vw, 300px"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </footer>
                </div>
                <Popup />
                {/* <div className={this.state.modalx ? "overlay active" : "overlay "}></div> */}
            </>
        );
    }
}

export default Main;
