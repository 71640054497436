import React, { Component } from 'react'; //different
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import './style.css';
import Main from './main';
import Home from './home';

import Casino from './casino';
import NavMenu from './navmenu';
import Slot from './slot';
import Arcade from './arcade';
import Event from './event';
import Sport from './sport';
import Fising from './fising';
import Signin from './signin';
import Profile from './profile';
import Promotion from './promotionx';
import Login from './login';
import Logout from './logout';
import Statement from './statement';
import Deposit from './deposit';
import Withdraw from './withdraw';
import Affiliate from './affiliate';
import Banner from './banner';
import Loss from './loss';
import Linklistall from './linklistall';
import Linklistalldemo from './linklistaldemo';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import Freegame from './slotfree';
import Register from './register';
import io from "socket.io-client";
import MoblieMenu from "./mobilemenu";
import moment from 'moment';
import Spinwheels from './spinweel';
import $ from "jquery";
import Paoyingchub from './game/paoyingchub';
import { Flag } from '@mui/icons-material';
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainsetting: [],
      rewardlist: [],
      activePage: 1,
      ThmeseV: null,
      checkboxcookie: false,
      isActive: false,
      formData: {}, // Contains login form data
      errors: {}, // Contains login field errors
      formSubmitted: false, // Indicates submit status of login form
      loading: true, // Indicates in progress state of login form
      pagecount: 1,
      username: null,
      settingwheel: "",
      settingcheckin: "",
      userid: null,
      phoneNumber: "",
      balance: 0,
      mainacc: null,
      pathname: "",
      qrcode: null,
      stateregis: 1,
      checkboxacc: 0,
      products_page_count: 1,
      loginstate: false,
      fullname: null,
      lname: null,
      link: "",
      alert: null,
      depositstate: 0,
      stateforget: 1,
      depositamount: 0,
      stateopterror: "",
      btnisActive: true,
      otpphone: "",
      token: window.localStorage.getItem("token"),
      accnumber: null,
      accnumbertxt: null,
      withdrawamount: 0,
      rankpoint: 0,
      changepassword: false,
      linelink: "",
      errortext: "",
      trueaccount: null,
      datas: null,
      otpphoneforget: "",
      affpercent: 0,
      userrecomend: "",
      historylist: [],
      useradvice: [],
      cashoutlist: [],
      tblnextrank: [],
      tblrankall: [],
      maxwithdrawstatus: 0,
      tblprofile: [],
      prodetail: [],
      oldpassword: "",
      linkaff: "",
      webnamex: "",
      inviteSet: "",
      truegiftlink: "",
      proderedeem: [],
      proderedeemlist: [],
      buttoncliam: false,
      limit: null,
      rankpic: "1.jpeg",
      rankname: null,
      rankob: null,
      cashoutSet: [],
      tblPopupMenu1: [],
      tblPopupMenu2: [],
      tblPopupMenu: [],
      termAndCondition: false,
      errorstatus: false,
    }
  }

  async componentDidMount() {
    // await this.callgetthemesv(Systems);
    this.setState({
      pathname: window.location.pathname.replace("/", "").trim()
    });
  }


  getpopup = () => {
    instance.post("/api/v1/popuplist", {
      System: Systems,
      Cat_ID: 0,
      page: 1,
      type: 1
    }).then(async (res) => {
      if (res.data.status === 200) {
        console.log(160, res.data.message.banklist);
        this.setState({
          tblPopupMenu1: res.data.message.tblPopupMenu1,
          tblPopupMenu: res.data.message.banklist,
          tblPopupMenu2: res.data.message.tblPopupMenu2,
        });



      }
    }).catch(async (err) => {
      // console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        localStorage.clear();
        await Swal.fire({
          title: 'Warning!',
          text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ตกลง'
        })
        window.location.href = '/';
      }
    });

  }

  render() {
    const subdomain = window.location.hostname.split('.')[0]; // ดึง subdomain

    return (

      < >

        <BrowserRouter>
          <Routes>
            {subdomain === 'member' ? (
              <>
                <Route path="/*" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/history" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
              </>
            ) : (
              <>
                <Route path="/home" element={<Home />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/deposit" element={<Home />} />
                <Route path="/profile" element={<Home />} />
                <Route path="/luckywheel" element={<Home />} />
                <Route path="/promotion" element={<Home />} />                
                <Route path="/withdraw" element={<Home />} />
                <Route path="/history" element={<Home />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/*" element={<Main />} />

              </>
            )}
          </Routes>
        </BrowserRouter>

        {/* <BrowserRouter >
            <Routes>
   
            </Routes>
          </BrowserRouter>       */}

        <ToastContainer />
      </>
    );
  }
}

export default App;
